export var getSwiperDefaultConfig = function (template) {
    var defaultOptions = {
        startSlide: 0,
        speed: 250,
        interval: 5000,
        continuous: true,
        disableScroll: false,
        stopPropagation: false,
        scale: 1
    };
    return defaultOptions;
};
