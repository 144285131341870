import useInView from "../../../../hooks/useInview";
import { findMultiLangStringValue } from "../../../../utils/resolveDisplayTemplate";
import { pickDSLData } from "../../../../utils/tools";
import classNames from "classnames";
import React from "react";
import './styles.less';
var clsPrefix = 'credit-promotion-sdk-slide-icon-card';
var IconCard = function (props) {
    var _a;
    var dsl = props.dsl, locale = props.locale;
    if (!dsl) {
        return null;
    }
    var basic = dsl.basic, render = dsl.render;
    var bannerId = (basic || {}).bannerId;
    var iconCardData;
    if (Array.isArray(render)) {
        iconCardData = render[0];
    }
    else {
        iconCardData = render;
    }
    if (!iconCardData) {
        return null;
    }
    var _b = useInView({
        onEnter: function () {
            var _a;
            (_a = props.onEnter) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        onLeave: function () {
            var _a;
            (_a = props.onLeave) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        threshold: (_a = props.threshold) !== null && _a !== void 0 ? _a : 0.8
    }), ref = _b[0], inView = _b[1];
    var background = iconCardData.background;
    var imageUrl = findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.image, locale);
    var text = findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.text, locale);
    return (React.createElement("div", { className: clsPrefix, ref: ref },
        React.createElement("div", { key: bannerId, className: classNames("".concat(clsPrefix, "-wrapper")), onClick: function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale)); } },
            React.createElement("div", { className: "".concat(clsPrefix, "-icon"), style: {
                    backgroundImage: "url(".concat(imageUrl, ")"),
                } }),
            React.createElement("div", { className: "".concat(clsPrefix, "-text") }, text))));
};
export default IconCard;
