var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { activityEventReport } from "../../../../apis";
import Initial from "../../../Initial";
import { useImageLoaded } from "../../../../hooks/useImageLoaded";
import BITracker from "../../../../utils/BITracker";
import { findMultiLangStringValue } from "../../../../utils/resolveDisplayTemplate";
import { isUndefined, pickDSLData, px2em } from "../../../../utils/tools";
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { CenterPopup } from '../../center';
import './styles.less';
var clsPrefix = 'credit-promotion-web-sdk-marketing-popup';
var tracker = new BITracker();
var MarketingPopup = function (props) {
    var _a, _b;
    var locale = props.locale, region = props.region;
    var _c = props.dsl, basic = _c.basic, render = _c.render;
    useEffect(function () {
        var _a;
        try {
            if (props.layout !== 'mobile') {
                return;
            }
            var data = pickDSLData(props.dsl, locale);
            activityEventReport({
                eventCode: props.eventCode,
                resourceIdList: [basic === null || basic === void 0 ? void 0 : basic.bannerId],
                operation: 'impression',
                bizType: props.bizType,
                region: props.region
            });
            tracker.trackImpressionEvent({
                page_type: props.pageType,
                page_section: 'popup_promotion',
                data: {
                    from_source: props.fromSource,
                    banner_id: basic === null || basic === void 0 ? void 0 : basic.bannerId,
                    banner_name: basic === null || basic === void 0 ? void 0 : basic.name,
                    banner_url: (_a = data.btnBehavior) === null || _a === void 0 ? void 0 : _a.content,
                    image_hash: data.backgroundImage,
                }
            });
        }
        catch (e) {
        }
    }, []);
    if (!render) {
        return null;
    }
    var popupData;
    if (Array.isArray(render)) {
        popupData = render[0];
    }
    else {
        popupData = render;
    }
    if (!popupData) {
        return null;
    }
    var background = popupData.background, button = popupData.button;
    var imageUrl = findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.image, props.locale);
    var loaded = useImageLoaded(imageUrl);
    if (!loaded) {
        return null;
    }
    var _d = button || {}, text = _d.text, behavior = _d.behavior;
    var behaviorText = findMultiLangStringValue(text, props.locale);
    var centerPopupProps = __assign(__assign({}, props), { bodyClassName: "".concat(clsPrefix, "-body") });
    return (React.createElement(Initial, { locale: locale, region: region },
        React.createElement(CenterPopup, __assign({}, centerPopupProps),
            React.createElement("div", { key: basic === null || basic === void 0 ? void 0 : basic.bannerId, className: classNames(clsPrefix) },
                React.createElement("div", { style: {
                        backgroundImage: "url(".concat(imageUrl, ")"),
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: !isUndefined((_a = props.config) === null || _a === void 0 ? void 0 : _a.radius) ? px2em((_b = props.config) === null || _b === void 0 ? void 0 : _b.radius) : '8px'
                    }, className: classNames("".concat(clsPrefix, "-background")), onClick: function () { var _a; return (_a = props.onBodyClick) === null || _a === void 0 ? void 0 : _a.call(props, popupData, props.dsl); } }),
                behaviorText && (React.createElement("div", { className: "".concat(clsPrefix, "-button"), onClick: function () {
                        var _a, _b;
                        var popupData = pickDSLData(props.dsl, locale);
                        (_a = props.onBehaviorClick) === null || _a === void 0 ? void 0 : _a.call(props, behavior, popupData, props.dsl);
                        try {
                            if (props.layout !== 'mobile') {
                                return;
                            }
                            activityEventReport({
                                eventCode: props.eventCode,
                                resourceIdList: [basic === null || basic === void 0 ? void 0 : basic.bannerId],
                                operation: 'click',
                                bizType: props.bizType,
                                region: props.region
                            });
                            tracker.trackClickEvent({
                                page_type: props.pageType,
                                page_section: 'popup_promotion',
                                target_type: 'action_button',
                                data: {
                                    from_source: props.fromSource,
                                    banner_id: popupData.bannerId,
                                    banner_name: popupData.name,
                                    banner_url: (_b = popupData.btnBehavior) === null || _b === void 0 ? void 0 : _b.content,
                                    image_hash: popupData.backgroundImage,
                                    button_text: popupData.btnText
                                }
                            });
                        }
                        catch (e) {
                        }
                    } }, behaviorText))))));
};
export default MarketingPopup;
