// https://github.com/thebird/Swipe
// https://github.com/lyfeyaj/swipe
import { browser, isMouseEvent } from "../dom/support";
import { createLogger } from "../../monitor/logger";
// utilities
var noop = function () { }; // simple no operation function
var offloadFn = function (fn) { setTimeout(fn || noop, 0); }; // offload a functions execution
var logger = createLogger('PromoWebSDK:Swiper');
export function createSwiper(container, options) {
    // quit if no root element
    if (!container) {
        logger.error('Swiper container is null');
        return null;
    }
    var element = container.children[0];
    var slides;
    var slidePos;
    var width;
    var length;
    options = options || {};
    var index = options.startSlide || 0;
    var speed = options.speed || 300;
    options.continuous = options.continuous !== undefined ? options.continuous : true;
    function cloneNode(el) {
        var clone = el.cloneNode(true);
        element.appendChild(clone);
        // tag these slides as clones (to remove them on kill)
        clone.setAttribute('data-cloned', 'true');
        // Remove id from element
        clone.removeAttribute('id');
    }
    function setup() {
        var _a;
        // cache slides
        slides = element.children;
        length = slides.length;
        // slides length correction, minus cloned slides
        for (var i = 0; i < slides.length; i++) {
            if (slides[i].getAttribute('data-cloned') == 'true') {
                length--;
            }
        }
        // set continuous to false if only one slide
        if (slides.length < 2) {
            options.continuous = false;
        }
        //special case if two slides
        if (browser.transitions && options.continuous && slides.length < 3) {
            cloneNode(slides[0]);
            cloneNode(slides[1]);
            slides = element.children;
        }
        // create an array to store current positions of each slide
        slidePos = new Array(slides.length);
        // determine width of each slide
        width = (container.getBoundingClientRect().width || container.offsetWidth) / (!!options.scale ? options.scale : 1);
        element.style.width = (slides.length * width) + 'px';
        // stack elements
        var pos = slides.length;
        while (pos--) {
            var slide_1 = slides[pos];
            slide_1.style.width = width + 'px';
            slide_1.setAttribute('data-index', String(pos));
            if (browser.transitions) {
                slide_1.style.left = (pos * -width) + 'px';
                move(pos, index > pos ? -width : (index < pos ? width : 0), 0);
            }
        }
        // reposition elements before and after index
        if (options.continuous && browser.transitions) {
            move(circle(index - 1), -width, 0);
            move(circle(index + 1), width, 0);
        }
        if (!browser.transitions) {
            element.style.left = (index * -width) + 'px';
        }
        container.style.visibility = 'visible';
        (_a = options.onSetupEnd) === null || _a === void 0 ? void 0 : _a.call(options, getPos(), slides[index]);
    }
    function prev() {
        if (options.continuous) {
            slide(index - 1);
        }
        else if (index) {
            slide(index - 1);
        }
    }
    function next() {
        if (options.continuous) {
            slide(index + 1);
        }
        else if (index < slides.length - 1) {
            slide(index + 1);
        }
    }
    function circle(index) {
        // a simple positive modulo using slides.length
        return (slides.length + (index % slides.length)) % slides.length;
    }
    function getPos() {
        // Fix for the clone issue in the event of 2 slides
        var currentIndex = index;
        if (currentIndex >= length) {
            currentIndex = currentIndex - length;
        }
        return currentIndex;
    }
    function slide(to, slideSpeed) {
        // do nothing if already on requested slide
        if (index == to)
            return;
        if (browser.transitions) {
            var direction = Math.abs(index - to) / (index - to); // 1: backward, -1: forward
            // get the actual position of the slide
            if (options.continuous) {
                var natural_direction = direction;
                direction = -slidePos[circle(to)] / width;
                // if going forward but to < index, use to = slides.length + to
                // if going backward but to > index, use to = -slides.length + to
                if (direction !== natural_direction) {
                    to = -direction * slides.length + to;
                }
            }
            var diff = Math.abs(index - to) - 1;
            // move all the slides between index and to in the right direction
            while (diff--)
                move(circle((to > index ? to : index) - diff - 1), width * direction, 0);
            to = circle(to);
            move(index, width * direction, slideSpeed || speed);
            move(to, 0, slideSpeed || speed);
            if (options.continuous)
                move(circle(to - direction), -(width * direction), 0); // we need to get the next in place
        }
        else {
            to = circle(to);
            animate(index * -width, to * -width, slideSpeed || speed);
            //no fallback for a circular continuous if the browser does not accept transitions
        }
        index = to;
        offloadFn(options.transitionStart && options.transitionStart(getPos(), slides[index]));
    }
    function move(index, dist, speed) {
        translate(index, dist, speed);
        slidePos[index] = dist;
    }
    function translate(index, dist, speed) {
        var slide = slides[index];
        var style = slide && slide.style;
        if (!style) {
            return;
        }
        style.webkitTransitionDuration = speed + 'ms';
        // @ts-ignore
        style.MozTransitionDuration = speed + 'ms';
        // @ts-ignore
        style.msTransitionDuration = speed + 'ms';
        // @ts-ignore
        style.OTransitionDuration = speed + 'ms';
        style.transitionDuration = speed + 'ms';
        style.webkitTransform = 'translate(' + dist + 'px,0)' + 'translateZ(0)';
        // @ts-ignore
        style.msTransform = 'translateX(' + dist + 'px)';
        // @ts-ignore
        style.MozTransform = 'translateX(' + dist + 'px)';
        // @ts-ignore
        style.OTransform = 'translateX(' + dist + 'px)';
        style.transform = 'translateX(' + dist + 'px)';
    }
    function animate(from, to, speed) {
        // if not an animation, just reposition
        if (!speed) {
            element.style.left = to + 'px';
            return;
        }
        var start = +new Date;
        var timer = setInterval(function () {
            var timeElap = +new Date - start;
            if (timeElap > speed) {
                element.style.left = to + 'px';
                if (delay) {
                    begin();
                }
                options.transitionEnd && options.transitionEnd.call(event, getPos(), slides[index]);
                clearInterval(timer);
                return;
            }
            element.style.left = (((to - from) * (Math.floor((timeElap / speed) * 100) / 100)) + from) + 'px';
        }, 4);
    }
    // setup auto slideshow
    var delay = options.interval || 0;
    var interval;
    function begin() {
        interval = setTimeout(next, delay);
    }
    function stop() {
        delay = 0;
        clearTimeout(interval);
    }
    // setup initial vars
    var start = { x: 0, y: 0, time: 0 };
    var delta = { x: 0, y: 0 };
    var isScrolling;
    // setup event capturing
    var events = {
        handleEvent: function (event) {
            console.log('event', event.type);
            switch (event.type) {
                case 'mousedown':
                case 'touchstart':
                    this.start(event);
                    break;
                case 'mousemove':
                case 'touchmove':
                    this.move(event);
                    break;
                case 'mouseleave':
                case 'mouseup':
                case 'touchend': {
                    offloadFn(this.end(event));
                    break;
                }
                case 'webkitTransitionEnd':
                case 'msTransitionEnd':
                case 'oTransitionEnd':
                case 'otransitionend':
                case 'transitionend':
                    offloadFn(this.transitionEnd(event));
                    break;
                case 'resize':
                    offloadFn(setup);
                    break;
            }
            if (options.stopPropagation)
                event.stopPropagation();
        },
        start: function (event) {
            // console.log('start: ', event.type)
            var touches;
            if (isMouseEvent(event)) {
                touches = event;
                event.preventDefault();
            }
            else {
                touches = event.touches[0];
            }
            // measure start values
            start = {
                // get initial touch coords
                x: touches.pageX,
                y: touches.pageY,
                // store time to determine touch duration
                time: +new Date
            };
            // used for testing first move event
            isScrolling = undefined;
            // reset delta and end measurements
            delta = { x: 0, y: 0 };
            // attach touchmove and touchend listeners
            if (isMouseEvent(event)) {
                element.addEventListener('mousemove', this, false);
                element.addEventListener('mouseup', this, false);
                element.addEventListener('mouseleave', this, false);
            }
            else {
                element.addEventListener('touchmove', this, false);
                element.addEventListener('touchend', this, false);
            }
        },
        move: function (event) {
            var touches;
            if (isMouseEvent(event)) {
                touches = event;
            }
            else {
                // ensure swiping with one touch and not pinching
                // @ts-ignore
                if (event.touches.length > 1 || (event === null || event === void 0 ? void 0 : event.scale) && event.scale !== 1) {
                    return;
                }
                if (options.disableScroll)
                    event.preventDefault();
                touches = event.touches[0];
            }
            // measure change in x and y
            delta = {
                x: touches.pageX - start.x,
                y: touches.pageY - start.y
            };
            // determine if scrolling test has run - one time test
            if (typeof isScrolling == 'undefined') {
                isScrolling = !!(isScrolling || Math.abs(delta.x) < Math.abs(delta.y));
            }
            // if user is not trying to scroll vertically
            if (!isScrolling) {
                // prevent native scrolling
                event.preventDefault();
                // stop slideshow
                clearTimeout(interval);
                // increase resistance if first or last slide
                if (options.continuous) { // we don't add resistance at the end
                    translate(circle(index - 1), delta.x + slidePos[circle(index - 1)], 0);
                    translate(index, delta.x + slidePos[index], 0);
                    translate(circle(index + 1), delta.x + slidePos[circle(index + 1)], 0);
                }
                else {
                    delta.x =
                        delta.x /
                            ((!index && delta.x > 0 // if first slide and sliding left
                                || index == slides.length - 1 // or if last slide and sliding right
                                    && delta.x < 0 // and if sliding at all
                            ) ?
                                (Math.abs(delta.x) / width + 1) // determine resistance level
                                : 1); // no resistance if false
                    // translate 1:1
                    translate(index - 1, delta.x + slidePos[index - 1], 0);
                    translate(index, delta.x + slidePos[index], 0);
                    translate(index + 1, delta.x + slidePos[index + 1], 0);
                }
            }
        },
        end: function (event) {
            // measure duration
            var duration = +new Date - start.time;
            // determine if slide attempt triggers next/prev slide
            var isValidSlide = Number(duration) < 250 // if slide duration is less than 250ms
                && Math.abs(delta.x) > 20 // and if slide amt is greater than 20px
                || Math.abs(delta.x) > width / 2; // or if slide amt is greater than half the width
            // determine if slide attempt is past start and end
            var isPastBounds = !index && delta.x > 0 // if first slide and slide amt is greater than 0
                || index == slides.length - 1 && delta.x < 0; // or if last slide and slide amt is less than 0
            if (options.continuous) {
                isPastBounds = false;
            }
            // determine direction of swipe (true:right, false:left)
            var direction = delta.x < 0;
            // if not scrolling vertically
            if (!isScrolling) {
                if (isValidSlide && !isPastBounds) {
                    if (direction) {
                        if (options.continuous) { // we need to get the next in this direction in place
                            move(circle(index - 1), -width, 0);
                            move(circle(index + 2), width, 0);
                        }
                        else {
                            move(index - 1, -width, 0);
                        }
                        move(index, slidePos[index] - width, speed);
                        move(circle(index + 1), slidePos[circle(index + 1)] - width, speed);
                        index = circle(index + 1);
                    }
                    else {
                        if (options.continuous) { // we need to get the next in this direction in place
                            move(circle(index + 1), width, 0);
                            move(circle(index - 2), -width, 0);
                        }
                        else {
                            move(index + 1, width, 0);
                        }
                        move(index, slidePos[index] + width, speed);
                        move(circle(index - 1), slidePos[circle(index - 1)] + width, speed);
                        index = circle(index - 1);
                    }
                    options.transitionStart && options.transitionStart(getPos(), slides[index]);
                }
                else {
                    if (options.continuous) {
                        move(circle(index - 1), -width, speed);
                        move(index, 0, speed);
                        move(circle(index + 1), width, speed);
                    }
                    else {
                        move(index - 1, -width, speed);
                        move(index, 0, speed);
                        move(index + 1, width, speed);
                    }
                }
            }
            // kill touchmove and touchend event listeners until touchstart called again
            if (isMouseEvent(event)) {
                element.removeEventListener('mousemove', events, false);
                element.removeEventListener('mouseup', events, false);
                element.removeEventListener('mouseleave', events, false);
            }
            else {
                element.removeEventListener('touchmove', events, false);
                element.removeEventListener('touchend', events, false);
            }
        },
        transitionEnd: function (event) {
            var target = event === null || event === void 0 ? void 0 : event.target;
            if (parseInt(target === null || target === void 0 ? void 0 : target.getAttribute('data-index'), 10) == index) {
                if (delay) {
                    begin();
                }
                options.transitionEnd && options.transitionEnd.call(event, getPos(), slides[index]);
            }
        }
    };
    // trigger setup
    setup();
    // start auto slideshow if applicable
    if (delay) {
        begin();
    }
    // add event listeners
    if (browser.addEventListener) {
        // set touchstart event on element
        if (browser.touch)
            element.addEventListener('touchstart', events, false);
        element.addEventListener('mousedown', events, false);
        if (browser.transitions) {
            element.addEventListener('webkitTransitionEnd', events, false);
            element.addEventListener('msTransitionEnd', events, false);
            element.addEventListener('oTransitionEnd', events, false);
            element.addEventListener('otransitionend', events, false);
            element.addEventListener('transitionend', events, false);
        }
        // set resize event on window
        window.addEventListener('resize', events, false);
    }
    else {
        window.onresize = function () { setup(); }; // to play nice with old IE
    }
    var removeListener = function (eventList) {
        if (eventList === void 0) { eventList = ['touchstart', 'transitionEnd', 'resize', 'mousedown']; }
        if (browser.addEventListener) {
            // remove current event listeners
            if (eventList.indexOf('touchstart') > -1) {
                element.removeEventListener('touchstart', events, false);
            }
            if (eventList.indexOf('mousedown') > -1) {
                element.removeEventListener('mousedown', events, false);
            }
            if (eventList.indexOf('transitionEnd') > -1) {
                element.removeEventListener('webkitTransitionEnd', events, false);
                element.removeEventListener('msTransitionEnd', events, false);
                element.removeEventListener('oTransitionEnd', events, false);
                element.removeEventListener('otransitionend', events, false);
                element.removeEventListener('transitionend', events, false);
            }
            if (eventList.indexOf('resize') > -1) {
                window.removeEventListener('resize', events, false);
            }
        }
        else {
            if (eventList.indexOf('resize') > -1) {
                window.onresize = null;
            }
        }
    };
    // expose the Swipe API
    return {
        setup: function () {
            setup();
        },
        slide: function (to, speed) {
            // cancel slideshow
            stop();
            slide(to, speed);
        },
        prev: function () {
            // cancel slideshow
            stop();
            prev();
        },
        next: function () {
            // cancel slideshow
            stop();
            next();
        },
        stop: function () {
            // cancel slideshow
            stop();
        },
        getPos: function () {
            return getPos();
        },
        getNumSlides: function () {
            // return total number of slides
            return length;
        },
        kill: function () {
            // cancel slideshow
            stop();
            container.style.visibility = '';
            // reset element
            element.style.width = '';
            element.style.left = '';
            // reset slides
            var pos = slides.length;
            while (pos--) {
                if (browser.transitions) {
                    translate(pos, 0, 0);
                }
                var slide_2 = slides[pos];
                // if the slide is tagged as clone, remove it
                if (slide_2.getAttribute('data-cloned') == 'true') {
                    var _parent = slide_2.parentElement;
                    _parent === null || _parent === void 0 ? void 0 : _parent.removeChild(slide_2);
                }
                slide_2.style.width = '';
                slide_2.style.left = '';
            }
            // removed event listeners
            removeListener(['touchstart', 'transitionEnd', 'resize', 'mousedown']);
        },
        removeListener: removeListener
    };
}
