import useInView from "../../../../hooks/useInview";
import { findMultiLangStringValue } from "../../../../utils/resolveDisplayTemplate";
import { isUndefined, pickDSLData, px2em } from "../../../../utils/tools";
import classNames from "classnames";
import React from "react";
import './styles.less';
var clsPrefix = 'credit-promotion-sdk-customer-review-banner';
export var CustomerReviewBanner = function (props) {
    var _a, _b, _c;
    var dsl = props.dsl, locale = props.locale;
    if (!dsl) {
        return null;
    }
    var render = dsl.render, basic = dsl.basic;
    var bannerId = (basic || {}).bannerId;
    var bannerData;
    if (Array.isArray(render)) {
        bannerData = render[0];
    }
    else {
        bannerData = render;
    }
    var background = bannerData.background;
    var imageUrl = findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.image, locale);
    var ref = useInView({
        onEnter: function () {
            var _a;
            (_a = props.onEnter) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        onLeave: function () {
            var _a;
            (_a = props.onLeave) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        threshold: (_a = props.threshold) !== null && _a !== void 0 ? _a : 0.8
    })[0];
    return (React.createElement("div", { className: classNames(clsPrefix, props.className), style: props.style, key: "".concat(bannerId), ref: ref },
        React.createElement("div", { className: "".concat(clsPrefix, "-background"), style: {
                backgroundImage: "url(".concat(imageUrl, ")"),
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: !isUndefined((_b = props.config) === null || _b === void 0 ? void 0 : _b.radius) ? "".concat(px2em((_c = props.config) === null || _c === void 0 ? void 0 : _c.radius)) : "".concat(px2em(6))
            }, onClick: function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, dsl); } })));
};
