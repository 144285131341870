var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useImperativeHandle, useRef, useState, } from 'react';
import { renderToBody } from './render-to-body';
export function renderImperatively(element, config) {
    var defaultConfig = {
        visible: true,
        body: document.body
    };
    var cfg = __assign(__assign({}, defaultConfig), config);
    var Wrapper = React.forwardRef(function (_, ref) {
        var _a = useState(element), elementToRender = _a[0], setElementToRender = _a[1];
        var _b = useState(cfg.visible), visible = _b[0], setVisible = _b[1];
        var closedRef = useRef(!cfg.visible);
        var _c = useState(false), initialed = _c[0], setInitial = _c[1];
        var fnListRef = useRef([]);
        var keyRef = useRef(0);
        useEffect(function () {
            if (!closedRef.current) {
                setVisible(cfg.visible);
            }
            setInitial(true);
            for (var _i = 0, _a = fnListRef.current; _i < _a.length; _i++) {
                var fn = _a[_i];
                fn();
            }
            fnListRef.current = [];
        }, []);
        useImperativeHandle(ref, function () { return ({
            destroy: function () {
                var _a, _b, _c, _d;
                (_b = (_a = elementToRender.props).beforeClose) === null || _b === void 0 ? void 0 : _b.call(_a);
                closedRef.current = true;
                unmount();
                (_d = (_c = elementToRender.props).afterClose) === null || _d === void 0 ? void 0 : _d.call(_c);
            },
            hide: function () {
                if (closedRef.current) {
                    return;
                }
                var innerHidden = function () {
                    var _a, _b, _c, _d;
                    (_b = (_a = elementToRender.props).beforeClose) === null || _b === void 0 ? void 0 : _b.call(_a);
                    closedRef.current = true;
                    setVisible(false);
                    (_d = (_c = elementToRender.props).afterClose) === null || _d === void 0 ? void 0 : _d.call(_c);
                };
                if (initialed) {
                    innerHidden();
                }
                else {
                    fnListRef.current.push(innerHidden);
                }
            },
            show: function () {
                if (!closedRef.current) {
                    return;
                }
                var innerShow = function () {
                    var _a, _b, _c, _d;
                    closedRef.current = false;
                    (_b = (_a = elementToRender.props).beforeShow) === null || _b === void 0 ? void 0 : _b.call(_a);
                    setVisible(true);
                    (_d = (_c = elementToRender.props).afterShow) === null || _d === void 0 ? void 0 : _d.call(_c);
                };
                if (initialed) {
                    innerShow();
                }
                else {
                    fnListRef.current.push(innerShow);
                }
            },
            replace: function (element) {
                keyRef.current++;
                // elementToRender.props.afterClose?.()
                setElementToRender(element);
            }
        }); });
        return React.cloneElement(elementToRender, __assign(__assign({}, elementToRender.props), { key: keyRef.current, visible: visible }));
    });
    var wrapperRef = React.createRef();
    var unmount = renderToBody(React.createElement(Wrapper, { ref: wrapperRef }), cfg.body);
    return {
        destroy: function () {
            var _a;
            (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
        },
        hide: function () {
            var _a;
            (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.hide();
        },
        show: function () {
            var _a;
            (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.show();
        },
        replace: function (element) {
            var _a;
            (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.replace(element);
        }
    };
}
