var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import 'intersection-observer';
import { useRef, useCallback, } from "react";
/**
 * useObserver
 * @param callback IntersectionObserverCallback
 * @param options IntersectionObserverInit
 * @param externalState React.ComponentState[]
 */
var useObserver = function (callback, _a, externalState) {
    var _b = _a === void 0 ? {} : _a, root = _b.root, rootMargin = _b.rootMargin, threshold = _b.threshold;
    if (externalState === void 0) { externalState = []; }
    var target = useRef(null);
    var observer = useRef(null);
    var setTarget = useCallback(function (node) {
        if (target.current && observer.current) {
            observer.current.unobserve(target.current);
            observer.current.disconnect();
            observer.current = null;
        }
        if (node) {
            observer.current = new IntersectionObserver(callback, { root: root, rootMargin: rootMargin, threshold: threshold });
            observer.current.observe(node);
            target.current = node;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, __spreadArray([target, root, rootMargin, JSON.stringify(threshold)], externalState, true));
    return setTarget;
};
export default useObserver;
