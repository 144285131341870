import {onLCP, onFCP, onFID, onCLS, LCPMetric, FIDMetric, CLSMetric, FCPMetric} from 'web-vitals';

const supportsEntry = (type: string) => {
    return (
        window.PerformanceObserver &&
        window.PerformanceObserver.supportedEntryTypes &&
        window.PerformanceObserver.supportedEntryTypes.includes(type)
    );
}

export const isLCPSupport = supportsEntry('largest-contentful-paint')

export const isFCPSupport = supportsEntry('paint')

export const isFIDSupport = supportsEntry('first-input')

export const isCLSSupport = supportsEntry('layout-shift')

class WebVitals {
    static instance: WebVitals
    constructor() {
        if (!WebVitals.instance) {
            WebVitals.instance = this
        }
        return WebVitals.instance;
    }
    async getLCP(): Promise<number> {
        return new Promise((resolve, reject) => {
            if (!isLCPSupport) return reject(-1)
            onLCP((metric: LCPMetric) => {
                // console.log('拿到lcp', metric.value)
                resolve(Math.round(metric.value))
            })
        })
    }
    async getFCP(): Promise<number> {
        return new Promise((resolve, reject) => {
            if (!isFCPSupport) return reject(-1)
            onFCP((metric: FCPMetric) => {
                // console.log('拿到fcp', metric.value)
                resolve(Math.round(metric.value))
            })
        })
    }
    async getFID(): Promise<number> {
        return new Promise((resolve, reject) => {
            if (!isFIDSupport) return reject(-1)
            onFID((metric: FIDMetric) => {
                // console.log('拿到fid', metric.value)
                resolve(Math.round(metric.value))
            })
        })
    }
    // 这个指标只有在页面开始卸载后才会触发
    async getCLS(cb: Function) {
        if (!isCLSSupport) return
        onCLS((metric: CLSMetric) => {
            // https://web.dev/cls/
            // 这个值的评判标准是good: value <= 0.1 poor: value > 0.25
            // 由于insight也会对性能指标Math.round取整，这里乘以100，再做上传
            cb(Math.round(metric.value * 100))
        })
    }
}

export default new WebVitals()