var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { popupWidgetRegister, WIDGETS } from '../../register';
import { Congrats } from './congrats';
import { CongratsV2 } from './congratsV2';
import { Voucher } from './voucher';
import { VoucherV2 } from './voucherV2';
popupWidgetRegister.set(WIDGETS.POPUP_VOUCHER, Voucher);
popupWidgetRegister.set(WIDGETS.POPUP_CONGRATS, Congrats);
popupWidgetRegister.set(WIDGETS.POPUP_CONGRATS_V2, CongratsV2);
popupWidgetRegister.set(WIDGETS.POPUP_VOUCHER_V2, VoucherV2);
export var getWidgetProps = function (cmpList, id) {
    if (!cmpList) {
        return { key: id };
    }
    var _loop_1 = function (i) {
        var cmp = cmpList[i];
        if (cmp.id === id) {
            var props_1 = cmp.props;
            var keys = Object.keys(props_1);
            var parsedProps = keys.reduce(function (acc, item) {
                var val = props_1[item];
                try {
                    acc[item] = JSON.parse(val);
                }
                catch (e) {
                    acc[item] = val;
                }
                return acc;
            }, {});
            return { value: __assign({ key: id }, parsedProps) };
        }
    };
    for (var i = 0; i < cmpList.length; i++) {
        var state_1 = _loop_1(i);
        if (typeof state_1 === "object")
            return state_1.value;
    }
    return {
        key: id
    };
};
export var PopupSlotWidget = function (props) {
    var id = props.id, _a = props.slots, slots = _a === void 0 ? [] : _a, locale = props.locale, region = props.region, _b = props.layout, layout = _b === void 0 ? 'mobile' : _b, className = props.className, style = props.style;
    if (!id) {
        return null;
    }
    var Widget = popupWidgetRegister.get(id);
    if (!Widget) {
        return null;
    }
    var widgetProps = __assign(__assign({}, getWidgetProps(slots, id)), { locale: locale, region: region, layout: layout, className: className, style: style });
    return React.createElement(Widget, __assign({}, widgetProps));
};
