import { PerfCommonData, EXTER_PREF, Options, REPORT_TYPE } from '../index.interface'
import { reportFMP } from '../util/fmp'
// import { getPerfFcp } from '../util/fcp'
import getTTI from '../util/tti'
import webVitals from '../util/webVitals'
import { isAPISupport, getPerfTime } from '../util/perf'

const _win = window
const _perf: EXTER_PREF = { fmp: -9999, fcp: -9999, tti: -9999, lcp: -9999, fid: -9999 }
const PERF_KEY = { fmp: 'useFMP', fcp: 'useFCP', tti: 'useTTI', lcp: 'useLCP', fid: 'useFID' }
const INTERVAL_TIME = 600

let timer

function isFinishToReportPerf(options: Options) {
  // console.log('_perf data', _perf)
  return Object.keys(_perf).length
    ? Object.keys(_perf).every((item) => {
        return options[PERF_KEY[item]] ? !!(_perf[item] >= 0 || _perf[item] === -1) : true
      })
    : true
}

export const getCommonData = (): PerfCommonData => {
  // @ts-ignore
  const sr = `${screen.width}x${screen.height}`
  const dpr = (_win.devicePixelRatio || 1).toFixed(2)
  const common = {
    sr,
    dpr
  }
  return common
}

export const watchPerfData = async (options: Options) => {
  if (options.useFCP) {
    // getPerfFcp()
    //   .then((fcp) => {
    //     _perf.fcp = fcp
    //   })
    //   .catch(() => {
    //     delete _perf.fcp
    //   })
    webVitals.getFCP()
      .then((fcp) => {
        fcp && (_perf.fcp = fcp)
      })
      .catch(() => {
        _perf.fcp = -1
      })
  }
  if (options.useFMP) {
    reportFMP()
      .then((res) => {
        _perf.fmp = res
      })
      .catch(() => {
        delete _perf.fmp
      })
  }

  if (options.useTTI) {
    getTTI()
      .then((tti) => {
        !tti && (_perf.tti = -1)
        tti && (_perf.tti = tti)
      })
      .catch(() => {
        delete _perf.tti
      })
  }
  if (options.useLCP) {
    webVitals.getLCP()
      .then((lcp) => {
        lcp && (_perf.lcp = lcp)
      })
      .catch(() => {
        _perf.lcp = -1
      })
  }
  if (options.useFID) {
    webVitals.getFID()
      .then((fid) => {
        fid && (_perf.fid = fid)
      })
      .catch(() => {
        _perf.fid = -1
      })
  }
}

export const clearTimer = () => {
  timer && clearTimeout(timer)
}

export const reportPerf = (options: Options, cb: Function) => {
  if (isAPISupport()) {
    if (isFinishToReportPerf(options)) {
      // console.log('准备上报perf')
      cb({
        type: REPORT_TYPE.Perf,
        data: {
          ...getPerfTime(_perf),
          ..._perf
        }
      })
    } else {
      // console.log('继续等待perf')
      timer = setTimeout(() => {
        timer = null
        reportPerf(options, cb)
      }, INTERVAL_TIME)
    }
  }
}

export const reportCLS = (cb: Function) => {
  webVitals.getCLS((value) => {
    value && cb({
      type: REPORT_TYPE.Perf,
      data: {
        cls: value
      }
    })
  })
}
