const _store = window.localStorage

const STORE = {
  set: (key: string, value: any) => {
    switch (typeof value) {
      case 'object':
        return _store.setItem(key, JSON.stringify(value))
      default:
        return _store.setItem(key, value)
    }
  },

  get: (key: string) => {
    try {
      return JSON.parse(_store.getItem(key)!)
    } catch (e) {
      throw new Error('storage parse error')
    }
  },

  clear: () => {
    return _store.clear()
  },

  remove: (key: string) => {
    return _store.removeItem(key)
  }
}

export default STORE
