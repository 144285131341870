/* Visit模块： history state变化时获取页面流向信息, 记录在window._pageHistory内 */
import { addEvent, getPageRoute, parseUrl, bind } from '../util/common'
import { Options, REPORT_TYPE, PageHistory, EventDataType } from '../index.interface'
import STORE from '../util/storage'
import { pageHistoryKey } from '../util/constants'

const NEED_VISIT_EVENTS = ['replaceState', 'pushState', 'popstate', 'hashchange']

class MyViewAndVisit {
  _fromUrl: string = ''
  _recordFn

  public init(opt: Options, recordFn, getPageHistoryFn, setFromUrl, setPageHistoryFn) {
    const _this = this
    this._recordFn = recordFn
    this.initData(recordFn, getPageHistoryFn, setFromUrl)

    // 路由变化时注册函数
    NEED_VISIT_EVENTS.forEach((e) => {
      addEvent(
        window,
        e,
        () => {
          if (this.isSameUrl()) return
          setFromUrl(this._fromUrl)
          recordFn(_this.composeNextVisit(getPageHistoryFn))
          recordFn(_this.composeNextView())
          // 更新fromurl
          this._fromUrl = getPageRoute(window.location.href)
        },
        false
      )
    })
    // 注册didtabback事件，兼容webview
    bind('didTapBack', () => {
      _this.updateLastPageHistory(getPageHistoryFn, setPageHistoryFn)
    })
    // 监听beforeunload，应用卸载前更新数据
    addEvent(window, 'beforeunload', () =>
      _this.updateLastPageHistory(getPageHistoryFn, setPageHistoryFn)
    )
  }

  private initData(recordFn, getPageHistoryFn, setFromUrl) {
    setFromUrl(this._fromUrl)
    // 生成并记录首个Visit
    recordFn(this.composeNextVisit(getPageHistoryFn))

    // 生成并记录首个View
    recordFn(this.composeNextView())

    // 更新fromurl
    this._fromUrl = getPageRoute(location.href)
  }

  private updateLastPageHistory(getPageHistoryFn, setPageHistoryFn) {
    const pageHistory = getPageHistoryFn()
    const last = pageHistory[pageHistory.length - 1]
    const timeNow = new Date().getTime()
    if (last) {
      last.stay = timeNow - last.timestamp
      // 更新pagehistory
      setPageHistoryFn(pageHistory)
      // 上报当前页面的stay时间
      // this.reportStayType(last)
      STORE.set(pageHistoryKey, pageHistory)
    }
  }

  private composeNextVisit(getPageHistoryFn: Function): PageHistory {
    const pageHistory = getPageHistoryFn()
    const last = pageHistory[pageHistory.length - 1]
    const timeNow = new Date().getTime()
    // 判断上个页面的stay是否为0，如果不为0，则不需要更新
    if (last && !last.stay) {
      last.stay = timeNow - last.timestamp
      // 上报上个页面的stay时间
      // this.reportStayType(last)
    }
    return {
      channel: parseUrl().channel,
      stay: 0,
      type: REPORT_TYPE.Visit
    }
  }

  private reportStayType(data: PageHistory) {
    this._recordFn({
      type: REPORT_TYPE.Stay,
      data: {
        stay: data.stay
      },
      url: data.url,
      fromUrl: data.fromUrl
    })
  }

  private composeNextView(): EventDataType {
    return {
      type: REPORT_TYPE.View,
      data: {
        channel: parseUrl().channel
      }
    }
  }

  private isSameUrl() {
    return this._fromUrl === getPageRoute(location.href)
  }
}

const ViewAndVisit = new MyViewAndVisit()

export default ViewAndVisit
