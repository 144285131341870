// 多语言支持  
// ID  https://transify.sea.com/resources/3360
// TH  https://transify.sea.com/resources/3361
// PH  https://transify.sea.com/resources/3708
// VN  https://transify.sea.com/resources/4069
// BR  https://transify.sea.com/resources/4070
// MY  https://transify.sea.com/resources/4068
// SG  https://transify.sea.com/resources/4071
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import TSPSDK from "tsp-sdk";
import id_en from './id/en.json';
import id_id from './id/id.json';
import th_en from './th/en.json';
import th_th from './th/th.json';
import ph_en from './ph/en.json';
import br_en from './br/en.json';
import br_pt_BR from './br/pt-BR.json';
import my_en from './my/en.json';
import my_zh_Hans from './my/zh-Hans.json';
import my_ms_my from './my/ms-my.json';
import sg_en from './sg/en.json';
import sg_zh_Hans from './sg/zh-Hans.json';
import sg_sg from './sg/sg.json';
import vn_en from './vn/en.json';
import vn_vi from './vn/vi.json';
import { getSDKInfo } from "../utils/sdkInfo";
var translator = new TSPSDK({
    name: 'promotion-web-sdk'
});
var tspEnvMap = {
    'live': 'live',
    'test': 'nonlive',
    'uat': 'nonlive',
    'staging': 'nonlive'
};
var initialized = false;
var setTranslatorEnv = function () { return __awaiter(void 0, void 0, void 0, function () {
    var sdkInfo;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (initialized) {
                    return [2 /*return*/, initialized];
                }
                initialized = getSDKInfo();
                return [4 /*yield*/, initialized];
            case 1:
                sdkInfo = _a.sent();
                translator.create({
                    env: tspEnvMap[sdkInfo.env] || 'live'
                });
                initialized = true;
                return [2 /*return*/];
        }
    });
}); };
// 各个国家的语言种类
var LANGES_CATEGORY = {
// id: [],
// th: []
};
var defaultLanges = {
    id_en: id_en,
    id_id: id_id,
    th_en: th_en,
    th_th: th_th,
    ph_en: ph_en,
    br_en: br_en,
    'br_pt-BR': br_pt_BR,
    my_en: my_en,
    'my_zh-Hans': my_zh_Hans,
    'my_ms-my': my_ms_my,
    sg_en: sg_en,
    'sg_zh-Hans': sg_zh_Hans,
    sg_sg: sg_sg,
    vn_en: vn_en,
    vn_vi: vn_vi,
};
var langes = {};
var merge = function (old, fresh) {
    var o = __assign({}, old);
    var keys = Object.keys(fresh);
    keys.forEach(function (key) { return o[key] = fresh[key]; });
    return o;
};
Object.keys(defaultLanges).forEach(function (key) {
    var keys = key.split('_');
    var region = keys[0];
    var lang = keys.slice(1).join('-');
    if (!LANGES_CATEGORY[region]) {
        LANGES_CATEGORY[region] = [];
    }
    LANGES_CATEGORY[region].push(lang);
});
var promiseMap = {};
var createFetchPromise = function (resource, region, locale, layout) { return __awaiter(void 0, void 0, void 0, function () {
    var id, p;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, setTranslatorEnv()];
            case 1:
                _a.sent();
                id = "".concat(region, "_").concat(locale);
                if (promiseMap[id]) {
                    return [2 /*return*/, Promise.resolve()];
                }
                p = translator.loadTranslation({
                    resources: [resource]
                }, locale).then(function (res) {
                    console.log("[".concat(region.toUpperCase(), "]: load ").concat(locale, " lang"), res);
                    if (!res || !Object.keys(res).length) {
                        if (layout === 'desk') {
                            throw new Error('fetch empty translation');
                        }
                        langes[id] = defaultLanges[id];
                    }
                    else {
                        langes[id] = merge(defaultLanges[id], res);
                        // langes[id] = defaultLanges[id]
                    }
                }).catch(function (e) {
                    if (layout === 'desk') {
                        throw e;
                    }
                    langes[id] = defaultLanges[id];
                });
                promiseMap[id] = p;
                return [2 /*return*/];
        }
    });
}); };
var RESOURCES = {
    id: 3360,
    th: 3361,
    ph: 3708,
    br: 4070,
    my: 4068,
    sg: 4071,
    vn: 4069
};
var getLanges = function (region, locale, layout) { return __awaiter(void 0, void 0, void 0, function () {
    var id, keys, values;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                id = "".concat(region, "_").concat(locale);
                if (!!langes[id]) return [3 /*break*/, 2];
                return [4 /*yield*/, createFetchPromise(RESOURCES[region], region, locale, layout)];
            case 1:
                _a.sent();
                _a.label = 2;
            case 2:
                keys = Object.keys(promiseMap);
                values = Object.values(promiseMap);
                return [2 /*return*/, Promise.all(Object.values(values))
                        .catch(function (e) {
                        if (layout === 'desk') {
                            throw e;
                        }
                        keys.map(function (k) { return langes[k] = defaultLanges[k]; });
                    })];
        }
    });
}); };
export { LANGES_CATEGORY, defaultLanges, getLanges };
export default langes;
