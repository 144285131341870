var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import BaseVoucherCard from '../BaseVoucherCard';
import { ReactComponent as CloseSvg } from '../BaseVoucherCard/images/close.svg';
import { createFormatMessage } from '../../utils/intl';
import classNames from 'classnames';
import './styles.less';
var SelectionCard = function (props) {
    var closable = props.closable, applicable = props.applicable, className = props.className, restProps = __rest(props, ["closable", "applicable", "className"]);
    var formatMessage = createFormatMessage(props.locale, props.region);
    return (React.createElement(BaseVoucherCard, __assign({ showUse: false, className: classNames(className, 'promotion-voucher-card-selection', !applicable && 'promotion-voucher-card-unapplicable') }, restProps, { extra: function () {
            return (React.createElement(React.Fragment, null,
                closable &&
                    React.createElement(CloseSvg, { onClick: props.onClose, className: "promotion-voucher-card-close-img" }),
                (applicable && props.showApply) &&
                    React.createElement("button", { onClick: props.onApply, className: "promotion-voucher-card-apply" }, formatMessage({ id: 'voucher.apply' })),
                props.showT_C && React.createElement("div", { onClick: props.onT_CClick, className: 'promotion-voucher-card-t_c' }, formatMessage({ id: 'voucher.T_C' }))));
        } })));
};
SelectionCard.defaultProps = {
    closable: true,
    applicable: true,
    showT_C: true
};
export default SelectionCard;
