import { ReactComponent as CardSVG } from './images/card.svg';
import { createFormatMessage } from "../../utils/intl";
import React from 'react';
import './styles.less';
import classNames from 'classnames';
var prefixCls = 'promotion-voucher-error';
var Error = function (props) {
    var locale = props.locale, region = props.region, className = props.className;
    var formatMessage = createFormatMessage(locale, region);
    return (React.createElement("div", { className: classNames(prefixCls, className) },
        React.createElement("div", { className: "".concat(prefixCls, "-card") },
            React.createElement(CardSVG, null)),
        React.createElement("div", { className: "".concat(prefixCls, "-retry-reminder") }, formatMessage({ id: 'voucher.selection.error' })),
        React.createElement("div", { className: "".concat(prefixCls, "-retry-btn"), onClick: props === null || props === void 0 ? void 0 : props.retry }, "Retry")));
};
export default Error;
