var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import MarketingPopup from './marketing';
import VoucherPopup from './voucher';
import VoucherPopupV2 from './voucherV2';
import { popupTemplateRegister, TEMPLATES } from "../../register";
import { createLogger } from "../../../monitor/logger";
import MarketingNoSysBtnPopup from "./marketingNotBtn";
popupTemplateRegister.set(TEMPLATES.MARKETING_POPUP, MarketingPopup);
popupTemplateRegister.set(TEMPLATES.MARKETING_POPUP_NO_SYS_BTN, MarketingNoSysBtnPopup);
popupTemplateRegister.set(TEMPLATES.VOUCHER_POPUP, VoucherPopup);
popupTemplateRegister.set(TEMPLATES.VOUCHER_POPUP_V2, VoucherPopupV2);
var logger = createLogger('PromoWebSDK:Popup Template');
export var PopupEvent = function (props) {
    if (!props.dsl) {
        return null;
    }
    var _a = props.dsl, version = _a.version, config = _a.config, render = _a.render;
    return (React.createElement(React.Fragment, null, render === null || render === void 0 ? void 0 : render.map(function (tpl, index) {
        var _a, _b;
        var basic = tpl.basic;
        if (!basic) {
            return null;
        }
        var template = basic.template;
        var Renderer = popupTemplateRegister.get(template);
        if (!Renderer) {
            logger.error('Not found Widget with template: ' + template);
            return null;
        }
        var childDSL = tpl;
        var key = "".concat((_b = (_a = childDSL.basic) === null || _a === void 0 ? void 0 : _a.bannerId) !== null && _b !== void 0 ? _b : template, "-").concat(index, "-").concat(version);
        // config 应该 merge，不应该直接覆盖
        var renderProps = __assign(__assign({}, props), { config: config, dsl: childDSL });
        return React.createElement(Renderer, __assign({ key: key }, renderProps));
    })));
};
