import { createLogger } from "../monitor/logger";
import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import langes, { defaultLanges } from "../locales";
dayjs.extend(Duration);
var varReg = /\{([^}]+)\}/g;
var logger = createLogger('PromoWebSDK:Intl');
var defaultLange = 'en';
var defaultRegion = 'id';
export function formatMessage(options, locale, region) {
    if (locale === void 0) { locale = defaultLange; }
    if (region === void 0) { region = defaultRegion; }
    var langId = region + '_' + locale;
    var lang = langes[langId];
    if (!lang) {
        lang = defaultLanges[langId]
            || defaultLanges["".concat(region, "_").concat(defaultLange)]
            || defaultLanges["".concat(defaultRegion, "_").concat(defaultLange)];
        if (Object.keys(langes).length > 0) {
            logger.error("FormatMessage Failed, Empty lang with langId=".concat(langId, ", options=").concat(JSON.stringify(options, null, 2), ", default lang will be used"));
        }
    }
    var message = lang[options.id];
    if (!message && !!options.id) {
        logger.error("FormatMessage Failed, Return empty message: region=".concat(region, " ; locale=").concat(locale, " ; options=").concat(JSON.stringify(options, null, 2)));
        return '';
    }
    if (!options.values)
        return message;
    return message.replace(varReg, function (_, key) { var _a; return ((_a = options.values) === null || _a === void 0 ? void 0 : _a[key]) || ''; });
}
export function createFormatMessage(locale, region) {
    if (locale === void 0) { locale = defaultLange; }
    if (region === void 0) { region = defaultRegion; }
    return function (options) { return formatMessage(options, locale, region); };
}
