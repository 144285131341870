import Initial from "../../../Initial";
import { findMultiLangStringValue } from "../../../../utils/resolveDisplayTemplate";
import { createFormatMessage } from "../../../../utils/intl";
import classNames from 'classnames';
import React from 'react';
import Time from "../../../../utils/time";
import './styles.less';
var clsPrefix = 'credit-promotion-slot-widget-banner-voucher';
export var Voucher = function (props) {
    var locale = props.locale, region = props.region, layout = props.layout, className = props.className, userVoucherInfo = props.userVoucherInfo;
    var detail = (userVoucherInfo || {}).detail;
    var _a = detail || {}, displayTemplate = _a.displayTemplate, _b = _a.extraInfo, extraInfo = _b === void 0 ? '{}' : _b;
    if (!displayTemplate) {
        return (React.createElement("div", { className: classNames(className, clsPrefix, "".concat(layout, "-layout")) }));
    }
    var formatMessage = createFormatMessage(locale, region);
    Time.config({ region: region });
    var header;
    try {
        var extraData = JSON.parse(extraInfo);
        header = typeof extraData.header == 'object' ? extraData.header : displayTemplate.header;
    }
    catch (e) {
        header = displayTemplate.header;
    }
    return (React.createElement(Initial, { locale: locale, region: region },
        React.createElement("div", { className: classNames(className, clsPrefix, "".concat(layout, "-layout")) },
            React.createElement("div", { className: "".concat(clsPrefix, "-header") }, findMultiLangStringValue(header, locale)),
            React.createElement("div", { className: "".concat(clsPrefix, "-valid-to") },
                formatMessage({
                    id: 'banner.voucher.valid_till',
                    values: {
                        date: Time.format(detail.validityTo * 1000, 'DD MMM YYYY')
                    }
                }),
                " "))));
};
