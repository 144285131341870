var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import React, { useCallback, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import './styles.less';
import { DSLVersion, slideTemplateRegister, TEMPLATES, WIDGETS } from '../register';
import { isFunction, stringToNumber } from "../../utils/tools";
import classNames from 'classnames';
import { useUnmountedRef } from "../../hooks";
import ItemCard from './template/ItemCard';
import { activityEventTrigger } from "../../apis";
import { createLogger } from "../../monitor/logger";
import BITracker from "../../utils/BITracker";
import IconCard from './template/IconCard';
import { RangeSlider } from './RangeSlider';
var clsPrefix = 'credit-promotion-sdk-slide';
slideTemplateRegister.set(TEMPLATES.ITEM_CARD_SLIDE, ItemCard);
slideTemplateRegister.set(TEMPLATES.ICON_CARD_SLIDE, IconCard);
var logger = createLogger('PromoWebSDK:Slide');
var tracker = new BITracker();
var getItemPercent = function (num) {
    var gap = Math.floor(num) * 16;
    var percent = Math.floor((375 - gap) / 375 * 100);
    return "".concat(percent / num, "%");
};
var itemNum = (_a = {},
    _a[TEMPLATES.ITEM_CARD_SLIDE] = 2.5,
    _a[TEMPLATES.ICON_CARD_SLIDE] = 3,
    _a);
var SlideBannerEvent = function (props, ref) {
    var _a = useState([]), cardList = _a[0], setCardList = _a[1];
    var _b = useState(false), showIndicator = _b[0], setShowIndicator = _b[1];
    var unmountedRef = useUnmountedRef();
    var wrapperRef = useRef(null);
    var fetcher = props.fetcher, eventCode = props.eventCode, dsl = props.dsl, locale = props.locale, region = props.region, bizType = props.bizType, _c = props.layout, layout = _c === void 0 ? 'mobile' : _c;
    var fetchData = useCallback(function () {
        if (dsl) {
            return Promise.resolve(dsl);
        }
        var realFetcher = !fetcher
            ? function () { return activityEventTrigger({ eventCode: eventCode, region: region, bizType: bizType }); }
            : (isFunction(fetcher) ? fetcher : null);
        if (!realFetcher) {
            return Promise.resolve(null);
        }
        var p = realFetcher();
        return Promise.resolve(p).then(function (res) {
            var _a, _b;
            if (res.code !== 0) {
                throw res;
            }
            var result = res.result;
            var bannerInfo = (result || {}).bannerInfo;
            var render = (_a = bannerInfo === null || bannerInfo === void 0 ? void 0 : bannerInfo.renderList) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                var parsed;
                try {
                    parsed = JSON.parse(item);
                }
                catch (error) {
                    logger.error(error);
                }
                return parsed;
            });
            var dsl = {
                version: DSLVersion,
                config: stringToNumber(bannerInfo === null || bannerInfo === void 0 ? void 0 : bannerInfo.spaceConfig),
                render: (render === null || render === void 0 ? void 0 : render.filter(function (item) { return !!item; })) || []
            };
            (_b = props === null || props === void 0 ? void 0 : props.onFetchSuccess) === null || _b === void 0 ? void 0 : _b.call(props, dsl);
            return dsl;
        }).catch(function (e) {
            var _a;
            (_a = props === null || props === void 0 ? void 0 : props.onFetchError) === null || _a === void 0 ? void 0 : _a.call(props, e);
            console.error(e);
            throw e;
        });
    }, [dsl, eventCode, fetcher]);
    useEffect(function () {
        fetchData().then(function (res) {
            var _a;
            if (!res) {
                return;
            }
            if (unmountedRef.current) {
                return;
            }
            setCardList(res.render);
            setShowIndicator(!!((_a = res.config) === null || _a === void 0 ? void 0 : _a.showIndicator));
        });
        return function () {
        };
    }, [dsl]);
    useImperativeHandle(ref, function () { return ({
        scrollTo: function (x, y) { var _a; return (_a = wrapperRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(x, y); }
    }); });
    return (React.createElement("div", { style: props.style, className: classNames(clsPrefix, "".concat(layout, "-layout")) },
        React.createElement("div", { className: "".concat(clsPrefix, "-wrapper"), ref: wrapperRef }, cardList === null || cardList === void 0 ? void 0 : cardList.map(function (banner, index) {
            var basic = banner.basic;
            if (!basic) {
                return null;
            }
            var template = basic.template;
            var TPLRenderer = slideTemplateRegister.get(template);
            if (!TPLRenderer) {
                return null;
            }
            var widgetProps = {
                locale: locale,
                region: region,
                layout: layout,
                dsl: banner,
                onClick: function (data) {
                    var _a, _b, _c;
                    tracker.trackClickEvent({
                        page_type: props.pageType,
                        page_section: BITracker.getPageSection(data.template),
                        data: {
                            from_source: props.fromSource,
                            banner_id: data.bannerId,
                            banner_name: data.name,
                            banner_url: ((_a = data.btnBehavior) === null || _a === void 0 ? void 0 : _a.content) || ((_b = data.behavior) === null || _b === void 0 ? void 0 : _b.content),
                            image_hash: data.backgroundImage,
                            location: index
                        }
                    });
                    (_c = props.onClick) === null || _c === void 0 ? void 0 : _c.call(props, data);
                },
                onEnter: function (data) {
                    var _a, _b, _c;
                    tracker.trackImpressionEvent({
                        page_type: props.pageType,
                        page_section: BITracker.getPageSection(data.template),
                        data: {
                            from_source: props.fromSource,
                            banner_id: data.bannerId,
                            banner_name: data.name,
                            banner_url: ((_a = data.btnBehavior) === null || _a === void 0 ? void 0 : _a.content) || ((_b = data.behavior) === null || _b === void 0 ? void 0 : _b.content),
                            image_hash: data.backgroundImage,
                            location: index
                        }
                    });
                    (_c = props.onEnter) === null || _c === void 0 ? void 0 : _c.call(props, data);
                },
                onLeave: props.onLeave,
                threshold: props.threshold
            };
            return (React.createElement("div", { className: classNames("".concat(clsPrefix, "-item"), props.slideClassName), style: __assign(__assign({}, props.slideStyle), { width: getItemPercent(itemNum[template] || 2.5) }), key: "".concat(basic.bannerId, "_").concat(index) },
                React.createElement(TPLRenderer, __assign({}, widgetProps))));
        })),
        !!cardList.length && showIndicator && (React.createElement(RangeSlider, { className: "".concat(clsPrefix, "-range-slider"), sliderRef: wrapperRef }))));
};
export default Object.assign(forwardRef(SlideBannerEvent), {
    TEMPLATES: TEMPLATES,
    WIDGETS: WIDGETS
});
