var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
// credit product
export var CREDIT_PRODUCT = {
    BCL: 1,
    SCL: 2,
    SPL: 3
};
// voucher type
export var VOUCHER_TYPE = {
    ADMIN_FEE_PERCENTAGE: 1,
    INSTALMENT_FEE_PERCENTAGE: 2,
    // REPAYMENT_PERCENTAGE: 3,
    ADMIN_FEE_ABSOLUTE: 4,
    INSTALMENT_FEE_ABSOLUTE: 5,
    REPAYMENT_ABSOLUTE: 6
};
export var isAdminFee = function (rewardType) {
    return (rewardType === VOUCHER_TYPE.ADMIN_FEE_ABSOLUTE
        || rewardType === VOUCHER_TYPE.ADMIN_FEE_PERCENTAGE);
};
export var isInstalmentFee = function (rewardType) {
    return (rewardType === VOUCHER_TYPE.INSTALMENT_FEE_ABSOLUTE
        || rewardType === VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE);
};
export var isRepayment = function (rewardType) {
    return rewardType === VOUCHER_TYPE.REPAYMENT_ABSOLUTE;
};
export var isPercentageType = function (rewardType) {
    return (rewardType === VOUCHER_TYPE.ADMIN_FEE_PERCENTAGE
        || rewardType === VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE);
};
export var isAbsoluteType = function (rewardType) {
    return (rewardType === VOUCHER_TYPE.ADMIN_FEE_ABSOLUTE
        || rewardType === VOUCHER_TYPE.REPAYMENT_ABSOLUTE
        || rewardType === VOUCHER_TYPE.INSTALMENT_FEE_ABSOLUTE);
};
// icon box
export var ICON_DATA = {
    productName: (_a = {},
        _a[CREDIT_PRODUCT.BCL] = 'buyer_cashloan_voucher',
        _a[CREDIT_PRODUCT.SCL] = 'seller_cashloan_voucher',
        _a[CREDIT_PRODUCT.SPL] = 'spl_repayment_voucher',
        _a),
    id: {
        icon: (_b = {},
            _b[CREDIT_PRODUCT.BCL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_BCL-20220118.png',
            _b[CREDIT_PRODUCT.SCL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_SCL-20220118.png',
            _b[CREDIT_PRODUCT.SPL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_SPL_2022040802.png',
            _b)
    },
    th: {
        icon: (_c = {},
            _c[CREDIT_PRODUCT.BCL] = 'https://cdn.scredit.sg/th/promotion_admin/live/TH_BCL-20220118.png',
            _c[CREDIT_PRODUCT.SCL] = 'https://cdn.scredit.sg/th/promotion_admin/live/TH_SCL-20220118.png',
            _c)
    },
    ph: {
        icon: (_d = {},
            _d[CREDIT_PRODUCT.BCL] = 'https://cdn.scredit.sg/ph/promotion/live/PH_BCL_20220511.png',
            _d[CREDIT_PRODUCT.SCL] = 'https://cdn.scredit.sg/ph/promotion/live/PH_SCL_20220511.png',
            _d[CREDIT_PRODUCT.SPL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_SPL_2022040802.png',
            _d)
    },
    my: {
        icon: (_e = {},
            _e[CREDIT_PRODUCT.BCL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_BCL-20220118.png',
            _e[CREDIT_PRODUCT.SCL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_SCL-20220118.png',
            _e[CREDIT_PRODUCT.SPL] = 'https://cdn.scredit.sg/id/promotion_admin/live/ID_SPL_2022040802.png',
            _e)
    },
    sg: {
        icon: (_f = {},
            _f[CREDIT_PRODUCT.BCL] = '',
            _f[CREDIT_PRODUCT.SCL] = '',
            _f)
    },
    br: {
        icon: (_g = {},
            _g[CREDIT_PRODUCT.BCL] = '',
            _g[CREDIT_PRODUCT.SCL] = '',
            _g)
    },
    vn: {
        icon: (_h = {},
            _h[CREDIT_PRODUCT.BCL] = '',
            _h[CREDIT_PRODUCT.SCL] = '',
            _h)
    }
};
export var VOUCHER_STATUS = {
    VALID: 1,
    USED: 2,
    EXPIRED: 3
};
export var Voucher_Status_Text = (_j = {},
    _j[VOUCHER_STATUS.VALID] = 'valid',
    _j[VOUCHER_STATUS.USED] = 'used',
    _j[VOUCHER_STATUS.EXPIRED] = 'expired',
    _j);
export var ApplicableInstalmentTypes = {
    firstInstalment: 1
};
export var PaymentMethodEnum;
(function (PaymentMethodEnum) {
    PaymentMethodEnum[PaymentMethodEnum["shopeePay"] = 8001400] = "shopeePay";
    PaymentMethodEnum[PaymentMethodEnum["InstaPay"] = 4019500] = "InstaPay";
    PaymentMethodEnum[PaymentMethodEnum["ShopeePayMy"] = 2001400] = "ShopeePayMy";
})(PaymentMethodEnum || (PaymentMethodEnum = {}));
export var PaymentMethodDesc = (_k = {},
    _k[PaymentMethodEnum.shopeePay] = 'ShopeePay',
    _k[PaymentMethodEnum.InstaPay] = 'InstaPay',
    _k[PaymentMethodEnum.ShopeePayMy] = 'ShopeePay',
    _k);
export var BannerBackground = {
    popupV1: 'https://cdn.scredit.sg/id/promotion/live/voucher_popup_bg_v1.png',
    popupV2: 'https://cdn.scredit.sg/id/promotion/live/voucher_popup_bg_v2.png',
    bannerV1: 'https://cdn.scredit.sg/id/promotion/live/voucher_banner_bg_v1.png'
};
