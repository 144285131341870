import { Options, REPORT_TYPE, ApiParamsType } from '../index.interface'
import { addEvent, getPageRoute, is } from './common'

export const DEFAULT_API_HELPER = [{ rule: /(\w+)\/\d{2,}/g, target: '$1' }]
export const DEFAULT_URL_HELPER = [
  { rule: /\/([a-z\-_]+)?\d{2,20}/g, target: '/$1**' } /* 将所有 path 中的数字变成 * */,
  /\/$/ /* 将末尾/去掉 */
]
export const DEFAULT_PARSE_RESPONSE = (res: any) => {
  if (!res || typeof res !== 'object') return {}
  const success = Number(res.code) === 0 ? 1 : 0
  let msg =
    res.msg || res.message || res.subMsg || res.errorMsg || res.ret || res.errorResponse || ''
  if (typeof msg === 'object') {
    msg = msg.msg || msg.message || msg.info || msg.ret || JSON.stringify(msg)
  }
  return { code: res.code, msg, success }
}

class MyApi {
  _options: any = {}
  recordFn: any = () => {}

  // 供index调用，初始化
  public init(options: Options, recordFn: Function) {
    this._options = options
    this.recordFn = recordFn
    this.initFetchHook()
    this.initXhrHook()
  }

  private isOnlyApi(url: string) {
    /**
     * 上报的api要排除资源url，这里判断url是否包含.后缀,同时上报的请求url也不计入上报
     */
    if (url.indexOf('.') > -1) {
      return false
    }

    // 白名单逻辑，如果whiteListApis参数有传，就用白名单逻辑，不用黑名单逻辑
    const whiteListApis = this._options?.whiteListApis
    if (Array.isArray(whiteListApis) && whiteListApis?.length > 0) {
      return whiteListApis?.some((api) => {
        try {
          // 正则
          if (api instanceof RegExp) {
            return api.test(url)
          }
          // 自定义函数
          if (typeof api === 'function') {
            return api(url)
          }
          // api是string
          return url.includes(api) || url.split('/').pop()?.includes(api.split('/').pop())
        } catch (e) {
          return false
        }
      })
    }

    const isIgnore = this._options?.ignore?.ignoreApis?.some((api) => {
      try {
        // 正则
        if (api instanceof RegExp) {
          return api.test(url)
        }
        // 自定义函数
        if (typeof api === 'function') {
          return api(url)
        }
        // api是string
        return url.includes(api) || url.split('/').pop()?.includes(api.split('/').pop())
      } catch (e) {
        return false
      }
    })

    if (isIgnore) return false
    if (url.includes(getPageRoute(this._options.url!))) {
      return false
    }
    return true
  }

  // 重写全局Fetch
  private initFetchHook() {
    const apiAddr = getPageRoute(this._options.url!)
    const __oFetch__: any = window.fetch

    window.fetch = (input: RequestInfo | URL, init?: RequestInit) => {
      if (input === undefined) {
        throw new Error('fetch params can not be empty')
      }
      let api
      if (typeof input === 'string') {
        api = input
      } else if (is<URL>(input, 'href')) {
        api = input.href
      } else if (is<Request>(input, 'url')) {
        api = input.url
      }

      // 移除协议和查询参数，去掉api中的数字（id等）
      api = getPageRoute(api)
      // 上传数据的基本字段，其他字段在index中补充
      const obj: ApiParamsType = {
        path: api,
        start: +new Date(),
        duration: 0,
        httpStatus: 0
      }
      return __oFetch__
        .apply(window, [input, init])
        .then(async (response: Response) => {
          obj.duration = this.getValidDuration(+new Date() - obj.start)
          obj.timestamp = +new Date()
          obj.httpStatus = response.status
          if (response?.headers?.get('content-type')?.includes('application/json')) {
            const consumeResponse = response.clone()
            const result = await consumeResponse.json()
            obj.requestId = result.request_id
          }
          return response
        })
        .catch((err) => {
          if (api !== apiAddr) {
            obj.duration = +new Date() - obj.start
          }
          obj.httpStatus = 0
          throw err
        })
        .finally(() => {
          delete obj.start
          this.isOnlyApi(obj.path) && this.updateApiList(obj)
        })
    }
  }

  private initXhrHook() {
    const apiAddr = getPageRoute(this._options.url!)
    const __oXMLHttpRequest__ = window.XMLHttpRequest
    // @ts-ignore
    window.XMLHttpRequest = () => {
      const xhr: XMLHttpRequest = new __oXMLHttpRequest__()
      const __open__ = xhr.open
      const __send__ = xhr.send

      // 上传数据的基本字段，其他字段在index中补充
      const obj: ApiParamsType = {
        path: '',
        start: +new Date(),
        duration: 0,
        httpStatus: 0
      }
      // @ts-ignore
      xhr.open = (method, url, async = true) => {
        // 移除协议和查询参数，去掉api中的数字（id等）
        if (typeof url === 'string') {
          obj.path = getPageRoute(url)
        } else {
          obj.path = getPageRoute(url.href)
        }
        __open__.apply(xhr, [method, url, async])
      }
      xhr.send = (data) => {
        obj.start = Date.now()
        __send__.apply(xhr, [data])
      }
      addEvent(xhr, 'readystatechange', () => {
        if (xhr.readyState === 4 && obj.path !== apiAddr) {
          // 以状态码在 200~299 外为网络异常
          obj.duration = this.getValidDuration(+new Date() - obj.start)
          obj.timestamp = +new Date()
          obj.httpStatus = xhr.status
          try {
            obj.requestId = this.getRequestId(xhr.response)
          } catch (error) {
            obj.requestId = ''
          }
          delete obj.start
          this.isOnlyApi(obj.path) && this.updateApiList(obj)
        }
      })
      return xhr
    }

    Object.setPrototypeOf(XMLHttpRequest, __oXMLHttpRequest__.prototype)
  }

  // 解析string,获取request id
  getRequestId(data: string) {
    if (data.indexOf('request_id') > -1) {
      const keyValueAry = data.split(',')
      const requestIdValue = keyValueAry.filter((key) => key.indexOf('request_id') > -1)[0]
      const result = requestIdValue.split(':')[1]
      return result
    }
  }

  // 耗时超过50s，以50s上报
  getValidDuration(duration: number) {
    if (duration > 50000) {
      return 50000
    }
    return duration
  }

  updateApiList(data: ApiParamsType) {
    this.recordFn({
      type: REPORT_TYPE.Api,
      data
    })
  }

  // // 聚合筛选url或api  eg. apple/10010/age 变成 apple/*/age
  // private polyUrlAndApi(str: any, rulesArr: any) {
  //   if (!Array.isArray(rulesArr)) {
  //     rulesArr = [rulesArr]
  //   }
  //   for (let i = 0; i < rulesArr.length; i++) {
  //     const rule = rulesArr[i]
  //     const ruleType = Object.prototype.toString.call(rule)
  //     if (ruleType === '[object Function]') {
  //       str = rule(str)
  //     }
  //     if (ruleType === '[object String]' || ruleType === '[object RegExp]') {
  //       str = str.replace(rule, '')
  //     }
  //     if (ruleType === '[object Object]') {
  //       str = str.replace(rule.rule, rule.target)
  //     }
  //   }
  //   return str
  // }

  // // 计算是否应该上报
  // private shouldReport(obj: any) {
  //   console.log('api', obj)
  //   const ensureIsArr = (item) => {
  //     return Array.isArray(item) ? item : [item]
  //   }
  //   obj = JSON.parse(JSON.stringify(obj))
  //   if (Number(obj.success) === 1) return false // 成功请求不上报

  //   if (!this.matchIgnoreCase(ensureIsArr(this._options.ignore!.ignoreApis), obj.api)) return false
  //   if (!this.matchIgnoreCase(ensureIsArr(this._options.ignore!.ignoreUrls), obj.url)) return false
  //   if (!this.matchIgnoreCase(ensureIsArr(this._options.ignore!.ignoreCode), obj.code)) return false
  //   return true
  // }

  // private matchIgnoreCase(regArr: any, target: any) {
  //   if (!regArr || !regArr.length) return true
  //   if (target === undefined || target === '') return false
  //   for (let i = 0; i < regArr.length; i++) {
  //     const item = regArr[i]
  //     const regType = Object.prototype.toString.call(item)
  //     if (regType === '[object RegExp]') {
  //       if (target.match(item)) return false
  //     }
  //     if (regType === '[object Function]') {
  //       const bool = item(target)
  //       if (!bool) return false
  //     }
  //     if (regType === '[object String]') {
  //       if (target.includes(item)) return false
  //     }
  //     if (regType === '[object Number]') {
  //       if (item === target * 1) return false
  //     }
  //   }
  //   return true
  // }
}

const API = new MyApi()
export default API
