import Initial from "../../../Initial";
import { createFormatMessage } from "../../../../utils/intl";
import classNames from 'classnames';
import React from 'react';
import './styles.less';
var clsPrefix = 'credit-promotion-slot-widget-popup-congrats-v2';
export var CongratsV2 = function (props) {
    var productName = props.productName, locale = props.locale, region = props.region, layout = props.layout, className = props.className;
    var formatMessage = createFormatMessage(locale, region);
    return (React.createElement(Initial, { locale: locale, region: region },
        React.createElement("div", { className: classNames(className, clsPrefix, "".concat(layout, "-layout")) },
            React.createElement("div", { className: "".concat(clsPrefix, "-title") }, formatMessage({ id: 'popup.voucher.congrats' })),
            React.createElement("div", { className: "".concat(clsPrefix, "-sub-title") }, formatMessage({
                id: 'popup.voucher.from',
                values: {
                    productName: formatMessage({ id: "voucher.".concat(productName) })
                }
            })))));
};
