import { MdapSdk, CustomPlugin } from '@mdap/javascript-sdk';
import APIPlugin from '@mdap/sdk-plugin-api';
import ExceptionPlugin from '@mdap/sdk-plugin-exception';
var secretKeys = {
    live: '058ba370801f10580ee4385b9e1cae155e1b77c58b05b11b6dcf6a5da20effc6',
    test: 'aae1a65cca3ad0a475202429efb1439198dc1cf07fee53bf18d10a119873e927'
};
var pattern = /([0-9]+)\.([0-9]+)\.([0-9]+)(-(([a-z]+)([.-]([0-9]+))?)?)?/;
var versionParse = function (version) {
    if (!version) {
        throw new Error('missing input version');
    }
    var match = version.match(pattern);
    if (!match) {
        throw new Error('invalid version - does not match pattern ' + pattern);
    }
    var rv = {
        major: Number(match[1]),
        minor: Number(match[2]),
        patch: Number(match[3]),
        preReleaseLabel: null,
        preReleaseType: null,
        preReleaseIncrement: null
    };
    if (match[5]) {
        Object.assign(rv, { preReleaseLabel: match[5] });
        Object.assign(rv, { preReleaseType: match[6] });
        if (match[8]) {
            Object.assign(rv, { preReleaseIncrement: Number(match[8]) });
        }
    }
    return rv;
};
var getEnv = function (version) {
    var v = versionParse(version);
    if (v.preReleaseLabel) {
        return 'test';
    }
    return 'live';
};
var ENV = getEnv(SDK_VERSION);
var sdk = new MdapSdk({
    app_name: 'credit-promotion-web-sdk',
    secret_key: secretKeys[ENV],
    environment: ENV,
    app_version: SDK_VERSION,
    sample: 1
});
var api = new APIPlugin({
    path: '/api/promotion',
    useLogicStatus: true
});
var exception = new ExceptionPlugin();
var customReporter = new CustomPlugin({});
sdk.use(api);
sdk.use(exception);
sdk.use(customReporter);
export var apiPlugin = api;
export var exceptionPlugin = exception;
export var customPlugin = customReporter;
