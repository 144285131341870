var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState, useCallback, } from "react";
import useObserver from "./useObserver";
/**
 * useInView
 * @param options IntersectionObserverInit
 * @param externalState React.ComponentState[]
 */
var useInView = function (_a, externalState) {
    var _b = _a === void 0 ? {} : _a, root = _b.root, rootMargin = _b.rootMargin, threshold = _b.threshold, unobserveOnEnter = _b.unobserveOnEnter, target = _b.target, onEnter = _b.onEnter, onLeave = _b.onLeave, defaultInView = _b.defaultInView;
    if (externalState === void 0) { externalState = []; }
    var _c = useState({
        inView: defaultInView || false,
        entry: null,
        observer: null,
    }), state = _c[0], setState = _c[1];
    var callback = useCallback(function (_a, observer) {
        var entry = _a[0];
        var inThreshold = observer.thresholds.some(function (t) { return entry.intersectionRatio >= t; });
        var inView = inThreshold && entry.isIntersecting;
        setState({
            inView: inView,
            entry: entry,
            observer: observer,
        });
        // unobserveOnEnter
        if (inView && unobserveOnEnter) {
            observer.unobserve(entry.target);
            observer.disconnect();
        }
        // Legacy callbacks
        if (inView) {
            onEnter === null || onEnter === void 0 ? void 0 : onEnter(entry, observer);
        }
        else {
            onLeave === null || onLeave === void 0 ? void 0 : onLeave(entry, observer);
        }
    }, [onEnter, onLeave, unobserveOnEnter]);
    var setTarget = useObserver(callback, { root: root, rootMargin: rootMargin, threshold: threshold }, __spreadArray([unobserveOnEnter], externalState, true));
    // Legacy 'target' option
    useEffect(function () {
        if (target === null || target === void 0 ? void 0 : target.current)
            setTarget(target.current);
    }, [target, setTarget]);
    return [setTarget, state.inView, state.entry, state.observer];
};
export default useInView;
