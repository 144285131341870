var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getWidgetProps } from "../../Popup/widgets";
import React from 'react';
import { bannerWidgetRegister, WIDGETS } from '../../register';
import { Voucher } from './voucher';
bannerWidgetRegister.set(WIDGETS.BANNER_VOUCHER, Voucher);
export var BannerSlotWidget = function (props) {
    var id = props.id, _a = props.slots, slots = _a === void 0 ? [] : _a, locale = props.locale, region = props.region, _b = props.layout, layout = _b === void 0 ? 'mobile' : _b, className = props.className, style = props.style;
    if (!id) {
        return null;
    }
    var Widget = bannerWidgetRegister.get(id);
    if (!Widget) {
        return null;
    }
    var widgetProps = __assign(__assign({}, getWidgetProps(slots, id)), { locale: locale, region: region, layout: layout, className: className, style: style });
    return React.createElement(Widget, __assign({}, widgetProps));
};
