import { CommonConfig } from '../config';
import Time from './time';
import getValidTime from './getValidTime';
import { switchKeys, toSnakeCase } from '@credit/utils';
var VOUCHER_STATUS = CommonConfig.VOUCHER_STATUS;
export function findMultiLangStringValue(multiLangStrings, locale) {
    var _a, _b;
    var values = multiLangStrings === null || multiLangStrings === void 0 ? void 0 : multiLangStrings.values;
    if (!values)
        return '';
    for (var i = 0; i < values.length; i++) {
        if (values[i].lang === locale) {
            return values[i].value;
        }
    }
    for (var i = 0; i < values.length; i++) {
        if (((_a = values[i].lang) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'en') {
            return values[i].value;
        }
    }
    return ((_b = values[0]) === null || _b === void 0 ? void 0 : _b.value) || '';
}
export function resolveDisplayTemplate(_a) {
    var _b;
    var region = _a.region, locale = _a.locale, dataSource = _a.dataSource, _c = _a.voucherStatus, voucherStatus = _c === void 0 ? VOUCHER_STATUS.VALID : _c;
    if (!dataSource)
        return {};
    if (region && Time._locale !== region) {
        Time.config({
            region: region,
        });
    }
    var displayTemplate = switchKeys(dataSource.displayTemplate, toSnakeCase);
    var labelTextList = [];
    var iconImage = '';
    var iconText = '';
    var headerText = '';
    var subHeaderText = '';
    if (displayTemplate) {
        iconImage = displayTemplate.voucher_icon;
        iconText = findMultiLangStringValue(displayTemplate.icon_text, locale);
        headerText = findMultiLangStringValue(displayTemplate.header, locale);
        subHeaderText = findMultiLangStringValue(displayTemplate.sub_header, locale);
        labelTextList =
            ((_b = displayTemplate.information_labels) === null || _b === void 0 ? void 0 : _b.map(function (label) { return findMultiLangStringValue(label, locale); })) ||
                [];
    }
    return {
        iconImage: iconImage,
        iconText: iconText,
        headerText: headerText,
        subHeaderText: subHeaderText,
        labelTextList: labelTextList,
        validTime: getValidTime((!!dataSource.validityTo ? dataSource.validityTo : 0) * 1000, voucherStatus, locale, region)
    };
}
