var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createFormatMessage } from "../../utils/intl";
import classNames from "classnames";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ReactComponent as ChoiceIcon } from './images/voucher-choice.svg';
import { ReactComponent as NoChoiceIcon } from './images/voucher-no-choice.svg';
import { ReactComponent as RightArrow } from './images/right-arrow-grey.svg';
import VoucherDrawer from "../VoucherDrawer";
import './styles.less';
import Initial from "../Initial";
import { findMultiLangStringValue } from "../../utils/resolveDisplayTemplate";
var classPrefix = "credit-promotion-sdk-voucher-entry";
export var VoucherEntry = function (props, ref) {
    var locale = props.locale, region = props.region;
    var formatMessage = createFormatMessage(locale, region);
    var _a = useState(props.drawerVisible || false), visible = _a[0], setVisible = _a[1];
    var entryRef = useRef(null);
    // Fix: https://github.com/umijs/umi/issues/5287
    var fixRef = useRef(null);
    var _b = useState(), selectedVoucher = _b[0], setSelectedVoucher = _b[1];
    var imperativeHandler = function () { return ({
        showDrawer: function () { return setVisible(true); },
        hideDrawer: function () { return setVisible(false); },
        applyVoucher: function (id) { var _a; return (_a = entryRef.current) === null || _a === void 0 ? void 0 : _a.applyVoucher(id); },
        unApplyVoucher: function () { var _a; return (_a = entryRef.current) === null || _a === void 0 ? void 0 : _a.unApplyVoucher(); },
        reload: function () { var _a; return (_a = entryRef.current) === null || _a === void 0 ? void 0 : _a.reload(); }
    }); };
    useImperativeHandle(ref, imperativeHandler);
    useImperativeHandle(fixRef, imperativeHandler);
    useEffect(function () {
        setVisible(!!props.drawerVisible);
    }, [props.drawerVisible]);
    useEffect(function () {
        if (props.onRefReady) {
            props.onRefReady(fixRef.current);
        }
    }, []);
    var getSelectedVoucherHeader = function () {
        var header;
        try {
            var extraData = JSON.parse((selectedVoucher === null || selectedVoucher === void 0 ? void 0 : selectedVoucher.extraInfo) || '{}');
            header = typeof extraData.header == 'object' ? extraData.header : selectedVoucher === null || selectedVoucher === void 0 ? void 0 : selectedVoucher.displayTemplate.header;
        }
        catch (e) {
            header = selectedVoucher === null || selectedVoucher === void 0 ? void 0 : selectedVoucher.displayTemplate.header;
        }
        return header;
    };
    return (React.createElement(Initial, { region: region, locale: locale },
        React.createElement("section", { className: "".concat(classPrefix) },
            React.createElement("div", { className: "".concat(classPrefix, "-voucher-item"), onClick: function () {
                    setVisible(true);
                } },
                React.createElement("div", { className: "".concat(classPrefix, "-left-voucher-icon") },
                    (selectedVoucher === null || selectedVoucher === void 0 ? void 0 : selectedVoucher.usable) ? React.createElement(ChoiceIcon, { viewBox: "0 0 48 48", className: "icon" }) : React.createElement(NoChoiceIcon, { viewBox: "0 0 48 48", className: "icon" }),
                    React.createElement("span", { className: "title" }, formatMessage({ id: 'voucher.apply.discount' }))),
                React.createElement("div", { className: "".concat(classPrefix, "-voucher-right-area") },
                    selectedVoucher ? (React.createElement("span", { className: classNames('vouchers-right-title', {
                            'title-disabled': !selectedVoucher.usable,
                        }) }, findMultiLangStringValue(getSelectedVoucherHeader(), locale))) : (React.createElement("span", { className: 'voucher-select' }, formatMessage({ id: 'voucher.apply.select' }))),
                    React.createElement(RightArrow, { className: classNames('right_arrow', 'allow-right') }))),
            selectedVoucher && !(selectedVoucher === null || selectedVoucher === void 0 ? void 0 : selectedVoucher.usable) && props.reminder !== false && (React.createElement("div", { className: "".concat(classPrefix, "-voucher-disabled") }, typeof props.reminder === 'function' ? props.reminder() : formatMessage({ id: selectedVoucher === null || selectedVoucher === void 0 ? void 0 : selectedVoucher.unusableMsg }))),
            React.createElement(VoucherDrawer, __assign({}, props, { visible: visible, ref: entryRef, onMaskClick: function () { return setVisible(false); }, onClose: function () { return setVisible(false); }, onApplyVoucher: function (voucher, idx) {
                    var _a;
                    setSelectedVoucher(voucher);
                    (_a = props.onApplyVoucher) === null || _a === void 0 ? void 0 : _a.call(props, voucher, idx);
                }, onUnApplyVoucher: function (voucher) {
                    var _a;
                    setSelectedVoucher(undefined);
                    (_a = props.onUnApplyVoucher) === null || _a === void 0 ? void 0 : _a.call(props, voucher);
                }, onFetchEnd: function (res, useable, error) {
                    var _a, _b, _c, _d, _e;
                    if (!res) {
                        (_a = props.onFetchEnd) === null || _a === void 0 ? void 0 : _a.call(props, null, undefined, error);
                        return;
                    }
                    var result = res.result, code = res.code;
                    var list = (result === null || result === void 0 ? void 0 : result.list) || [];
                    if (code !== 0) {
                        (_b = props.onFetchEnd) === null || _b === void 0 ? void 0 : _b.call(props, null, undefined, new Error(JSON.stringify(res)));
                        return;
                    }
                    var defaultApply = list.find(function (item) { return item.userVoucherId === props.defaultApplyVoucherId; });
                    if (props.autoSelectVoucher && !props.defaultApplyVoucherId) {
                        setSelectedVoucher(useable);
                        if (useable) {
                            (_c = entryRef.current) === null || _c === void 0 ? void 0 : _c.applyVoucher(useable === null || useable === void 0 ? void 0 : useable.userVoucherId);
                        }
                    }
                    else {
                        setSelectedVoucher(defaultApply);
                        if (defaultApply) {
                            (_d = entryRef.current) === null || _d === void 0 ? void 0 : _d.applyVoucher(defaultApply === null || defaultApply === void 0 ? void 0 : defaultApply.userVoucherId);
                        }
                    }
                    (_e = props.onFetchEnd) === null || _e === void 0 ? void 0 : _e.call(props, res, useable);
                } })))));
};
export default forwardRef(VoucherEntry);
