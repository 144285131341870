var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect, useState } from 'react';
import Drawer from '../Drawer';
import VoucherSelection from '../VoucherSelection';
import { ReactComponent as CloseSvg } from './images/close.svg';
import './styles.less';
import classNames from 'classnames';
import { createFormatMessage } from "../../utils/intl";
import Initial from '../Initial';
import { getBizTypeText } from "../../utils/tools";
var classPrefix = "credit-promotion-sdk-voucher-drawer";
var defaultStyle = { height: '50vh', overflowY: 'scroll' };
var VoucherDrawer = function (props, ref) {
    var locale = props.locale, region = props.region;
    var _a = useState(props.visible || false), visible = _a[0], setVisible = _a[1];
    var formatMessage = createFormatMessage(locale, region);
    useEffect(function () {
        var _a;
        setVisible(props.visible || false);
        (_a = props.onDrawerVisibleChange) === null || _a === void 0 ? void 0 : _a.call(props, props.visible || false);
    }, [props.visible]);
    return (React.createElement(Initial, { locale: props.locale, region: props.region },
        React.createElement(Drawer, { visible: visible, onMaskClick: props.onMaskClick, className: classPrefix },
            React.createElement("div", null,
                React.createElement("div", { className: "".concat(classPrefix, "-header") },
                    React.createElement("div", null, formatMessage({ id: "voucher.drawer.title.".concat(getBizTypeText(props.bizType)) })),
                    React.createElement(CloseSvg, { className: classNames("".concat(classPrefix, "-close")), onClick: props.onClose, viewBox: "0 0 24 24" })),
                React.createElement("div", { className: "".concat(classPrefix, "-wrapper"), style: __assign(__assign({}, defaultStyle), props.style) },
                    React.createElement(VoucherSelection, __assign({}, props, { source: props.fromSource, visible: visible, ref: ref })))))));
};
export default forwardRef(VoucherDrawer);
