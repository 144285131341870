var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useMemo, useRef, useState } from 'react';
import { useLockScroll, useUnmountedRef } from '../../hooks';
import { useSpring, animated } from '@react-spring/web';
import { renderToContainer } from '../../utils/dom/render-to-container';
import { withStopPropagation } from '../../utils/dom/stop-propagation';
import './styles.less';
import classNames from 'classnames';
var classPrefix = "credit-promotion-sdk-mask";
var opacityRecord = {
    default: 0.40,
    thin: 0.25,
    thick: 0.55,
};
var defaultProps = {
    visible: true,
    color: 'black',
    opacity: 'default',
    disableBodyScroll: true,
    stopPropagation: ['click'],
    getContainer: function () { return document.body; }
};
export var Mask = function (p) {
    var props = __assign(__assign({}, defaultProps), p);
    var ref = useRef(null);
    useLockScroll(ref, props.visible && props.disableBodyScroll, props.getContainer);
    var background = useMemo(function () {
        var _a;
        var opacity = (_a = opacityRecord[props.opacity]) !== null && _a !== void 0 ? _a : props.opacity;
        var rgb = props.color === 'white' ? '255, 255, 255' : '0, 0, 0';
        return "rgba(".concat(rgb, ", ").concat(opacity, ")");
    }, [props.color, props.opacity]);
    var _a = useState(props.visible), active = _a[0], setActive = _a[1];
    var unmountedRef = useUnmountedRef();
    var opacity = useSpring({
        opacity: props.visible ? 1 : 0,
        config: {
            precision: 0.01,
            mass: 1,
            tension: 250,
            friction: 30,
            clamp: true,
        },
        onStart: function () {
            setActive(true);
        },
        onRest: function () {
            if (unmountedRef.current) {
                return;
            }
            setActive(props.visible);
        },
    }).opacity;
    var node = withStopPropagation(props.stopPropagation, React.createElement(animated.div, { className: classNames(classPrefix, props.className), ref: ref, style: __assign(__assign({}, props.style), { background: background, opacity: opacity, display: active ? undefined : 'none' }), onClick: function (e) {
            var _a;
            // target: The element that triggered the event (e.g., the user clicked on)
            // currentTarget: The element that the event listener is attached to.
            if (e.target === e.currentTarget) {
                (_a = props.onMaskClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }
        } },
        React.createElement("div", { className: "".concat(classPrefix, "-content") }, props.children)));
    return (React.createElement(React.Fragment, null, renderToContainer(node, props.container)));
};
