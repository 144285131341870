import { Options, Headers, ReportMessage, ReportReqParams } from '../index.interface'
import { getCommonHeader } from '../util/common'

export const xhrReport = (url: string, data: ReportReqParams, headers?: Headers) => {
  let client: XMLHttpRequest | null = new XMLHttpRequest()
  client.withCredentials = true
  client.open('POST', url, true)
  headers &&
    Object.keys(headers).forEach((key) => {
      client!.setRequestHeader(key, headers[key])
    })
  client.onreadystatechange = function() {
    if (client !== null && client.readyState === 4) client = null
  }
  client.send(JSON.stringify(data))
}

export const fetchReport = (url: string, data: ReportReqParams) => {
  fetch(url, {
    headers: {
      "accept": "application/json",
      "content-type": "application/json; charset=UTF-8"
    },
    body: JSON.stringify(data),
    method: 'POST',
    mode: "cors",
    credentials: "omit"
  })
}

// const beaconReport = (url: string, data: ReportReqParams) => {
//   const blob = new Blob([JSON.stringify(data)], {
//     type: 'application/json'
//   })
//   navigator.sendBeacon(url, blob)
// }

export const report = async (_options: Options, data: ReportMessage) => {
  const { url, headers, useWeb } = _options
  const params: ReportReqParams = {
    header: await getCommonHeader(_options),
    message: data
  }
  const headersInfo: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  }
  // 如果是web上报，则用beacon
  if (useWeb) {
    fetchReport(url!, params)
    // beaconReport(url!, params)
  } else {
    _options.xhr ? _options.xhr(params) : xhrReport(url!, params, headers || headersInfo)
  }
}
