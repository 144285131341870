import { Perf } from '../index.interface'

export const isTimingAPISupport = (): boolean => {
    return !!(window.performance && window.performance.timing)
}

export const getPerfByTimingAPI = (extraData: any): Perf => {
    const t = window.performance.timing
    const result: Perf = {
        // 阶段耗时
        dns: t.domainLookupEnd - t.domainLookupStart,
        tcp: t.connectEnd - t.connectStart,
        sslready: t.secureConnectionStart > 0 ? t.connectEnd - t.secureConnectionStart : 0,
        ttfb: t.responseStart - t.navigationStart,
        trans: t.responseEnd - t.responseStart,
        domanalysis: t.domInteractive - t.responseEnd,
        resload: t.loadEventStart - t.domContentLoadedEventEnd,
        // 关键性能指标
        firstbyte: t.responseStart - t.domainLookupStart,
        fpt: t.responseEnd - t.fetchStart,
        // tti: t.domContentLoadedEventEnd - t.fetchStart,
        domready: t.domContentLoadedEventEnd - t.fetchStart,
        loaded: t.loadEventEnd - t.fetchStart,
        ...extraData
    }
    return result
}

// 部分机型performance.getEntriesByType('navigation')拿到是空数组
export const isGetEntriesAPISupport = (): boolean => {
    return !!(window.performance && window.performance.getEntriesByType && window.performance.getEntriesByType('navigation') && window.performance.getEntriesByType('navigation')[0])
}

export const getPerfByNavigationTiming = (extraData: any): Perf => {
    const navigationTiming = window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    const result: Perf = {
        // 阶段耗时
        dns: navigationTiming.domainLookupEnd - navigationTiming.domainLookupStart,
        tcp: navigationTiming.connectEnd - navigationTiming.connectStart,
        sslready: navigationTiming.secureConnectionStart > 0 ? navigationTiming.connectEnd - navigationTiming.secureConnectionStart : 0,
        ttfb: navigationTiming.responseStart,
        trans: navigationTiming.responseEnd - navigationTiming.responseStart,
        domanalysis: navigationTiming.domInteractive - navigationTiming.responseEnd,
        resload: navigationTiming.loadEventStart - navigationTiming.domContentLoadedEventEnd,
        // 关键性能指标
        firstbyte: navigationTiming.responseStart - navigationTiming.domainLookupStart,
        fpt: navigationTiming.responseEnd - navigationTiming.fetchStart,
        // tti: navigationTiming.domContentLoadedEventEnd - navigationTiming.fetchStart,
        domready: navigationTiming.domContentLoadedEventEnd - navigationTiming.fetchStart,
        loaded: navigationTiming.loadEventEnd - navigationTiming.fetchStart,
        ...extraData,
    };
    return result;
};

export const isAPISupport = () => {
    return isGetEntriesAPISupport() || isTimingAPISupport()
}

export const getPerfTime = (extraData: any): Perf | undefined => {
    try {
        if (isGetEntriesAPISupport()) {
            return getPerfByNavigationTiming(extraData)
        }
        if (isTimingAPISupport()) {
            return getPerfByTimingAPI(extraData)
        }
    } catch (e) {
        console.error('getPerfTime error: ', e)
        return undefined
    }
}
