import { getLanges } from "../../locales";
import React, { useEffect, useState } from "react";
var Initial = function (props) {
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    useEffect(function () {
        getLanges(props.region, props.locale)
            .finally(function () {
            setLoading(false);
        });
    });
    if (loading) {
        return null;
    }
    return (React.createElement(React.Fragment, null, props.children));
};
export default Initial;
