export var DSLVersion = '0.0.1';
var SimpleMap = /** @class */ (function () {
    function SimpleMap() {
        this.data = {};
    }
    SimpleMap.prototype.set = function (key, value) {
        if (this.data[key]) {
            console.warn('Duplicate key: ', key);
        }
        this.data[key] = value;
    };
    SimpleMap.prototype.get = function (key) {
        return this.data[key];
    };
    return SimpleMap;
}());
export var registerCreator = function (registerId) {
    var map = new SimpleMap();
    var id = typeof Symbol == 'function' ? Symbol(registerId) : registerId;
    return {
        set: function (name, cmp) {
            map.set(name, cmp);
        },
        get: function (name) { return map.get(name); },
        id: function () { return id; }
    };
};
export var TEMPLATES = {
    MARKETING_POPUP: 'marketing-popup-v1',
    MARKETING_POPUP_NO_SYS_BTN: 'marketing-popup-no-sys-btn',
    VOUCHER_POPUP: 'voucher-popup-v1',
    VOUCHER_POPUP_V2: 'voucher-popup-v2',
    MARKETING_BANNER: 'marketing-banner-v1',
    VOUCHER_BANNER: 'voucher-banner-v1',
    TIP_BANNER: 'tip-banner-v1',
    CUSTOMER_REVIEW_BANNER: 'customer-review-banner-v1',
    ITEM_CARD_SLIDE: 'item-card-slide-v1',
    ICON_CARD_SLIDE: 'icon-card-slide-v1'
};
export var WIDGETS = {
    BANNER_VOUCHER: 'banner-voucher',
    POPUP_VOUCHER: 'popup-voucher',
    POPUP_VOUCHER_V2: 'popup-voucher-v2',
    POPUP_CONGRATS: 'popup-congrats',
    POPUP_CONGRATS_V2: 'popup-congrats-v2',
};
// Popup
export var popupTemplateRegister = registerCreator('popup-template');
export var popupWidgetRegister = registerCreator('popup-widget');
// Banner
export var bannerTemplateRegister = registerCreator('banner-template');
export var bannerWidgetRegister = registerCreator('banner-widget');
// Slide
export var slideTemplateRegister = registerCreator('slide-template');
