import {
  Options,
  REPORT_TYPE,
  PageHistory,
  EventDataType,
  CMD,
  BridgeTypeEnum,
  MetricConfig,
  ReportCustomMetricParams
} from './index.interface'
import { addEvent, hookRouterStateChange, getEventCommonParams } from './util/common'
import { watchPerfData, reportPerf, reportCLS } from './services/perf'
// import { reportRes } from './util/res'
import { report } from './services/report'
import STORE from './util/storage'
import API from './util/api'
import ViewAndVisit from './services/viewAndVisit'
import CustomBrigde from './util/bridge'
import {
  REPORT_TIME_INTERVAL,
  MAX_EVENTLIST_LENGTH,
  eventListKey,
  pageHistoryKey,
  bridgeListKey
} from './util/constants'

/*
  文档地址: https://www.yuque.com/ygihrv/tfgnvx/imlp8v
  暴露的方法有
  init:初始化sdk参数
  reportClickEvent 点击事件上报
  updateEventList 自定义上报事件
  reportStorage 把localstorage的数据上报，提供给不支持beforeunload的webview使用
*/

let CHECKER

declare global {
  interface Window {
    [property: string]: any
  }
}

class Tracer {
  private _options: Options = {
    appId: '', // 预申请的应用id
    region: '',
    bizType: '',
    isLive: true,
    url: '',
    autoReportView: true,
    autoReportApi: true,
    autoReportPerf: true,
    autoReportVisit: true,
    sampleRate: 1,
    // parseResponse: DEFAULT_PARSE_RESPONSE,
    // urlHelper: DEFAULT_URL_HELPER,
    // apiHelper: DEFAULT_API_HELPER,
    ignore: {
      // ignoreUrls: [], // 废弃了
      ignoreApis: [],
      // ignoreCode: [] // 废弃了
    },
    whiteListApis: [],
    useFMP: true,
    useFCP: true,
    useTTI: true,
    useLCP: true,
    useFID: true,
    useWeb: false,
  }

  private _eventList: EventDataType[] = []
  private _bridgeList: any = {}
  private _pageHistory: PageHistory[] = []
  private _fromUrl: string = ''

  private getPageHistory() {
    return this._pageHistory
  }

  private setPageHistory(pageHistory: PageHistory[]) {
    this._pageHistory = pageHistory
  }

  public init(options: Options) {
    // console.log('insight初始化')
    if (options !== undefined && typeof options === 'object') {
      Object.assign(this._options, options)
      this.initUrl()
      // this.validate() // 验证是否传入appId
      this.initSettings() // 初始化设置
      this.initListeners() // 挂载监听函数
      this.initChecker() // 启动定时器
    }
  }

  private initUrl() {
    const { useWeb, isLive } = this._options
    if (useWeb) {
      this._options.url = isLive
        ? 'https://insight.shopeekredit.co.id/api/report'
        : 'https://insight.test.shopeekredit.co.id/api/report'
    } else {
      !this._options.url && (this._options.url = `${window.location.origin}/credit/insight/fe_chat`)
    }
  }

  // // STEP ZERO: 验证是否传入appId
  // private validate() {
  //   if (!this._options.appId) {
  //     throw new Error('options.appId is required')
  //   }
  // }

  // STEP ONE: 初始化配置信息
  private initSettings(): void {
    this.reportStorage()
  }

  // STEP TWO: 监听器及钩子
  private initListeners(): void {
    const _this = this
    // 监听perf性能数据
    watchPerfData(_this._options)

    // router state变化添加hook
    hookRouterStateChange()
    API.init(_this._options, _this.recordData.bind(_this))
    new CustomBrigde({
      updateEventList: this.updateEventList.bind(this),
      cacheBridge: this.cacheBridge.bind(this),
      deleteBridge: this.deleteBridge.bind(this),
      getCacheBridge: this.getCacheBridge.bind(this)
    })

    // 页面访问/接口返回 监听启动
    ViewAndVisit.init(
      _this._options,
      _this.recordData.bind(_this),
      _this.getPageHistory.bind(_this),
      _this.setFromUrl.bind(_this),
      _this.setPageHistory.bind(_this)
    )

    // 获取公共数据
    // const common = getCommonData()

    // 添加load事件
    addEvent(window, 'load', () => {
      reportPerf(_this._options, _this.recordData.bind(_this))
      reportCLS(_this.recordData.bind(_this))
      // reportRes(common, _this.recordData.bind(_this))
    })

    // 资源加载监听
    // SPLIT_CHUNKS_RES_EVENTS.forEach((e) => {
    //   addEvent(window, e, () => {
    //     reportRes(common, _this.recordData.bind(_this))
    //   })
    // })

    // 页面unload前的操作
    addEvent(window, 'beforeunload', () => clearInterval(CHECKER))
    addEvent(window, 'beforeunload', () => this.checkEventList())
    addEvent(window, 'beforeunload', () => this.reportPageHistory())
  }

  // STEP THREE: 启动上报定时检查器,定时上报事件列表和pageHistory列表
  private initChecker(): void {
    const _this = this
    CHECKER = setInterval(() => _this.checkEventList(), REPORT_TIME_INTERVAL)
  }

  public reportStorage() {
    this.checkStorage()
    this.clearAll()
  }

  // 检查localStorage中是否有未上报的数据
  private checkStorage(): void {
    const _this = this
    let eventList: any = STORE.get(eventListKey)
    const pageHistory: any = STORE.get(pageHistoryKey)
    const bridgeList: any = STORE.get(bridgeListKey)
    if (bridgeList) {
      if (!eventList) eventList = []
      Object.keys(bridgeList).forEach((item) => {
        eventList.push(bridgeList[item])
      })
    }
    if (eventList || pageHistory) {
      report(_this._options, {
        eventList,
        pageHistory,
        cmd: CMD.REPORT
      })
    }
  }

  // 清空队列及localStorage内存货
  private clearAll(): void {
    const _this = this
    _this.clearEventList()
    _this.clearPageHistory()
  }

  // 清空eventList相关存储
  private clearEventList(): void {
    const _this = this
    _this._eventList = []
    STORE.remove(eventListKey)
  }

  // 清空pageHistory相关存储
  private clearPageHistory(): void {
    const _this = this
    _this._pageHistory = []
    STORE.remove(pageHistoryKey)
  }

  // 检查eventlist内是否有待上报数据，上报数据并清空队列
  private checkEventList(): void {
    const _this = this
    if (_this._eventList.length > 0) {
      report(_this._options, {
        eventList: _this._eventList,
        cmd: CMD.REPORT
      })
      _this.clearEventList()
    }
  }

  public updateEventList(recordObj: EventDataType) {
    const _this = this
    // 填充上报公共数据
    recordObj = {
      ...getEventCommonParams(this._fromUrl),
      ...recordObj
    }
    // 根据metricNames,组装metricConfigs
    if (recordObj?.metricData?.length) {
      const metricConfigs: MetricConfig[] = []
      recordObj.metricData.forEach(metricData => {
        const targetConfigArr = this._options.metricConfigs?.filter(config => config.metricName === metricData.metricName)
        if (targetConfigArr?.length === 1) {
          metricConfigs.push({ ...targetConfigArr[0], ...metricData })
        }
      })
      delete recordObj.metricData
      recordObj.data.metricConfigs = metricConfigs
    }
    _this._eventList.push(recordObj)
    STORE.set(eventListKey, _this._eventList)
    if (_this._eventList.length >= MAX_EVENTLIST_LENGTH) {
      _this.checkEventList()
    }
  }

  // 暴露错误上报
  /**
   *
   *
   * @param {string} error 错误信息
   * @param {Object} extra 错误附加信息
   * @memberof Tracer
   */
  public reportErrorInfo(error: string, extra?: Object) {
    this.updateEventList({
      type: REPORT_TYPE.Error,
      data: {
        error,
        extra: extra || {}
      }
    })
  }

  /**
   * 
   * @param message 
   * @param extra 
   * @param metricData 
   */
  public reportCustomInfo(message: string, extra?: Object, metricData?: ReportCustomMetricParams[]) {
    this.updateEventList({
      type: REPORT_TYPE.Custom,
      data: {
        message,
        extra: extra || {}
      },
      metricData
    })
  }

  public cacheBridge(uuid, data) {
    this._bridgeList[uuid] = {
      ...getEventCommonParams(this._fromUrl),
      ...data
    }
    STORE.set(bridgeListKey, JSON.stringify(this._bridgeList))
  }

  public deleteBridge(uuid) {
    delete this._bridgeList[uuid]
    STORE.set(bridgeListKey, JSON.stringify(this._bridgeList))
  }

  public getCacheBridge(uuid) {
    return this._bridgeList[uuid]
  }

  // bridge超时上报方法
  public reportBridgeOverTime(bridgeName: string) {
    this.updateEventList({
      type: REPORT_TYPE.Bridge,
      data: {
        type: BridgeTypeEnum.custom,
        name: bridgeName,
        overTime: true
      }
    })
  }

  // bridge自定义上报方法
  public reportBridgeCustom(bridgeName: string, data: Object = {}) {
    this.updateEventList({
      type: REPORT_TYPE.Bridge,
      data: {
        ...data,
        type: BridgeTypeEnum.custom,
        name: bridgeName
      }
    })
  }

  private updatePageHistory(recordObj: PageHistory) {
    const _this = this
    // 填充上报公共数据
    recordObj = {
      ...getEventCommonParams(this._fromUrl),
      ...recordObj
    }

    _this._pageHistory.push(recordObj)
    STORE.set(pageHistoryKey, _this._pageHistory)
  }

  public reportPageHistory(): void {
    const _this = this
    report(_this._options, {
      pageHistory: _this._pageHistory,
      cmd: CMD.REPORT
    })
    _this.clearPageHistory()
  }

  // 点击事件上报
  public reportClickEvent(clickId: string) {
    this.recordData({
      ...getEventCommonParams(this._fromUrl),
      type: REPORT_TYPE.Click,
      data: clickId
    })
  }

  private recordData(recordObj: any): void {
    // console.log('recordObj', recordObj)
    const _this = this
    switch (recordObj.type) {
      case REPORT_TYPE.Visit:
        _this.updatePageHistory(recordObj)
        break
      case REPORT_TYPE.View:
      case REPORT_TYPE.Click:
      case REPORT_TYPE.Api:
      case REPORT_TYPE.Perf:
      case REPORT_TYPE.Stay:
      case REPORT_TYPE.Bridge:
        _this.updateEventList(recordObj)
        break
      // case REPORT_TYPE.AutoExpo:
      //   _this.updateEventList({ reportType: type, reportData: data })
      //   break
      default:
        throw new Error('TYPE NOT SUPPORTED!')
    }
  }

  private setFromUrl(url: string) {
    this._fromUrl = url
  }
}

export default new Tracer()
