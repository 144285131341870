import { useEffect, useRef } from 'react';
export var useUnmountedRef = function () {
    var unmountedRef = useRef(false);
    useEffect(function () {
        unmountedRef.current = false;
        return function () {
            unmountedRef.current = true;
        };
    }, []);
    return unmountedRef;
};
