(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("classnames"), require("@mdap/sdk-plugin-api"), require("@mdap/sdk-plugin-exception"), require("dayjs"), require("@credit/utils"), require("@shopee_common/currency"), require("react-dom"), require("whatwg-fetch"), require("intersection-observer"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "classnames", "@mdap/sdk-plugin-api", "@mdap/sdk-plugin-exception", "dayjs", "@credit/utils", "@shopee_common/currency", "react-dom", "whatwg-fetch", "intersection-observer"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("classnames"), require("@mdap/sdk-plugin-api"), require("@mdap/sdk-plugin-exception"), require("dayjs"), require("@credit/utils"), require("@shopee_common/currency"), require("react-dom"), require("whatwg-fetch"), require("intersection-observer")) : factory(root["react"], root["classnames"], root["@mdap/sdk-plugin-api"], root["@mdap/sdk-plugin-exception"], root["dayjs"], root["@credit/utils"], root["@shopee_common/currency"], root["react-dom"], root["whatwg-fetch"], root["intersection-observer"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, function(__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__8310__, __WEBPACK_EXTERNAL_MODULE__3830__, __WEBPACK_EXTERNAL_MODULE__5644__, __WEBPACK_EXTERNAL_MODULE__5760__, __WEBPACK_EXTERNAL_MODULE__8935__, __WEBPACK_EXTERNAL_MODULE__4994__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__3757__, __WEBPACK_EXTERNAL_MODULE__6759__) {
return 