// langes en | pt-BR | id | zh-Hans | ms | th | zh-Hant | vi | es-MX | es-CL | es-ES | pl | fr | hi
var _a, _b;
import { ApplicableInstalmentTypes, VOUCHER_TYPE } from "./common";
export var PercentageVoucherTypes = [
    VOUCHER_TYPE.ADMIN_FEE_PERCENTAGE,
    VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE
];
// voucher type 对应的 head 文字
export var VOUCHER_TYPE_TITLES = (_a = {},
    // admin fee
    _a[VOUCHER_TYPE.ADMIN_FEE_PERCENTAGE] = ['percentageAdminFee'],
    _a[VOUCHER_TYPE.ADMIN_FEE_ABSOLUTE] = ['absoluteDiscountAmountAdminFee'],
    // instalment fee
    _a[VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE] = [
        'percentageInstalmentFee',
        'percentageFirstInstalmentFee' // For first instalment
    ],
    _a[VOUCHER_TYPE.INSTALMENT_FEE_ABSOLUTE] = [
        'absoluteDiscountAmountInstalmentFee',
        'absoluteDiscountAmountFirstInstalmentFee' // For first instalment
    ],
    _a[VOUCHER_TYPE.REPAYMENT_ABSOLUTE] = ['SPLRepayment'],
    _a);
export var ApplicableInstalmentDesc = (_b = {},
    _b[ApplicableInstalmentTypes.firstInstalment] = 'firstInstalment',
    _b);
// monthly discount cap amount
export var MonthlyDiscountCapOfVoucherTypes = [
    VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE
];
