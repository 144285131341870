var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { createSwiper } from "../../utils/swiper";
import './styles.less';
import { bannerTemplateRegister, DSLVersion, TEMPLATES, WIDGETS } from '../register';
import { MarketingBanner } from './template/marketing';
import { VoucherBanner } from './template/voucher';
import { TipBanner } from './template/tip';
import { CustomerReviewBanner } from './template/customerReview';
import { isFunction, pickDSLData, stringToNumber } from "../../utils/tools";
import classNames from 'classnames';
import { useUnmountedRef } from "../../hooks";
import { activityEventTrigger } from "../../apis";
import { getSwiperDefaultConfig } from './config';
import Initial from '../Initial';
import { createLogger } from "../../monitor/logger";
import BITracker from "../../utils/BITracker";
bannerTemplateRegister.set(TEMPLATES.MARKETING_BANNER, MarketingBanner);
bannerTemplateRegister.set(TEMPLATES.VOUCHER_BANNER, VoucherBanner);
bannerTemplateRegister.set(TEMPLATES.CUSTOMER_REVIEW_BANNER, CustomerReviewBanner);
bannerTemplateRegister.set(TEMPLATES.TIP_BANNER, TipBanner);
var clsPrefix = 'credit-promotion-sdk-banner-swipe';
var logger = createLogger('PromoWebSDK:Banner');
var tracker = new BITracker();
var CarouselBannerEvent = function (props, swiperRef) {
    var bannerRef = useRef(null);
    var _a = useState(null), swiper = _a[0], setSwiper = _a[1];
    var unmountedRef = useUnmountedRef();
    var _b = useState(props.startSlide || 0), bannerPos = _b[0], setBannerPos = _b[1];
    var _c = useState([]), bannerList = _c[0], setBannerList = _c[1];
    var _d = useState([]), clonedList = _d[0], setClonedList = _d[1];
    var _e = useState(), spaceConfig = _e[0], setSpaceConfig = _e[1];
    var fetcher = props.fetcher, eventCode = props.eventCode, bizType = props.bizType, dsl = props.dsl, region = props.region, _f = props.layout, layout = _f === void 0 ? 'mobile' : _f, _g = props.showIndicator, showIndicator = _g === void 0 ? true : _g;
    var fetchData = useCallback(function () {
        if (dsl) {
            return Promise.resolve(dsl);
        }
        var realFetcher = !fetcher
            ? function () { return activityEventTrigger({ eventCode: eventCode, region: region, bizType: bizType }); }
            : (isFunction(fetcher) ? fetcher : null);
        if (!realFetcher) {
            return Promise.resolve(null);
        }
        var p = realFetcher();
        return Promise.resolve(p)
            .then(function (res) {
            var _a, _b;
            if (res.code !== 0) {
                throw res;
            }
            var result = res.result;
            var bannerInfo = (result || {}).bannerInfo;
            var render = (_a = bannerInfo === null || bannerInfo === void 0 ? void 0 : bannerInfo.renderList) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                var parsed;
                try {
                    parsed = JSON.parse(item);
                }
                catch (error) {
                    logger.error(error);
                }
                return parsed;
            }).filter(function (item) { return !!item; });
            var dsl = {
                version: DSLVersion,
                config: stringToNumber(bannerInfo === null || bannerInfo === void 0 ? void 0 : bannerInfo.spaceConfig),
                render: render || []
            };
            (_b = props === null || props === void 0 ? void 0 : props.onFetchSuccess) === null || _b === void 0 ? void 0 : _b.call(props, dsl);
            return dsl;
        }).catch(function (e) {
            var _a;
            (_a = props === null || props === void 0 ? void 0 : props.onFetchError) === null || _a === void 0 ? void 0 : _a.call(props, e);
            console.error(e);
            throw e;
        });
    }, [dsl, eventCode, fetcher]);
    useEffect(function () {
        var container = bannerRef.current;
        fetchData().then(function (res) {
            if (!res) {
                return;
            }
            if (unmountedRef.current) {
                return;
            }
            // 过滤掉无效数据
            var list = res.render;
            var filtered = list.filter(function (item) {
                var basic = item.basic;
                if (!basic) {
                    return false;
                }
                var template = basic.template;
                return !!bannerTemplateRegister.get(template);
            });
            // 当有两张banner，并且需要持续轮播的时候，需特殊处理
            if (filtered.length == 2 && props.continuous !== false) {
                var cloned = filtered.map(function (item, index) { return (__assign(__assign({}, item), { cloneIndex: index })); });
                setClonedList(__spreadArray(__spreadArray([], filtered, true), cloned, true));
            }
            else {
                setClonedList(__spreadArray([], filtered, true));
            }
            setBannerList(filtered);
            setSpaceConfig(__assign({}, res.config));
            var swiper = createSwiper(container, __assign(__assign(__assign(__assign({}, getSwiperDefaultConfig()), res.config), props), { transitionStart: function (index, slide) {
                    var _a;
                    setBannerPos(index);
                    var banner = filtered[index];
                    (_a = props.transitionStart) === null || _a === void 0 ? void 0 : _a.call(props, index, pickDSLData(banner, props.locale), slide);
                }, transitionEnd: function (index, slide) {
                    var _a;
                    var banner = filtered[index];
                    (_a = props.transitionEnd) === null || _a === void 0 ? void 0 : _a.call(props, index, pickDSLData(banner, props.locale), slide);
                }, onSetupEnd: function (index, slide) {
                    var _a;
                    setBannerPos(index);
                    var banner = filtered[index];
                    (_a = props.onSetupEnd) === null || _a === void 0 ? void 0 : _a.call(props, index, pickDSLData(banner, props.locale), slide);
                } }));
            if (filtered.length <= 1) {
                swiper === null || swiper === void 0 ? void 0 : swiper.removeListener(['touchstart', 'transitionEnd']);
            }
            setSwiper(swiper);
        });
        return function () {
            swiper === null || swiper === void 0 ? void 0 : swiper.kill();
        };
    }, [dsl]);
    useEffect(function () {
        if (props.tracker && !window.__TMSTracker__ && props.layout == 'pc') {
            window.__TMSTracker__ = props.tracker;
        }
    }, [props.tracker]);
    useImperativeHandle(swiperRef, function () { return ({
        setup: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.setup(); },
        slide: function (to, speed) { return swiper === null || swiper === void 0 ? void 0 : swiper.slide(to, speed); },
        prev: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.prev(); },
        next: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.next(); },
        stop: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.stop(); },
        removeListener: function (events) { return swiper === null || swiper === void 0 ? void 0 : swiper.removeListener(events); },
        getPos: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.getPos(); },
        getNumSlides: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.getNumSlides(); },
        kill: function () { return swiper === null || swiper === void 0 ? void 0 : swiper.kill(); }
    }); });
    return (React.createElement("div", { ref: bannerRef, style: bannerList.length ? props.style : { display: 'none' }, className: classNames(props.className, clsPrefix, "".concat(layout, "-layout")) },
        React.createElement("div", { className: "".concat(clsPrefix, "-wrapper") }, clonedList.map(function (banner, index) {
            var basic = banner.basic;
            if (!basic) {
                return null;
            }
            var template = basic.template;
            var TPLRenderer = bannerTemplateRegister.get(template);
            if (!TPLRenderer) {
                return null;
            }
            var widgetProps = {
                locale: props.locale,
                region: props.region,
                layout: props.layout,
                onClick: function (dsl) {
                    var _a, _b, _c;
                    var bannerData = pickDSLData(dsl, props.locale);
                    tracker.trackClickEvent({
                        page_type: props.pageType,
                        page_section: BITracker.getPageSection(bannerData.template),
                        data: __assign({ from_source: props.fromSource, banner_id: bannerData.bannerId, banner_name: bannerData.name, banner_url: (_a = bannerData.btnBehavior) === null || _a === void 0 ? void 0 : _a.content, image_hash: bannerData.backgroundImage, location: (_b = banner.cloneIndex) !== null && _b !== void 0 ? _b : index }, props.extraData)
                    });
                    (_c = props.onBannerClick) === null || _c === void 0 ? void 0 : _c.call(props, bannerData, dsl);
                },
                // onUseVoucherClick: props.onUseVoucherClick,
                dsl: banner,
                onEnter: function (data) {
                    var _a, _b, _c;
                    tracker.trackImpressionEvent({
                        page_type: props.pageType,
                        page_section: BITracker.getPageSection(data.template),
                        data: __assign({ from_source: props.fromSource, banner_id: data.bannerId, banner_name: data.name, banner_url: (_a = data.btnBehavior) === null || _a === void 0 ? void 0 : _a.content, image_hash: data.backgroundImage, location: (_b = banner.cloneIndex) !== null && _b !== void 0 ? _b : index }, props.extraData)
                    });
                    (_c = props.onEnter) === null || _c === void 0 ? void 0 : _c.call(props, data);
                },
                onLeave: props.onLeave,
                threshold: props.threshold,
                style: props.bannerStyle,
                className: props.bannerClassName,
                config: spaceConfig,
                scale: props.scale
            };
            var cloneIndex = banner.cloneIndex;
            return (React.createElement("div", { className: classNames("".concat(clsPrefix, "-item")), key: "".concat(basic.bannerId, "_").concat(index), "data-cloned": cloneIndex !== undefined },
                React.createElement(Initial, { region: props.region, locale: props.locale },
                    React.createElement(TPLRenderer, __assign({}, widgetProps)))));
        })),
        showIndicator && bannerList.length > 1 && (React.createElement("div", { className: classNames("".concat(clsPrefix, "-indicator-wrapper")) },
            React.createElement("div", { className: "indicator" }, bannerList.map(function (banner, index) {
                var _a;
                return (React.createElement("span", { "data-index": index, className: classNames('item', index === bannerPos ? 'active' : ''), key: "".concat((_a = banner.basic) === null || _a === void 0 ? void 0 : _a.bannerId, "-").concat(index) }));
            }))))));
};
export default Object.assign(forwardRef(CarouselBannerEvent), {
    TEMPLATES: TEMPLATES,
    WIDGETS: WIDGETS
});
