import Initial from "../../../Initial";
import { resolvePopupVoucher } from "../../../../utils/resolveVoucherData";
import classNames from 'classnames';
import React from 'react';
import './styles.less';
var clsPrefix = 'credit-promotion-slot-widget-popup-voucher';
export var Voucher = function (props) {
    var locale = props.locale, region = props.region, layout = props.layout, className = props.className;
    var resolvedData = resolvePopupVoucher(props);
    return (React.createElement(Initial, { locale: locale, region: region },
        React.createElement("div", { className: classNames(className, clsPrefix, "".concat(layout, "-layout")) },
            React.createElement("div", { className: "".concat(clsPrefix, "-title") }, resolvedData.headerText),
            React.createElement("div", { className: "".concat(clsPrefix, "-discount") }, resolvedData.discountText),
            React.createElement("div", { className: "".concat(clsPrefix, "-valid-to") }, resolvedData.validTime))));
};
