var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import classNames from 'classnames';
import React, { useState, useRef, useLayoutEffect } from 'react';
import { withNativeProps } from "../../utils/dom/native-props";
import { renderToContainer } from "../../utils/dom/render-to-container";
import { withStopPropagation } from "../../utils/dom/stop-propagation";
import { useSpring, animated } from '@react-spring/web';
import { defaultDrawerBaseProps } from './base-props';
import { useInnerVisible, useLockScroll, useUnmountedRef } from "../../hooks";
import { Mask } from '../Mask';
import { ReactComponent as CloseSvg } from '../Popup/images/close.svg';
import './styles.less';
var classPrefix = "credit-promotion-sdk-drawer";
var defaultProps = __assign(__assign({}, defaultDrawerBaseProps), { position: 'bottom' });
var Drawer = function (p) {
    var _a;
    var props = __assign(__assign({}, defaultProps), p);
    var bodyCls = classNames("".concat(classPrefix, "-body"), props.bodyClassName, "".concat(classPrefix, "-body-position-").concat(props.position));
    var _b = useState(props.visible), active = _b[0], setActive = _b[1];
    useLayoutEffect(function () {
        if (props.visible) {
            setActive(true);
        }
    }, [props.visible]);
    var ref = useRef(null);
    useLockScroll(ref, !!props.disableBodyScroll && !!active, props.getContainer);
    var unmountedRef = useUnmountedRef();
    var percent = useSpring({
        percent: props.visible ? 0 : 100,
        config: {
            precision: 0.1,
            mass: 0.4,
            tension: 300,
            friction: 30,
        },
        onRest: function () {
            if (unmountedRef.current) {
                return;
            }
            setActive(props.visible);
        },
    }).percent;
    var maskVisible = useInnerVisible(!!active && !!props.visible);
    var node = withStopPropagation(props.stopPropagation, withNativeProps(props, React.createElement("div", { className: classPrefix, onClick: props.onClick, style: { display: active ? undefined : 'none' } },
        props.mask && (React.createElement(Mask, { visible: maskVisible, onMaskClick: function (e) {
                var _a, _b;
                (_a = props.onMaskClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
                if (props.closeOnMaskClick) {
                    (_b = props.onClose) === null || _b === void 0 ? void 0 : _b.call(props);
                }
            }, style: props.maskStyle, className: classNames("".concat(classPrefix, "-mask"), props.maskClassName), disableBodyScroll: false, stopPropagation: props.stopPropagation, getContainer: props.getContainer })),
        React.createElement(animated.div, { className: bodyCls, style: __assign(__assign({}, props.bodyStyle), { transform: percent.to(function (v) {
                    if (props.position === 'bottom') {
                        return "translate(0, ".concat(v, "%)");
                    }
                    if (props.position === 'top') {
                        return "translate(0, -".concat(v, "%)");
                    }
                    if (props.position === 'left') {
                        return "translate(-".concat(v, "%, 0)");
                    }
                    if (props.position === 'right') {
                        return "translate(".concat(v, "%, 0)");
                    }
                    return 'none';
                }) }), ref: ref },
            props.showCloseButton && (React.createElement(CloseSvg, { className: classNames("".concat(classPrefix, "-close")), onClick: function () {
                    var _a;
                    (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
                } })),
            props.children))));
    return (React.createElement(React.Fragment, null, renderToContainer(node, (_a = props.getContainer) === null || _a === void 0 ? void 0 : _a.call(props))));
};
export default Drawer;
