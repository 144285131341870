var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BIZ_TYPE } from "../types";
import { findMultiLangStringValue } from "./resolveDisplayTemplate";
export function isFunction(fn) {
    return typeof fn === 'function';
}
export function isUndefined(v) {
    return typeof v === 'undefined';
}
export function pickDSLData(banner, locale) {
    if (!banner) {
        return {};
    }
    var basic = banner.basic, render = banner.render;
    var bannerData = __assign({}, basic);
    var renderData = Array.isArray(render) ? render[0] : render;
    var _a = renderData !== null && renderData !== void 0 ? renderData : {}, behavior = _a.behavior, background = _a.background, button = _a.button, _b = _a.slots, slots = _b === void 0 ? [] : _b;
    var extData = slots === null || slots === void 0 ? void 0 : slots.reduce(function (acc, slot) {
        acc[slot.id] = slot.props;
        return acc;
    }, {});
    return Object.assign(bannerData, {
        behavior: behavior,
        backgroundImage: findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.image, locale),
        btnText: findMultiLangStringValue(button === null || button === void 0 ? void 0 : button.text, locale),
        btnBehavior: button === null || button === void 0 ? void 0 : button.behavior,
        extData: extData
    });
}
export var stringToNumber = function (data) {
    if (!data) {
        return {};
    }
    var keys = Object.keys(data);
    var d = {};
    keys.forEach(function (k) {
        var val = data[k];
        if (/^\d+$/.test(val)) {
            d[k] = Number(val);
        }
        else if (val == 'true' || val == 'false') {
            d[k] = Boolean(val);
        }
        else {
            d[k] = val;
        }
    });
    return d;
};
export var px2em = function (size, base) {
    if (size === void 0) { size = 0; }
    if (base === void 0) { base = 30; }
    if (base == 0) {
        base = 30;
    }
    return (size / base).toFixed(5) + 'em';
};
export var getBizTypeText = function (bizType) {
    switch (bizType) {
        case BIZ_TYPE.Buyer:
            return 'cashloan';
        case BIZ_TYPE.Seller:
            return 'sellerloan';
        case BIZ_TYPE.SPL:
            return 'spl';
        default:
            return 'others';
    }
};
