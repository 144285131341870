export var supportsPassive = false;
export var canUseDom = !!(typeof window !== 'undefined' &&
    typeof document !== 'undefined' &&
    window.document &&
    window.document.createElement);
export var isMouseEvent = function (e) {
    return /^mouse/.test(e.type);
};
export var browser = {
    addEventListener: !!window.addEventListener,
    // @ts-ignore
    touch: ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch,
    transitions: (function (temp) {
        var props = ['transitionProperty', 'WebkitTransition', 'MozTransition', 'OTransition', 'msTransition'];
        for (var i in props) {
            var prop = props[i];
            // @ts-ignore
            if (temp.style[prop] !== undefined) {
                return true;
            }
        }
        return false;
    })(document.createElement('swipe'))
};
if (canUseDom) {
    try {
        var opts = {};
        Object.defineProperty(opts, 'passive', {
            get: function () {
                supportsPassive = true;
            },
        });
        window.addEventListener('test-passive', null, opts);
    }
    catch (e) { }
}
