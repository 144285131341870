import * as ID from './id';
import * as TH from './th';
import * as PH from './ph';
import * as MY from './my';
export * as CommonConfig from './common';
var AllConfig = {
    id: ID,
    th: TH,
    ph: PH,
    sg: {},
    vn: {},
    my: MY,
    br: {}
};
export function getConfig(region) {
    return AllConfig[region];
}
