import { useEffect, useState } from 'react';
export function useImageLoaded(src) {
    var _a = useState(false), loaded = _a[0], setLoaded = _a[1];
    useEffect(function () {
        var image = new Image();
        image.onload = function () {
            setLoaded(true);
        };
        image.src = src;
    }, [src]);
    return loaded;
}
