var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useLayoutEffect, useRef, useState } from 'react';
import { renderToContainer } from '../../utils/dom/render-to-container';
import { Mask } from '../Mask';
import { withStopPropagation } from '../../utils/dom/stop-propagation';
import { animated, useSpring } from '@react-spring/web';
import classNames from 'classnames';
import { useLockScroll, useUnmountedRef, useInnerVisible } from '../../hooks';
import { ReactComponent as CloseSvg } from './images/close.svg';
import { defaultPopupBaseProps, } from '../../types/popup-props';
import './center-styles.less';
var classPrefix = 'credit-promotion-sdk-center-popup';
var defaultProps = __assign({}, defaultPopupBaseProps);
export var CenterPopup = function (p) {
    var _a;
    var props = __assign(__assign({}, defaultProps), p);
    var unmountedRef = useUnmountedRef();
    var style = useSpring({
        scale: props.visible ? 1 : 0.8,
        opacity: props.visible ? 1 : 0,
        config: {
            mass: 1.2,
            tension: 200,
            friction: 25,
            clamp: true,
        },
        onRest: function () {
            if (unmountedRef.current) {
                return;
            }
            setActive(props.visible);
        },
    });
    var _b = useState(props.visible), active = _b[0], setActive = _b[1];
    useLayoutEffect(function () {
        if (props.visible) {
            setActive(true);
        }
    }, [props.visible]);
    var ref = useRef(null);
    useLockScroll(ref, props.disableBodyScroll && active, props.getContainer);
    var maskVisible = useInnerVisible(active && props.visible);
    var body = (React.createElement("div", { className: classNames("".concat(classPrefix, "-body"), props.bodyClassName), style: props.bodyStyle }, props.children));
    var node = withStopPropagation(props.stopPropagation, React.createElement("div", { className: classNames(classPrefix, props.centerClassName, "".concat(props.layout, "-layout")), style: __assign({ display: active ? undefined : 'none', pointerEvents: active ? undefined : 'none' }, props === null || props === void 0 ? void 0 : props.centerStyle) },
        props.mask && (React.createElement(Mask, { visible: maskVisible, onMaskClick: function (e) {
                var _a, _b;
                (_a = props.onMaskClick) === null || _a === void 0 ? void 0 : _a.call(props, e);
                if (props.closeOnMaskClick) {
                    (_b = props.onClose) === null || _b === void 0 ? void 0 : _b.call(props);
                }
            }, style: props.maskStyle, className: classNames("".concat(classPrefix, "-mask"), props.maskClassName), disableBodyScroll: false, stopPropagation: props.stopPropagation, getContainer: props.getContainer })),
        React.createElement("div", { className: classNames("".concat(classPrefix, "-wrap"), props.centerWrapClassName), style: props.centerWrapStyle },
            React.createElement(animated.div, { style: style, ref: ref },
                props.showCloseButton && (React.createElement(CloseSvg, { className: classNames("".concat(classPrefix, "-close")), onClick: function () {
                        var _a;
                        (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
                    } })),
                body))));
    return (React.createElement(React.Fragment, null, renderToContainer(node, (_a = props.getContainer) === null || _a === void 0 ? void 0 : _a.call(props))));
};
