import { useTouch } from './useTouch';
import { useEffect } from 'react';
import { getScrollParent } from '../utils/dom/get-scroll-parent';
import { supportsPassive } from '../utils/dom/support';
var totalLockCount = 0;
var BODY_LOCK_CLASS = 'credit-promotion-sdk-overflow-hidden';
// https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
export function useLockScroll(rootRef, shouldLock, getLockContainer) {
    var touch = useTouch();
    var onTouchMove = function (event) {
        touch.move(event);
        var direction = touch.deltaY.current > 0 ? '10' : '01';
        var el = getScrollParent(event.target, rootRef.current);
        if (!el)
            return;
        var scrollHeight = el.scrollHeight, offsetHeight = el.offsetHeight, scrollTop = el.scrollTop;
        var status = '11';
        if (scrollTop === 0) {
            status = offsetHeight >= scrollHeight ? '00' : '01';
        }
        else if (scrollTop + offsetHeight >= scrollHeight) {
            status = '10';
        }
        if (status !== '11' &&
            touch.isVertical() &&
            !(parseInt(status, 2) & parseInt(direction, 2))) {
            if (event.cancelable) {
                event.preventDefault();
            }
        }
    };
    var lock = function () {
        document.addEventListener('touchstart', touch.start);
        document.addEventListener('touchmove', onTouchMove, supportsPassive ? { passive: false } : false);
        if (!totalLockCount) {
            var body = (getLockContainer === null || getLockContainer === void 0 ? void 0 : getLockContainer()) || document.body;
            body.classList.add(BODY_LOCK_CLASS);
        }
        totalLockCount++;
    };
    var unlock = function () {
        if (totalLockCount) {
            document.removeEventListener('touchstart', touch.start);
            document.removeEventListener('touchmove', onTouchMove);
            totalLockCount--;
            if (!totalLockCount) {
                var body = (getLockContainer === null || getLockContainer === void 0 ? void 0 : getLockContainer()) || document.body;
                body.classList.remove(BODY_LOCK_CLASS);
            }
        }
    };
    useEffect(function () {
        if (shouldLock) {
            lock();
            return function () {
                unlock();
            };
        }
    }, [shouldLock]);
}
