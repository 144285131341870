var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import classNames from 'classnames';
export function withNativeProps(props, element) {
    var p = __assign({}, element.props);
    if (props.className) {
        p.className = classNames(element.props.className, props.className);
    }
    if (props.style) {
        p.style = __assign(__assign({}, p.style), props.style);
    }
    if (props.tabIndex !== undefined) {
        p.tabIndex = props.tabIndex;
    }
    for (var key in props) {
        if (!props.hasOwnProperty(key))
            continue;
        if (key.startsWith('data-') || key.startsWith('aria-')) {
            p[key] = props[key];
        }
    }
    return React.cloneElement(element, p);
}
