var _a, _b;
import { ApplicableInstalmentTypes, VOUCHER_TYPE } from "./common";
export var PercentageVoucherTypes = [
    VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE
];
// voucher type 对应的 head 文字
export var VOUCHER_TYPE_TITLES = (_a = {},
    // admin fee
    _a[VOUCHER_TYPE.ADMIN_FEE_PERCENTAGE] = ['percentageAdminFee'],
    _a[VOUCHER_TYPE.ADMIN_FEE_ABSOLUTE] = ['absoluteDiscountAmountAdminFee'],
    // instalment fee, (th 叫 interest fee)
    _a[VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE] = [
        'percentageInterestFee',
    ],
    _a[VOUCHER_TYPE.INSTALMENT_FEE_ABSOLUTE] = [
        'interestFeeAbsolute',
    ],
    _a);
export var ApplicableInstalmentDesc = (_b = {},
    _b[ApplicableInstalmentTypes.firstInstalment] = 'firstTenure',
    _b);
// monthly discount cap amount
export var MonthlyDiscountCapOfVoucherTypes = [
    VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE
];
