var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { renderImperatively } from "../../utils/dom/render-imperatively";
import { PopupEvent } from './template';
// import { fetchPopup } from './__Mock__';
import { isFunction, pickDSLData, stringToNumber } from "../../utils/tools";
import { DSLVersion, TEMPLATES, WIDGETS } from '../register';
import { activityEventTrigger } from "../../apis";
import { createLogger } from "../../monitor/logger";
import BITracker from "../../utils/BITracker";
var logger = createLogger('PromoWebSDK:Popup');
var tracker = new BITracker();
var defaultProps = {
    layout: 'mobile',
    mask: true,
    closeOnMaskClick: false,
    disableClose: false,
    onMaskClick: function (e) { console.log(e); },
    getContainer: function () { return document.body; }
};
var Popup = /** @class */ (function () {
    function Popup(props) {
        var _this = this;
        this.create = function (visible) {
            if (visible === void 0) { visible = true; }
            var _a = _this.props, dsl = _a.dsl, getContainer = _a.getContainer;
            if (!dsl || !dsl.render) {
                return;
            }
            _this.handler = renderImperatively(React.createElement(PopupEvent, __assign({}, _this.props)), { visible: visible, body: getContainer === null || getContainer === void 0 ? void 0 : getContainer() });
            _this.handlerSet.add(_this.handler);
        };
        this.show = function () {
            var _a;
            if (!_this.handler) {
                _this.create(false);
            }
            (_a = _this.handler) === null || _a === void 0 ? void 0 : _a.show();
        };
        this.hide = function () {
            var _a;
            (_a = _this.handler) === null || _a === void 0 ? void 0 : _a.hide();
        };
        this.destroy = function () {
            if (_this.handler) {
                _this.handler.destroy();
                _this.handlerSet.delete(_this.handler);
                _this.handler = null;
            }
        };
        this.destroyAll = function () {
            _this.handlerSet.forEach(function (handler) { return handler.destroy(); });
            _this.handler = null;
        };
        this.replace = function (dsl, region, locale) {
            _this.props.dsl = dsl;
            _this.props.region = region;
            _this.props.locale = locale;
            _this.handlerSet.forEach(function (handler) { return handler.replace(React.createElement(PopupEvent, __assign({}, _this.props))); });
        };
        this.handler = null;
        this.handlerSet = new Set(); // 兼容性
        this.props = __assign(__assign(__assign({}, defaultProps), props), { beforeShow: function () {
                var _a;
                var dsl = props.dsl, locale = props.locale;
                (_a = props.beforeShow) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl === null || dsl === void 0 ? void 0 : dsl.render[0], locale));
            }, afterShow: function () {
                var _a;
                var dsl = props.dsl, locale = props.locale;
                (_a = props.afterShow) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl === null || dsl === void 0 ? void 0 : dsl.render[0], locale));
            }, beforeClose: function () {
                var _a;
                var dsl = props.dsl, locale = props.locale;
                (_a = props.beforeClose) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl === null || dsl === void 0 ? void 0 : dsl.render[0], locale));
            }, afterClose: function () {
                var _a;
                var dsl = props.dsl, locale = props.locale;
                (_a = props.afterClose) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl === null || dsl === void 0 ? void 0 : dsl.render[0], locale));
            }, onBehaviorClick: function (behavior, data, dsl) {
                var _a;
                if (!props.disableClose) {
                    _this.hide();
                }
                (_a = props.onBehaviorClick) === null || _a === void 0 ? void 0 : _a.call(props, behavior, data, dsl);
            }, onClose: function () {
                var _a, _b;
                var dsl = props.dsl, locale = props.locale;
                if (props.disableClose) {
                    return;
                }
                _this.hide();
                var popupData = pickDSLData(dsl === null || dsl === void 0 ? void 0 : dsl.render[0], locale);
                var tpl = popupData.template || '';
                var pageSection = 'popup_promotion';
                if ((tpl === null || tpl === void 0 ? void 0 : tpl.indexOf('voucher-popup')) >= 0) {
                    pageSection = 'popup_voucher';
                }
                tracker.trackClickEvent({
                    page_type: props.pageType,
                    page_section: pageSection,
                    target_type: 'close',
                    data: {
                        from_source: props.fromSource,
                        banner_id: popupData.bannerId,
                        banner_name: popupData.name,
                        banner_url: (_a = popupData.btnBehavior) === null || _a === void 0 ? void 0 : _a.content,
                        image_hash: popupData.backgroundImage
                    }
                });
                (_b = props.onClosePopup) === null || _b === void 0 ? void 0 : _b.call(props, popupData);
            } });
    }
    return Popup;
}());
export { Popup };
function createPopup(props) {
    var eventCode = props.eventCode, fetcher = props.fetcher, region = props.region, bizType = props.bizType, dsl = props.dsl;
    if (!region) {
        return Promise.reject('Missing region');
    }
    if (dsl) {
        return Promise.resolve(new Popup(props));
    }
    var realFetcher = !fetcher
        ? function () { return activityEventTrigger({ eventCode: eventCode, region: region, bizType: bizType }); }
        : (isFunction(fetcher) ? fetcher : null);
    if (isFunction(realFetcher)) {
        var resultPromise = realFetcher();
        return Promise.resolve(resultPromise)
            .then(function (res) {
            var _a;
            if (res.code !== 0) {
                throw res;
            }
            var result = res.result;
            var bannerInfo = result.bannerInfo;
            var render = (_a = bannerInfo === null || bannerInfo === void 0 ? void 0 : bannerInfo.renderList) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                var parsed;
                try {
                    parsed = JSON.parse(item);
                }
                catch (e) {
                }
                return parsed;
            });
            return new Popup(__assign(__assign({}, props), { dsl: {
                    version: DSLVersion,
                    config: stringToNumber(bannerInfo === null || bannerInfo === void 0 ? void 0 : bannerInfo.spaceConfig),
                    render: render === null || render === void 0 ? void 0 : render.filter(function (item) { return !!item; })
                } }));
        })
            .catch(function (e) {
            logger.error(e);
            throw e;
        });
    }
    return Promise.resolve(new Popup(props));
}
createPopup.TEMPLATES = TEMPLATES;
createPopup.WIDGETS = WIDGETS;
export default createPopup;
