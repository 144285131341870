var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { TEMPLATES } from "../components/register";
import { createLogger, isMobile } from "../monitor/logger";
var logger = createLogger('PromoWebSDK:BITracker');
var BITracker = /** @class */ (function () {
    function BITracker() {
        this.checkEnabled = function () {
            return typeof (window === null || window === void 0 ? void 0 : window.bridgeCallHandler) === 'function' || typeof window.__TMSTracker__ === 'object';
        };
    }
    BITracker.getPageSection = function (template) {
        if (template === TEMPLATES.MARKETING_POPUP || template === TEMPLATES.MARKETING_POPUP_NO_SYS_BTN) {
            return 'popup_promotion';
        }
        if (template === TEMPLATES.VOUCHER_POPUP || template === TEMPLATES.VOUCHER_POPUP_V2) {
            return 'popup_voucher';
        }
        if (template === TEMPLATES.CUSTOMER_REVIEW_BANNER) {
            return 'customer_review';
        }
        if (template === TEMPLATES.VOUCHER_BANNER
            || template === TEMPLATES.MARKETING_BANNER) {
            return 'banner';
        }
        if (template === TEMPLATES.TIP_BANNER) {
            return 'tips_banner';
        }
        if (template === TEMPLATES.ITEM_CARD_SLIDE) {
            return 'item_card';
        }
        if (template === TEMPLATES.ICON_CARD_SLIDE) {
            return 'marketing_icon';
        }
        return '';
    };
    BITracker.prototype.trackEvent = function (operation, trackData) {
        var _a;
        var eventData = [
            {
                type: 'v3',
                timestamp: new Date().getTime(),
                source: window.__TMSTracker__ ? 'pc' : 'h5',
                info: __assign(__assign({ data: {} }, trackData), { operation: operation })
            }
        ];
        // @ts-ignore
        if (BUILD_ENV !== 'live') {
            console.info('BI Raw data: ', eventData);
        }
        if (!this.checkEnabled()) {
            if (isMobile) {
                logger.warn('JS bridge register failed');
            }
            else {
                logger.warn('Missing TMS tracker');
            }
            return;
        }
        try {
            logger.record(operation, {
                page_type: trackData.page_type
            });
            if (window.__TMSTracker__) {
                window.__TMSTracker__.trigger(eventData[0]);
            }
            else {
                (_a = window.bridgeCallHandler) === null || _a === void 0 ? void 0 : _a.call(window, 'trackBIEvent', {
                    events: JSON.stringify(eventData)
                });
            }
        }
        catch (error) {
        }
    };
    BITracker.prototype.trackImpressionEvent = function (sourceData) {
        var data = sourceData.data, otherData = __rest(sourceData, ["data"]);
        var impressionData = __assign(__assign({}, otherData), { data: {
                viewed_objects: Array.isArray(data)
                    ? data
                    : Object.prototype.toString.call(data) === '[object Object]'
                        ? [data]
                        : []
            } });
        return this.trackEvent('impression', impressionData);
    };
    BITracker.prototype.trackClickEvent = function (sourceData) {
        return this.trackEvent('click', sourceData);
    };
    // trackViewEvent(data: TrackSource) {
    //   return this.trackEvent('view', data)
    // }
    BITracker.prototype.getTrackBizType = function (bizType) {
        return 'promotion-web-sdk';
    };
    return BITracker;
}());
export default BITracker;
