import { getVoucherHeaderText, resolveVoucherData } from './resolveVoucherData';
import { CommonConfig } from '../config';
import { LANGES_CATEGORY } from '../locales';
// 生成displayTemplate 数据
export function getVoucherCardDisplayTemplate(_a) {
    var _b;
    var region = _a.region, dataSource = _a.dataSource;
    var langs = LANGES_CATEGORY[region];
    var displayTemplate = {
        voucher_icon: '',
        icon_text: {
            values: [],
        },
        header: {
            values: [],
        },
        sub_header: {
            values: [],
        },
        information_labels: [],
    };
    if (!langs || !langs.length)
        return displayTemplate;
    var voucherDatas = langs.map(function (locale) {
        return resolveVoucherData({
            locale: locale,
            dataSource: dataSource,
            voucherStatus: CommonConfig.VOUCHER_STATUS.VALID,
            region: region,
        });
    });
    // 相同地区，不同语言的icon一样
    var firstItem = voucherDatas[0];
    displayTemplate.voucher_icon = firstItem.iconImage;
    voucherDatas.forEach(function (item, index) {
        item.iconText &&
            displayTemplate.icon_text.values.push({
                lang: langs[index],
                value: item.iconText,
            });
        item.headerText &&
            displayTemplate.header.values.push({
                lang: langs[index],
                value: item.headerText,
            });
        if (item.subHeaderText) {
            displayTemplate.sub_header.values.push({
                lang: langs[index],
                value: item.subHeaderText,
            });
        }
        if (item.labelTextList) {
            item.labelTextList.forEach(function (label, idx) {
                var values = {
                    lang: langs[index],
                    value: label,
                };
                if (!displayTemplate.information_labels[idx]) {
                    displayTemplate.information_labels[idx] = {
                        values: [],
                    };
                }
                displayTemplate.information_labels[idx].values.push(values);
            });
        }
        else {
            delete displayTemplate.information_labels;
        }
    });
    if (((_b = displayTemplate.sub_header) === null || _b === void 0 ? void 0 : _b.values.length) == 0) {
        delete displayTemplate.sub_header;
    }
    return displayTemplate;
}
// 生成extra data
export function getVoucherExtraData(_a) {
    var region = _a.region, dataSource = _a.dataSource;
    var langs = LANGES_CATEGORY[region];
    var extraData = {
        header: {
            values: [],
        },
    };
    if (!langs || !langs.length)
        return extraData;
    var extra = langs.map(function (locale) {
        return {
            headerText: getVoucherHeaderText(region, locale, {
                rewardType: dataSource.rewardType,
                discountPercentage: dataSource.discountPercentage,
                discountAmount: dataSource.discountAmount,
                preApplicableInstalment: dataSource.preApplicableInstalment
            }, true)
        };
    });
    extra.forEach(function (item, index) {
        item.headerText &&
            extraData.header.values.push({
                lang: langs[index],
                value: item.headerText,
            });
    });
    return extraData;
}
