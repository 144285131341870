import React, { useMemo } from 'react';
import { ReactComponent as VoucherIconBg } from './images/voucherIconBg.svg';
import './styles.less';
import classNames from 'classnames';
import { CommonConfig } from "../../config";
import { createFormatMessage } from '../../utils/intl';
import { resolveVoucherData } from '../../utils/resolveVoucherData';
import { resolveDisplayTemplate } from '../../utils/resolveDisplayTemplate';
import { isUserVoucher } from '../../types';
var VOUCHER_STATUS = CommonConfig.VOUCHER_STATUS, Voucher_Status_Text = CommonConfig.Voucher_Status_Text;
var BaseVoucherCard = function (props) {
    var _a, _b, _c;
    var layout = props.layout, dataSource = props.dataSource, region = props.region, locale = props.locale, voucherStatus = props.voucherStatus, className = props.className, style = props.style;
    var classes = classNames(className, 'promotion-voucher-card', "".concat(layout, "-layout"), Voucher_Status_Text[voucherStatus || VOUCHER_STATUS.VALID]);
    var formatMessage = createFormatMessage(locale, region);
    var memoData = useMemo(function () {
        // 使用template 数据
        if (isUserVoucher(dataSource)) {
            return resolveDisplayTemplate({
                region: region,
                locale: locale,
                dataSource: dataSource,
                voucherStatus: voucherStatus,
            });
        }
        // 使用普通字段数据
        return resolveVoucherData({
            region: region,
            locale: locale,
            dataSource: dataSource,
            voucherStatus: voucherStatus,
        });
    }, [locale, region, dataSource]);
    return (React.createElement("div", { style: style, className: classes },
        React.createElement("div", { className: "promotion-voucher-card-wrapper" }, (_a = props.extra) === null || _a === void 0 ? void 0 :
            _a.call(props),
            React.createElement("div", { className: "promotion-voucher-card-icon-box" },
                React.createElement(VoucherIconBg, { className: classNames('promotion-voucher-card-icon-bg') }),
                voucherStatus !== VOUCHER_STATUS.VALID && props.showTag && (React.createElement("div", { className: "promotion-voucher-card-invalid-tag" },
                    React.createElement("span", { className: "promotion-voucher-card-tag-text" }, formatMessage({
                        id: "voucher.".concat(Voucher_Status_Text[voucherStatus || VOUCHER_STATUS.VALID]),
                    })))),
                React.createElement("div", { className: "promotion-voucher-card-icon-wrapper" }, memoData.iconImage && (React.createElement(React.Fragment, null,
                    React.createElement("img", { className: "promotion-voucher-card-img", src: memoData.iconImage, alt: "icon" }),
                    React.createElement("div", { className: "promotion-voucher-card-text" }, memoData.iconText))))),
            React.createElement("div", { className: "promotion-voucher-card-content" },
                React.createElement("div", { className: "promotion-voucher-card-head" }, memoData.headerText),
                memoData.subHeaderText && (React.createElement("div", { className: "promotion-voucher-card-subhead" }, memoData.subHeaderText)),
                memoData.labelTextList &&
                    React.createElement("div", { className: "promotion-voucher-card-label-wrapper" }, memoData.labelTextList.slice(0, 2).map(function (labelText, idx) {
                        return (React.createElement("div", { className: "promotion-voucher-card-label", key: "label-".concat(idx) }, labelText));
                    })),
                React.createElement("div", { className: classNames('promotion-voucher-card-time', ((_b = memoData.validTime) === null || _b === void 0 ? void 0 : _b.isExpiring) && 'promotion-voucher-card-expiring') }, (_c = memoData.validTime) === null || _c === void 0 ? void 0 : _c.validText)))));
};
BaseVoucherCard.defaultProps = {
    layout: 'mobile',
    locale: 'en',
    voucherStatus: VOUCHER_STATUS.VALID,
    showTag: true,
};
export default React.memo(BaseVoucherCard);
