import classNames from "classnames";
import React, { useEffect, useState } from "react";
import './styles.less';
var prefixCls = 'credit-sdk-component-range-slider';
export var RangeSlider = function (props) {
    var className = props.className, sliderRef = props.sliderRef;
    var _a = useState(0), indicatorRate = _a[0], setIndicatorRate = _a[1];
    var _b = useState(0), leftRate = _b[0], setLeftRate = _b[1];
    var getScrollData = function () {
        if (!sliderRef.current) {
            return;
        }
        var totalWidth = sliderRef.current.scrollWidth;
        var viewWidth = sliderRef.current.clientWidth;
        var scrollLeftDistance = sliderRef.current.scrollLeft;
        var indicatorRate = viewWidth / totalWidth * 100;
        var leftRate = scrollLeftDistance / totalWidth * 100;
        setIndicatorRate(indicatorRate);
        setLeftRate(leftRate);
    };
    useEffect(function () {
        if (sliderRef.current) {
            getScrollData();
            sliderRef.current.addEventListener('scroll', getScrollData, false);
        }
        return function () { var _a; return (_a = sliderRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', getScrollData); };
    }, [sliderRef.current]);
    if (indicatorRate >= 100) {
        return null;
    }
    return (React.createElement("div", { className: classNames(prefixCls, className) },
        React.createElement("div", { className: "".concat(prefixCls, "-bar") }),
        React.createElement("div", { className: "".concat(prefixCls, "-indicator"), style: {
                width: "".concat(indicatorRate, "%"),
                left: "".concat(leftRate, "%")
            } })));
};
