import { compactCurrency, formatCurrency } from '@shopee_common/currency';
export function currencyFormat(value, region, precision) {
    if (precision === void 0) { precision = 1; }
    var regionMap = {
        id: 'ID',
        th: 'TH',
        ph: 'PH',
        sg: 'SG',
        vn: 'VN',
        my: 'MY',
        br: 'BR'
    };
    if (!precision) {
        return formatCurrency(regionMap[region], value);
    }
    return compactCurrency(regionMap[region], value, precision);
}
