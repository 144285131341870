import { queryParams, queryStringify } from '@credit/utils';
export var getFullUsageUrl = function (usageUrl, source) {
    if (usageUrl === void 0) { usageUrl = ''; }
    var hostAndPath = usageUrl.split('?')[0];
    var query = queryParams(usageUrl);
    query.from = source;
    var search = queryStringify(query, true);
    return "".concat(hostAndPath !== null && hostAndPath !== void 0 ? hostAndPath : '').concat(search);
};
export var getFullTermsUrl = function (termsUrl, backAction, source) {
    if (termsUrl === void 0) { termsUrl = ''; }
    if (backAction === void 0) { backAction = 'back'; }
    var hostAndPath = termsUrl.split('?')[0];
    var query = queryParams(termsUrl);
    query.from = source;
    query.is_rn = backAction === 'pop' ? '1' : '0';
    var search = queryStringify(query, true);
    return "".concat(hostAndPath !== null && hostAndPath !== void 0 ? hostAndPath : '').concat(search);
};
