import { findMultiLangStringValue } from "../../../../utils/resolveDisplayTemplate";
import classNames from "classnames";
import React, { useRef } from "react";
import { BannerSlotWidget } from "../../widgets";
import { createFormatMessage } from "../../../../utils/intl";
import { ReactComponent as Arrow } from './images/arrow.svg';
import './styles.less';
import { WIDGETS } from "../../../register";
import useInView from "../../../../hooks/useInview";
import { isUndefined, pickDSLData, px2em } from "../../../../utils/tools";
import { useRect } from "../../../../hooks/useRect";
var clsPrefix = 'credit-promotion-sdk-voucher-banner';
export var VoucherBanner = function (props) {
    var _a, _b, _c;
    var dsl = props.dsl, locale = props.locale, region = props.region, _d = props.scale, scale = _d === void 0 ? 1 : _d, _e = props.layout, layout = _e === void 0 ? 'mobile' : _e;
    if (!dsl) {
        return null;
    }
    var bannerRef = useRef(null);
    var rect = useRect(bannerRef);
    var render = dsl.render, basic = dsl.basic;
    var bannerData;
    if (Array.isArray(render)) {
        bannerData = render[0];
    }
    else {
        bannerData = render;
    }
    var background = bannerData.background, slots = bannerData.slots, behavior = bannerData.behavior;
    var imageUrl = findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.image, locale);
    var formatMessage = createFormatMessage(locale, region);
    var ref = useInView({
        onEnter: function () {
            var _a;
            (_a = props.onEnter) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        onLeave: function () {
            var _a;
            (_a = props.onLeave) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        threshold: (_a = props.threshold) !== null && _a !== void 0 ? _a : 0.8
    })[0];
    return (React.createElement("div", { key: basic === null || basic === void 0 ? void 0 : basic.bannerId, ref: ref, className: classNames(clsPrefix, props.className), style: props.style, onClick: function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, dsl); } },
        React.createElement("div", { style: {
                backgroundImage: "url(".concat(imageUrl, ")"),
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                borderRadius: !isUndefined((_b = props.config) === null || _b === void 0 ? void 0 : _b.radius) ? "".concat(px2em((_c = props.config) === null || _c === void 0 ? void 0 : _c.radius)) : "".concat(px2em(8))
            }, ref: bannerRef, className: classNames("".concat(clsPrefix, "-background")) },
            React.createElement("div", { className: classNames("".concat(clsPrefix, "-voucher-wrapper")), style: { height: ((rect === null || rect === void 0 ? void 0 : rect.height) ? rect.height : 0) / scale } },
                React.createElement("div", null,
                    React.createElement(BannerSlotWidget, { id: WIDGETS.BANNER_VOUCHER, slots: slots, locale: locale, region: region, layout: layout, className: "".concat(clsPrefix, "-voucher-slot") }),
                    React.createElement("div", { className: "".concat(clsPrefix, "-button") },
                        React.createElement("div", { className: 'use' },
                            React.createElement("span", null, formatMessage({ id: 'banner.voucher.use' })),
                            React.createElement(Arrow, { className: "arrow" }))))))));
};
