var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import BaseVoucherCard from '../BaseVoucherCard';
import { CommonConfig } from '../../config';
import { ReactComponent as ArrowRight } from '../BaseVoucherCard/images/arrowRight.svg';
import { createFormatMessage } from '../../utils/intl';
import Initial from '../Initial';
import { getFullTermsUrl, getFullUsageUrl } from "../../utils/webPath";
var renderExtra = function (props) {
    var formatMessage = createFormatMessage(props.locale, props.region);
    var layout = props.layout, source = props.source, backAction = props.backAction;
    var onUsageClick = function (userVoucher) {
        var _a;
        var partnersLink = userVoucher.partnersLink, termsLink = userVoucher.termsLink;
        // 在移动端时才生效
        var fullUsageLink = getFullUsageUrl(partnersLink, source);
        if (layout === 'mobile' && !props.onUsageClick) {
            window.location.href = fullUsageLink;
        }
        else {
            (_a = props === null || props === void 0 ? void 0 : props.onUsageClick) === null || _a === void 0 ? void 0 : _a.call(props, __assign(__assign({}, userVoucher), { fullTermsLink: getFullTermsUrl(termsLink, backAction, source), fullUsageLink: fullUsageLink }));
        }
    };
    var onClickTerms = function (userVoucher) {
        var _a;
        var partnersLink = userVoucher.partnersLink, termsLink = userVoucher.termsLink;
        var fullTermsLink = getFullTermsUrl(termsLink, backAction, source);
        if (layout === 'mobile' && !props.onT_CClick) {
            window.location.href = fullTermsLink;
        }
        else {
            (_a = props === null || props === void 0 ? void 0 : props.onT_CClick) === null || _a === void 0 ? void 0 : _a.call(props, __assign(__assign({}, userVoucher), { fullTermsLink: fullTermsLink, fullUsageLink: getFullUsageUrl(partnersLink, source) }));
        }
    };
    return (React.createElement(React.Fragment, null,
        props.voucherStatus === VOUCHER_STATUS.VALID && props.showUse &&
            React.createElement("div", { onClick: function () { return onUsageClick === null || onUsageClick === void 0 ? void 0 : onUsageClick(props.dataSource); }, className: 'promotion-voucher-card-use' },
                React.createElement("span", null, formatMessage({ id: 'voucher.use' })),
                React.createElement(ArrowRight, { className: 'promotion-voucher-card-arrow' })),
        props.showT_C &&
            React.createElement("div", { onClick: function () { return onClickTerms === null || onClickTerms === void 0 ? void 0 : onClickTerms(props.dataSource); }, className: 'promotion-voucher-card-t_c' }, formatMessage({ id: 'voucher.T_C' }))));
};
var VOUCHER_STATUS = CommonConfig.VOUCHER_STATUS;
var VoucherCard = function (props) {
    return (React.createElement(Initial, { region: props.region, locale: props.locale || 'en' },
        React.createElement(BaseVoucherCard, __assign({}, props, { extra: function () { return renderExtra(props); } }))));
};
VoucherCard.defaultProps = {
    showUse: true,
    showT_C: true,
    layout: 'mobile',
    backAction: 'back',
    source: 'promotion'
};
export default VoucherCard;
