var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SelectionCard from '../SelectionCard';
import './styles.less';
import { fetchUserVoucherListForUse } from '../../apis';
import classNames from 'classnames';
import { createFormatMessage } from '../../utils/intl';
import Error from '../Error';
import { isFunction } from "../../utils/tools";
import Initial from '../Initial';
import { getFullTermsUrl } from "../../utils/webPath";
import Loading from '../Loading';
import BITracker from "../../utils/BITracker";
import { findMultiLangStringValue } from "../../utils/resolveDisplayTemplate";
var tracker = new BITracker();
var prefixCls = 'promotion-select-voucher';
var getVoucherTitle = function (tpl, locale) {
    var _a, _b;
    var header = tpl.header;
    return (_b = (_a = header === null || header === void 0 ? void 0 : header.values) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.lang === locale; })) === null || _b === void 0 ? void 0 : _b.value;
};
var getUseableVoucher = function (voucherList, appliedId) {
    var backup = undefined;
    var len = voucherList.length;
    for (var i = len - 1; i >= 0; i--) {
        var voucher = voucherList[i];
        if (voucher.usable) {
            backup = voucher;
        }
        if (appliedId === voucher.userVoucherId && voucher.usable) {
            return voucher;
        }
    }
    return backup;
};
var VoucherSelection = function (props, ref) {
    var region = props.region, locale = props.locale, className = props.className, defaultApplyVoucherId = props.defaultApplyVoucherId, bizType = props.bizType, source = props.source, rules = props.rules, fetcher = props.fetcher, backAction = props.backAction;
    var formatMessage = createFormatMessage(locale, region);
    var userVoucherListRef = useRef([]);
    var _a = useState([]), userVoucherList = _a[0], setUserVoucherList = _a[1];
    var _b = useState(null), appliedVoucher = _b[0], setAppliedVoucher = _b[1];
    var _c = useState(false), hasError = _c[0], setHasError = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var onClickTerms = function (userVoucher, idx) {
        var _a;
        var termsLink = userVoucher.termsLink;
        var fullTermsLink = getFullTermsUrl(termsLink, backAction, source);
        tracker.trackClickEvent({
            page_type: props.pageType,
            page_section: 'voucher_selection',
            target_type: 't_and_c',
            data: {
                voucher_id: userVoucher.userVoucherId,
                voucher_name: findMultiLangStringValue(userVoucher.displayTemplate.header, props.locale),
                location: idx + 1,
                is_usable: userVoucher.usable
            }
        });
        if (!props.onClickT_C) {
            window.location.href = fullTermsLink;
        }
        else {
            (_a = props === null || props === void 0 ? void 0 : props.onClickT_C) === null || _a === void 0 ? void 0 : _a.call(props, __assign(__assign({}, userVoucher), { fullTermsLink: fullTermsLink }), idx);
        }
    };
    var fetchData = function (type, applyId) {
        var fetchPromise = isFunction(fetcher) ? fetcher({ type: type }) : fetchUserVoucherListForUse({
            bizType: bizType,
            region: region,
            source: source,
            params: rules || {}
        }, undefined, props.timeout);
        // 优先使用data
        if (props.data !== undefined) {
            fetchPromise = Promise.resolve({ code: 0, result: { list: props.data } });
        }
        setLoading(true);
        return Promise.resolve(fetchPromise)
            .then(function (resp) {
            var _a;
            var code = resp.code, result = resp.result;
            var list = (result === null || result === void 0 ? void 0 : result.list) ? result.list : [];
            userVoucherListRef.current = __spreadArray([], list, true);
            setHasError(code !== 0);
            setApplyVoucher(__spreadArray([], list, true), applyId);
            (_a = props.onFetchEnd) === null || _a === void 0 ? void 0 : _a.call(props, __assign({}, resp), getUseableVoucher(list, applyId));
            return resp;
        })
            .catch(function (e) {
            var _a;
            (_a = props.onFetchEnd) === null || _a === void 0 ? void 0 : _a.call(props, null, undefined, e);
            setHasError(true);
            return e;
        })
            .finally(function () {
            setLoading(false);
        });
    };
    var setApplyVoucher = function (list, applyId) {
        var _a;
        var appliedVoucherIndex = list.findIndex(function (voucher) { return voucher.userVoucherId === applyId; });
        var applied = null;
        if (appliedVoucherIndex > -1) {
            applied = (_a = list.splice(appliedVoucherIndex, 1)) === null || _a === void 0 ? void 0 : _a[0];
        }
        setUserVoucherList(list);
        setAppliedVoucher(applied);
        return { applied: applied, appliedVoucherIndex: appliedVoucherIndex };
    };
    var onClickCloseAppliedVoucher = function (userVoucher) {
        setAppliedVoucher(null);
        setUserVoucherList(__spreadArray([], userVoucherListRef.current, true));
        tracker.trackClickEvent({
            page_type: props.pageType,
            page_section: 'voucher_selection',
            target_type: 'cancel',
            data: {
                voucher_id: userVoucher === null || userVoucher === void 0 ? void 0 : userVoucher.userVoucherId,
                voucher_name: (userVoucher === null || userVoucher === void 0 ? void 0 : userVoucher.usable)
                    ? findMultiLangStringValue(userVoucher === null || userVoucher === void 0 ? void 0 : userVoucher.displayTemplate.header, props.locale)
                    : undefined
            }
        });
    };
    useEffect(function () {
        fetchData('Initial', defaultApplyVoucherId);
    }, [
        props.data,
        rules === null || rules === void 0 ? void 0 : rules.loanAmount,
        rules === null || rules === void 0 ? void 0 : rules.loanTenure,
        rules === null || rules === void 0 ? void 0 : rules.paymentChannel,
        rules === null || rules === void 0 ? void 0 : rules.paymentSubChannel,
        rules === null || rules === void 0 ? void 0 : rules.repaymentAmount
    ]);
    useEffect(function () {
        if (props.visible === true) {
            fetchData('Initial', defaultApplyVoucherId).then(function (resp) {
                var result = resp.result;
                var list = (result || {}).list;
                tracker.trackImpressionEvent({
                    page_type: props.pageType,
                    page_section: 'voucher_selection',
                    data: {
                        voucher_list: (list === null || list === void 0 ? void 0 : list.some(function (item) { return item.usable; })) ?
                            list === null || list === void 0 ? void 0 : list.map(function (item, index) {
                                return {
                                    voucher_id: item.userVoucherId,
                                    is_usable: item.usable,
                                    location: index,
                                    unusable_reason: formatMessage({ id: item.unusableMsg }),
                                    voucher_name: findMultiLangStringValue(item.displayTemplate.header, props.locale)
                                };
                            }) : []
                    }
                });
                return resp;
            });
        }
    }, [props.visible]);
    useImperativeHandle(ref, function () { return ({
        applyVoucher: function (appliedId) {
            var _a;
            var _b = setApplyVoucher(__spreadArray([], userVoucherListRef.current, true), appliedId), applied = _b.applied, appliedVoucherIndex = _b.appliedVoucherIndex;
            (_a = props.onApplyVoucher) === null || _a === void 0 ? void 0 : _a.call(props, applied, appliedVoucherIndex);
        },
        unApplyVoucher: function () {
            var _a;
            onClickCloseAppliedVoucher(appliedVoucher);
            (_a = props.onUnApplyVoucher) === null || _a === void 0 ? void 0 : _a.call(props, appliedVoucher);
        },
        reload: function () {
            return fetchData('Reload', props.defaultApplyVoucherId);
        }
    }); });
    if (loading) {
        return (React.createElement("div", { className: prefixCls },
            React.createElement(Loading, null)));
    }
    if (hasError) {
        return (React.createElement(Initial, { region: props.region, locale: props.locale },
            React.createElement("div", { className: classNames(prefixCls, className) },
                React.createElement(Error, { retry: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var res, e_1;
                        var _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _c.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, fetchData('Retry', defaultApplyVoucherId)];
                                case 1:
                                    res = _c.sent();
                                    (_a = props === null || props === void 0 ? void 0 : props.onRetry) === null || _a === void 0 ? void 0 : _a.call(props, res);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _c.sent();
                                    (_b = props === null || props === void 0 ? void 0 : props.onRetry) === null || _b === void 0 ? void 0 : _b.call(props, e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, region: region, locale: locale }))));
    }
    return (React.createElement(Initial, { region: props.region, locale: props.locale },
        React.createElement("div", { className: classNames(prefixCls, className) },
            appliedVoucher && (React.createElement("div", { className: "".concat(prefixCls, "-apply-wrapper") },
                React.createElement("div", { className: "".concat(prefixCls, "-apply-voucher-title") },
                    formatMessage({ id: 'voucher.selection.applied' }),
                    React.createElement("span", { className: appliedVoucher.usable
                            ? "".concat(prefixCls, "-apply-voucher-title-text")
                            : "".concat(prefixCls, "-apply-voucher-title-inapplicable-text") }, appliedVoucher.usable ?
                        (React.createElement("span", null, getVoucherTitle(appliedVoucher.displayTemplate, locale)))
                        : (React.createElement("span", null, formatMessage({ id: 'voucher.selection.inapplicable' }))))),
                React.createElement("div", { className: "".concat(prefixCls, "-card-wrapper") },
                    React.createElement(SelectionCard, { className: "".concat(prefixCls, "-card"), closable: true, region: region, locale: locale, applicable: appliedVoucher.usable, layout: 'mobile', dataSource: appliedVoucher, onClose: function () {
                            var _a;
                            onClickCloseAppliedVoucher(appliedVoucher);
                            (_a = props.onUnApplyVoucher) === null || _a === void 0 ? void 0 : _a.call(props, appliedVoucher);
                        }, onT_CClick: function () {
                            onClickTerms(appliedVoucher, 0);
                        } }),
                    !appliedVoucher.usable && appliedVoucher.unusableMsg && (React.createElement("div", { className: "".concat(prefixCls, "-reminder") }, formatMessage({ id: appliedVoucher.unusableMsg })))))),
            (appliedVoucher && !!userVoucherList.length) && (React.createElement("div", { className: "".concat(prefixCls, "-other") }, formatMessage({ id: 'voucher.selection.other' }))),
            userVoucherList.map(function (userVoucher, index) {
                var userVoucherId = userVoucher.userVoucherId, usable = userVoucher.usable, unusableMsg = userVoucher.unusableMsg;
                return (React.createElement("div", { className: "".concat(prefixCls, "-list"), key: userVoucherId },
                    React.createElement("div", { className: "".concat(prefixCls, "-card-wrapper") },
                        React.createElement(SelectionCard, { className: "".concat(prefixCls, "-card"), closable: false, region: region, locale: locale, applicable: usable, showApply: true, layout: 'mobile', dataSource: userVoucher, onApply: function () {
                                var _a;
                                setApplyVoucher(__spreadArray([], userVoucherListRef.current, true), userVoucher.userVoucherId);
                                (_a = props.onApplyVoucher) === null || _a === void 0 ? void 0 : _a.call(props, userVoucher, index);
                                tracker.trackClickEvent({
                                    page_type: props.pageType,
                                    page_section: 'voucher_selection',
                                    target_type: 'apply',
                                    data: {
                                        voucher_id: userVoucher.userVoucherId,
                                        voucher_name: findMultiLangStringValue(userVoucher.displayTemplate.header, props.locale),
                                        location: index + 1
                                    }
                                });
                            }, onT_CClick: function () {
                                onClickTerms(userVoucher, index);
                            } }),
                        !usable && unusableMsg && (React.createElement("div", { className: "".concat(prefixCls, "-reminder") }, formatMessage({ id: unusableMsg }))))));
            }))));
};
export default forwardRef(VoucherSelection);
