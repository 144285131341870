import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import { CommonConfig } from "../config";
import Time from './time';
import { createFormatMessage } from './intl';
dayjs.extend(Duration);
var defaultLange = 'en';
var defaultRegion = 'id';
export default function getValidTime(validityTo, voucherStatus, locale, region) {
    if (locale === void 0) { locale = defaultLange; }
    if (region === void 0) { region = defaultRegion; }
    var VOUCHER_STATUS = CommonConfig.VOUCHER_STATUS;
    var formatMessage = createFormatMessage(locale, region);
    if (region && Time._locale !== region) {
        Time.config({
            region: region,
        });
    }
    if (voucherStatus !== VOUCHER_STATUS.VALID) {
        var validText = formatMessage({
            id: 'voucher.valid_till',
            values: { date: Time.format(validityTo, 'DD.MM.YYYY') },
        });
        return {
            isExpiring: false,
            validText: validText,
        };
    }
    var nowTime = dayjs();
    // seconds
    var validToTime = dayjs.unix(validityTo / 1000);
    var duration = dayjs.duration(validToTime.diff(nowTime));
    var daysToLeft = duration.asDays();
    var hoursToLeft = duration.asHours();
    var minutesToLeft = duration.asMinutes();
    // > 3
    if (daysToLeft > 3) {
        var validText = formatMessage({
            id: 'voucher.valid_till',
            values: { date: Time.format(validityTo, 'DD.MM.YYYY') },
        });
        return {
            isExpiring: false,
            validText: validText
        };
    }
    // [1, 3]
    if (daysToLeft >= 1 && daysToLeft <= 3) {
        var days = Math.floor(daysToLeft);
        return {
            isExpiring: true,
            validText: formatMessage({
                id: days > 1 ? 'voucher.expiring.days' : 'voucher.expiring.one_day',
                values: { days: days }
            })
        };
    }
    // (1 day, 1 hour]
    if (daysToLeft < 1 && hoursToLeft >= 1) {
        var hours = Math.floor(hoursToLeft);
        return {
            isExpiring: true,
            validText: formatMessage({
                id: hours > 1 ? 'voucher.expiring.hours' : 'voucher.expiring.one_hour',
                values: { hours: hours }
            })
        };
    }
    // (1 hour, 1 minute]
    if (hoursToLeft < 1 && minutesToLeft >= 1) {
        var minutes = Math.floor(minutesToLeft);
        return {
            isExpiring: true,
            validText: formatMessage({
                id: minutes > 1 ? 'voucher.expiring.minutes' : 'voucher.expiring.one_minute',
                values: { minutes: minutes }
            })
        };
    }
    // (1 nimute, 0]
    if (minutesToLeft < 1 && minutesToLeft > 0) {
        return {
            isExpiring: true,
            validText: formatMessage({ id: 'voucher.expiring.one_minute' })
        };
    }
    return {
        isExpiring: false,
        validText: !!validityTo ? formatMessage({
            id: 'voucher.valid_till',
            values: { date: Time.format(validityTo, 'DD.MM.YYYY') },
        }) : ''
    };
}
