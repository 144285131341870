import { getConfig, CommonConfig } from '../config';
import Time from './time';
import { createFormatMessage } from './intl';
import getValidTime from './getValidTime';
import { currencyFormat } from './CurrencyFormat';
var ICON_DATA = CommonConfig.ICON_DATA, VOUCHER_STATUS = CommonConfig.VOUCHER_STATUS, ApplicableInstalmentTypes = CommonConfig.ApplicableInstalmentTypes, VOUCHER_TYPE = CommonConfig.VOUCHER_TYPE;
export function getVoucherHeaderText(region, locale, dataSource, isComplete) {
    if (isComplete === void 0) { isComplete = false; }
    var headerText = '';
    var formatMessage = createFormatMessage(locale, region);
    var config = getConfig(region);
    if (!config) {
        return headerText;
    }
    var VOUCHER_TYPE_TITLES = config.VOUCHER_TYPE_TITLES, PercentageVoucherTypes = config.PercentageVoucherTypes;
    if (dataSource.rewardType) {
        var values = {};
        // percentage
        if ((PercentageVoucherTypes === null || PercentageVoucherTypes === void 0 ? void 0 : PercentageVoucherTypes.indexOf(dataSource.rewardType)) > -1) {
            values.percentage = dataSource.discountPercentage;
        }
        else {
            // absolute
            values.discountAmount = currencyFormat(dataSource.discountAmount || 0, region);
        }
        var titleKeys = (VOUCHER_TYPE_TITLES === null || VOUCHER_TYPE_TITLES === void 0 ? void 0 : VOUCHER_TYPE_TITLES[dataSource.rewardType]) || [];
        var transifyKey = titleKeys[0];
        if ((dataSource.rewardType === VOUCHER_TYPE.INSTALMENT_FEE_ABSOLUTE ||
            dataSource.rewardType === VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE) &&
            dataSource.preApplicableInstalment === ApplicableInstalmentTypes.firstInstalment
            && isComplete) {
            if (titleKeys[1]) {
                transifyKey = titleKeys[1];
            }
        }
        headerText = formatMessage({
            id: "voucher.head.".concat(transifyKey),
            values: values,
        });
    }
    return headerText;
}
export function resolveVoucherData(_a) {
    var _b;
    var region = _a.region, locale = _a.locale, dataSource = _a.dataSource, _c = _a.voucherStatus, voucherStatus = _c === void 0 ? VOUCHER_STATUS.VALID : _c;
    if (!dataSource)
        return { validTime: {} };
    if (region && Time._locale !== region) {
        Time.config({
            region: region,
        });
    }
    var config = getConfig(region);
    if (!config)
        return { validTime: {} };
    var formatMessage = createFormatMessage(locale, region);
    var ApplicableInstalmentDesc = config.ApplicableInstalmentDesc;
    var PaymentMethodDesc = CommonConfig.PaymentMethodDesc;
    //  header
    var headerText = getVoucherHeaderText(region, locale, {
        rewardType: dataSource.rewardType,
        discountPercentage: dataSource.discountPercentage,
        discountAmount: dataSource.discountAmount,
        preApplicableInstalment: dataSource.preApplicableInstalment
    });
    // subHeader
    // Min. tenure > Min. loan > Cap
    var subHeaderTexts = [];
    if (dataSource.loanTenure) {
        subHeaderTexts.push(formatMessage({
            id: 'voucher.subhead.loanTenure',
            values: {
                loanTenure: dataSource.loanTenure,
            },
        }));
    }
    else if (dataSource.validTenure && dataSource.validTenure.length) {
        var validTenure = dataSource
            .validTenure
            .sort(function (a, b) { return a - b; })
            .join('&');
        subHeaderTexts.push(formatMessage({
            id: 'voucher.subhead.validTenure',
            values: {
                validTenure: validTenure
            }
        }));
    }
    if (dataSource.loanAmount) {
        subHeaderTexts.push(formatMessage({
            id: 'voucher.subhead.minLoan',
            values: {
                loanAmount: currencyFormat(dataSource.loanAmount, region, 2),
            },
        }));
    }
    if (!!Number(dataSource.termCapAmount)) {
        subHeaderTexts.push(formatMessage({
            id: 'voucher.subhead.discountCapMonthly',
            values: {
                discountCap: currencyFormat(Number(dataSource.termCapAmount), region, 2),
            },
        }));
    }
    else if (!!Number(dataSource.totalCapAmount)) {
        subHeaderTexts.push(formatMessage({
            id: 'voucher.subhead.discountCapTotal',
            values: {
                discountCap: currencyFormat(Number(dataSource.totalCapAmount), region, 2),
            },
        }));
    }
    if (dataSource.repaymentAmount) {
        subHeaderTexts.push(formatMessage({
            id: 'voucher.subhead.minRepayment',
            values: {
                minRepayment: currencyFormat(dataSource.repaymentAmount, region, 2),
            },
        }));
    }
    // label
    var labelTextList = [];
    if (dataSource.preApplicableInstalment) {
        var applicableInsLabelText = formatMessage({
            id: "voucher.label.".concat(ApplicableInstalmentDesc === null || ApplicableInstalmentDesc === void 0 ? void 0 : ApplicableInstalmentDesc[dataSource.preApplicableInstalment]),
        });
        if (applicableInsLabelText) {
            labelTextList.push(applicableInsLabelText);
        }
    }
    if (dataSource.paymentMethod) {
        var paymentMethodLabelText = formatMessage({
            id: "voucher.label.paymentMethod.".concat(PaymentMethodDesc[dataSource.paymentMethod]),
        });
        if (paymentMethodLabelText) {
            labelTextList.push(paymentMethodLabelText);
        }
    }
    return {
        iconImage: (_b = ICON_DATA[region]) === null || _b === void 0 ? void 0 : _b.icon[dataSource.creditProductType],
        iconText: formatMessage({
            id: "voucher.".concat(ICON_DATA.productName[dataSource.creditProductType]),
        }),
        headerText: headerText,
        subHeaderText: subHeaderTexts.join(', '),
        labelTextList: labelTextList,
        validTime: dataSource.validityTo
            ? getValidTime(dataSource.validityTo * 1000, voucherStatus, locale, region)
            : {}
    };
}
export function resolvePopupVoucher(props) {
    var defaultInfo = {
        headerText: '',
        discountText: '',
        validTime: ''
    };
    var locale = props.locale, region = props.region, userVoucherInfo = props.userVoucherInfo;
    var detail = userVoucherInfo === null || userVoucherInfo === void 0 ? void 0 : userVoucherInfo.detail;
    var rewardRule = userVoucherInfo === null || userVoucherInfo === void 0 ? void 0 : userVoucherInfo.rewardRule;
    if (!userVoucherInfo || !detail) {
        return defaultInfo;
    }
    if (region && Time._locale !== region) {
        Time.config({
            region: region,
        });
    }
    var config = getConfig(region);
    if (!config) {
        return defaultInfo;
    }
    var formatMessage = createFormatMessage(locale, region);
    var VOUCHER_TYPE_TITLES = config.VOUCHER_TYPE_TITLES;
    var isPercentageType = CommonConfig.isPercentageType;
    // header text
    var headerText = '';
    if (detail.rewardType) {
        var titleKeys = (VOUCHER_TYPE_TITLES === null || VOUCHER_TYPE_TITLES === void 0 ? void 0 : VOUCHER_TYPE_TITLES[detail.rewardType]) || [];
        var transifyKey = titleKeys[0];
        if ((detail.rewardType === VOUCHER_TYPE.INSTALMENT_FEE_ABSOLUTE ||
            detail.rewardType === VOUCHER_TYPE.INSTALMENT_FEE_PERCENTAGE) &&
            rewardRule.preApplicableInstalment === ApplicableInstalmentTypes.firstInstalment) {
            if (titleKeys[1]) {
                transifyKey = titleKeys[1];
            }
        }
        headerText = formatMessage({
            id: "popup.voucher.head.".concat(transifyKey),
        });
    }
    //  discount text
    var discountText = '';
    if (detail.rewardType) {
        var values = {};
        var discountType 
        // percentage
        = void 0;
        // percentage
        if (isPercentageType(detail.rewardType)) {
            values.percentage = detail.discountPercentage;
            discountType = 'percentage';
        }
        else {
            // absolute
            values.discountAmount = currencyFormat(detail.discountAmount || 0, region);
            discountType = 'absolute';
        }
        discountText = formatMessage({
            id: "popup.voucher.discount.".concat(discountType),
            values: values,
        });
    }
    return {
        headerText: headerText,
        discountText: discountText,
        validTime: detail.validityTo ? formatMessage({
            id: 'voucher.valid_till',
            values: { date: Time.format(detail.validityTo * 1000, 'DD.MM.YYYY') },
        }) : ''
    };
}
