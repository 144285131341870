export * from './banner-props';
export * from './popup-props';
export * from './slide-props';
export * from '../apis/types';
export * from './dsl';
export * from '../components/VoucherEntry';
export var BIZ_TYPE;
(function (BIZ_TYPE) {
    BIZ_TYPE[BIZ_TYPE["None"] = 0] = "None";
    BIZ_TYPE[BIZ_TYPE["Buyer"] = 1] = "Buyer";
    BIZ_TYPE[BIZ_TYPE["Seller"] = 2] = "Seller";
    BIZ_TYPE[BIZ_TYPE["SPL"] = 3] = "SPL";
})(BIZ_TYPE || (BIZ_TYPE = {}));
export function isUserVoucher(data) {
    if ('displayTemplate' in data) {
        return true;
    }
    return false;
}
