export var defaultPopupBaseProps = {
    closeOnMaskClick: true,
    disableBodyScroll: true,
    getContainer: function () { return document.body; },
    mask: true,
    showCloseButton: false,
    stopPropagation: ['click'],
    visible: false,
    layout: 'mobile',
};
