import { useEffect, useState } from "react";
export function useRect(ref) {
    var _a = useState(), rect = _a[0], setRect = _a[1];
    useEffect(function () {
        if (ref.current) {
            var rect_1 = ref.current.getBoundingClientRect();
            setRect(rect_1);
        }
    }, [ref.current]);
    var onResize = function () {
        if (ref.current) {
            var rect_2 = ref.current.getBoundingClientRect();
            setRect(rect_2);
        }
    };
    useEffect(function () {
        window.addEventListener('resize', onResize);
        return function () { return window.removeEventListener('resize', onResize); };
    }, []);
    return rect;
}
