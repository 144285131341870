import classNames from 'classnames';
import React from 'react';
import './styles.less';
var prefixCls = 'promotion-widget';
export var Loading = function (props) {
    return (React.createElement("div", { className: classNames("".concat(prefixCls, "-loading-container"), props.className) },
        React.createElement("div", { className: classNames("".concat(prefixCls, "-loading-ele"), "".concat(prefixCls, "-animation-delay1")) }),
        React.createElement("div", { className: classNames("".concat(prefixCls, "-loading-ele"), "".concat(prefixCls, "-animation-delay2")) }),
        React.createElement("div", { className: classNames("".concat(prefixCls, "-loading-ele")) })));
};
export default Loading;
