var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getSDKInfo } from "../utils/sdkInfo";
import { customPlugin, exceptionPlugin } from ".";
var DEFAULT_NAME = 'Promotion Web SDK';
var pointIdMap = {
    click: {
        staging: '8ea9fa1b67a2d8b3915d2cc0b6d4a85e',
        test: '8ea9fa1b67a2d8b3915d2cc0b6d4a85e',
        uat: '8ea9fa1b67a2d8b3915d2cc0b6d4a85e',
        live: '11238753c01eec11c4ab24ebbb5789d6'
    },
    impression: {
        staging: 'b0151ef27252a9b6ddb1bcade669f2ae',
        test: 'b0151ef27252a9b6ddb1bcade669f2ae',
        uat: 'b0151ef27252a9b6ddb1bcade669f2ae',
        live: '359e2aa2f337305ed47e80709a7b4232'
    }
};
var isError = function (e) {
    return e instanceof Error;
};
export var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator === null || navigator === void 0 ? void 0 : navigator.userAgent);
// export const isMobile = true
var Logger = /** @class */ (function () {
    function Logger(name) {
        if (name === void 0) { name = DEFAULT_NAME; }
        this.moduleName = name;
    }
    Logger.prototype.uploadException = function (level, args) {
        if (!isMobile) {
            return;
        }
        try {
            exceptionPlugin.capture({
                message: "".concat(this.moduleName),
                // error: args.length == 1 && isError(args[0]) ? args[0] : undefined,
                data: {
                    level: level,
                    extra: {
                        content: args === null || args === void 0 ? void 0 : args.map(function (item) {
                            if (isError(item)) {
                                return item.message;
                            }
                            return JSON.stringify(item);
                        })
                    }
                }
            });
        }
        catch (error) {
            exceptionPlugin.capture({
                message: "".concat(this.moduleName),
                error: error
            });
        }
    };
    Logger.prototype.record = function (level, data) {
        var _this = this;
        try {
            // @ts-ignore
            if (BUILD_ENV !== 'live') {
                console.log('[record]: ', this.moduleName, data);
            }
            if (!isMobile) {
                return;
            }
            getSDKInfo().then(function (sdkInfo) {
                customPlugin.sendData({
                    point_id: pointIdMap[level][sdkInfo.env],
                    data: __assign({ name: _this.moduleName }, data)
                });
            });
        }
        catch (e) {
        }
    };
    Logger.prototype.log = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (BUILD_ENV !== 'live') {
            console.log.apply(console, __spreadArray([this.moduleName], args, false));
        }
        // this.uploadException('debug', args)
    };
    Logger.prototype.info = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (BUILD_ENV !== 'live') {
            console.info.apply(console, __spreadArray([this.moduleName], args, false));
        }
        // this.uploadException('info', args)
    };
    Logger.prototype.warn = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (BUILD_ENV !== 'live') {
            console.warn.apply(console, __spreadArray([this.moduleName], args, false));
        }
        if (!isMobile) {
            return;
        }
        this.uploadException('warn', args);
    };
    Logger.prototype.error = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (BUILD_ENV !== 'live') {
            console.error.apply(console, __spreadArray([this.moduleName], args, false));
        }
        if (!isMobile) {
            return;
        }
        this.uploadException('error', args);
    };
    return Logger;
}());
export var createLogger = function (name) {
    return new Logger(name);
};
