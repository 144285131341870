var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var eventToPropRecord = {
    'click': 'onClick',
};
export function withStopPropagation(events, element) {
    var props = __assign({}, element.props);
    var _loop_1 = function (key) {
        var prop = eventToPropRecord[key];
        props[prop] = function (e) {
            var _a, _b;
            e.stopPropagation();
            (_b = (_a = element.props)[prop]) === null || _b === void 0 ? void 0 : _b.call(_a, e);
        };
    };
    for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
        var key = events_1[_i];
        _loop_1(key);
    }
    return React.cloneElement(element, props);
}
