import useInView from "../../../../hooks/useInview";
import { findMultiLangStringValue } from "../../../../utils/resolveDisplayTemplate";
import { pickDSLData } from "../../../../utils/tools";
import classNames from "classnames";
import React from "react";
import './styles.less';
var clsPrefix = 'credit-promotion-sdk-slide-item-card';
var ItemCard = function (props) {
    var _a;
    var dsl = props.dsl, locale = props.locale;
    if (!dsl) {
        return null;
    }
    var basic = dsl.basic, render = dsl.render;
    var bannerId = (basic || {}).bannerId;
    var itemCardData;
    if (Array.isArray(render)) {
        itemCardData = render[0];
    }
    else {
        itemCardData = render;
    }
    if (!itemCardData) {
        return null;
    }
    var _b = useInView({
        onEnter: function () {
            var _a;
            (_a = props.onEnter) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        onLeave: function () {
            var _a;
            (_a = props.onLeave) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale));
        },
        threshold: (_a = props.threshold) !== null && _a !== void 0 ? _a : 0.8
    }), ref = _b[0], inView = _b[1];
    var background = itemCardData.background;
    var imageUrl = findMultiLangStringValue(background === null || background === void 0 ? void 0 : background.image, locale);
    return (React.createElement("div", { className: clsPrefix, ref: ref },
        React.createElement("div", { key: bannerId, style: {
                backgroundImage: "url(".concat(imageUrl, ")"),
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }, className: classNames("".concat(clsPrefix, "-background")), onClick: function () { var _a; return (_a = props.onClick) === null || _a === void 0 ? void 0 : _a.call(props, pickDSLData(dsl, locale)); } })));
};
export default ItemCard;
