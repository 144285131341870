import UAParser from 'ua-parser-js'
import { v4 as uuid4 } from 'uuid'
import { CommonHeader, nEvent, Options, NetworkTypeEnum } from '../index.interface'

const _win = window
const _doc = document
// 标识每次进入应用
const seq_id = uuid4()

const UA = new UAParser()
const browser = UA.getBrowser()
const device = UA.getDevice()
const os = UA.getOS()

const DEFAULT_COOKIE_NAME = '__UID__'
const DEFAULT_COOKIE_EXPIRE_DAYS = 30



let networkInfo

export async function getIsWifi() {
  const result = await new Promise((resolve) => {
    setTimeout(() => {
      resolve({})
    }, 3000)
    if (window.bridgeCallHandler) {
      window.bridgeCallHandler('getNetworkInfo', {}, (res) => {
        resolve(res)
      })
    } else {
      resolve({})
    }
  })
  return result
}

export const getBrowserVersion = () => browser.version
export const getOsVersion = () => (os.version ? os.version : 'none')
export const getDeviceBrand = () => device.vendor
export const getBrowserName = () => browser.name
const isAndroid = () => os.name === 'Android'
export const isMobileAndTabletType = () => {
  switch (device.type) {
    case 'mobile':
    case 'tablet':
      return true
    default:
      return false
  }
}

export const getNavigatorInstance = () => {
  if (typeof window !== 'undefined') {
    if (window.navigator || navigator) {
      return window.navigator || navigator
    }
  }
  return false
}

export const isIOS13Check = (type) => {
  const nav = getNavigatorInstance()
  return (
    nav &&
    nav.platform &&
    (nav.platform.indexOf(type) !== -1 ||
      (nav.platform === 'MacIntel' && nav.maxTouchPoints > 1 && !window.MSStream))
  )
}

const getIPad13 = () => isIOS13Check('iPad')

export const isMobile = isMobileAndTabletType() || getIPad13()

export const addEvent = (node: any, type: string, handler: any, useCapture?: boolean): boolean => {
  if (!node) return false
  if (node.addEventListener) {
    // 兼容标准DOM事件模型
    node.addEventListener(type, handler, useCapture || false)
    return true
  }
  if (node.attachEvent) {
    // 兼容IE事件模型
    node[`e${type}${handler}`] = handler
    node[type + handler] = function() {
      node[`e${type}${handler}`](window.event)
    }
    node.attachEvent(`on${type}`, node[type + handler])
    return true
  }
  return false
}

export const getPathName = (): string => {
  return _win.location.pathname === '/' ? 'index' : _win.location.pathname
}

export const createVisitorId = (): string => {
  const s4 = function(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${s4()}-${s4()}-${s4()}-${s4()}`
}

export const getCookie = (name: string = DEFAULT_COOKIE_NAME) => {
  const cookies = _doc.cookie.split(';')
  const regex = new RegExp(`^\\s*${name}=\\s*(.*?)\\s*$`)
  for (let i = 0; i < cookies.length; i++) {
    const r = cookies[i].match(regex)
    if (r && r.length) {
      return r[1]
    }
  }
  return ''
}

export const setCookie = (
  cookieName: string = DEFAULT_COOKIE_NAME,
  cookieValue?: string
): boolean => {
  const cookieDomain = _doc.domain
  const expires = new Date(
    new Date().getTime() + DEFAULT_COOKIE_EXPIRE_DAYS * (24 * 60 * 60 * 1000)
  ).toUTCString()
  const newCookie = `${cookieName}=${escape(
    cookieValue || ''
  )};path=/;domain=${cookieDomain};expires=${expires};`
  try {
    _doc.cookie = newCookie
    return true
  } catch (e) {
    return false
  }
}

// export const fetchAndSetUid = async (_opts: Options): Promise<void> => {
//   // 轮询localStorage获取UID，0.4s/次，10次后失败。失败则分配随机uid
//   _win.getUidLoopCount = 0
//   _win.getUidLoop = setInterval(() => {
//     _win.getUidLoopCount++
//     if (_win.getUidLoopCount < 10) {
//       if (Object.prototype.hasOwnProperty.call(localStorage, UID_LOCAL_STORAGE_NAME)) {
//         const uid = localStorage.getItem(UID_LOCAL_STORAGE_NAME)
//         _opts.uid = uid!
//         // setCookie('__UID__', uid!)
//         clearInterval(_win.getUidLoop)
//         _win.getUidLoopCount = null
//         _win.getUidLoop = null
//       }
//     } else {
//       const randomUid = `visitor-${createVisitorId()}`
//       _opts.uid = randomUid!
//       // setCookie('__UID__', randomUid)
//       clearInterval(_win.getUidLoop)
//       _win.getUidLoopCount = null
//       _win.getUidLoop = null
//     }
//   }, 400)
// }

export const removeQuerystr = (url: string): string => {
  return url.replace(/\?.*$/, '')
}

// 移除协议和查询参数  eg. http:www.fruit.com/apple/age?a=1&b=2 变成 apple/age
export const removeProtocolAndQuerystr = (url: string): string => {
  return url
    ? url
        .replace(/^(http?:)?\/\//, '')
        .replace(/^(https?:)?\/\//, '')
        .replace(/\?.*$/, '')
    : ''
}

export const removeDomain = (url: string): string => {
  const pos = url.indexOf('/')
  if (pos === -1) return '/'
  return url.substring(pos)
}

export const getDomain = (url: string): string => {
  const pos = url.indexOf('/')
  if (pos === -1) return url
  return url.substring(0, pos)
}

export const getPageRoute = (url: string): string => {
  return removeDomain(removeProtocolAndQuerystr(url)).replace(/\/([a-z\-_0-9]+)?\d{2,}/g, '/**')
}

export const hookRouterStateChange = () => {
  const _wr = function(event: string) {
    const orig = window.history[event]
    return function() {
      // console.log('-==-=-wr ', arguments)
      const rv = orig.apply(window.history, arguments)
      const e: nEvent = new Event(event)
      e.arguments = arguments
      window.dispatchEvent(e)
      return rv
    }
  }
  _win.history.pushState = _wr('pushState')
  _win.history.replaceState = _wr('replaceState')
}

export const getUrlSearchParam = (url: string, key: string): string => {
  const queryStr = url.match(/\?.*$/)
  if (!queryStr) return ''
  const queryArr = queryStr[0].substr(1).split('&')
  let result = ''
  for (let i = 0; i < queryArr.length; i++) {
    const temp = queryArr[i].split('=')
    if (temp[0] === key) {
      result = temp[1]
      break
    }
  }
  return result
}

export function parseUrl(param?: string): any {
  const queryParams = {}
  const url = param || window.location.href
  url.replace(
    new RegExp('([^#?&=]+)(=([^&#]*))?', 'g'),
    // @ts-ignore
    (match, $1, $2, $3) => {
      queryParams[$1] = $3
    }
  )
  return queryParams
}

export function getSystemInfo() {
  let system_type = ''
  let device_brand = ''
  let system_version = ''

  // 手机包括平板
  if (isMobile) {
    system_type = isAndroid() ? 'Android' : 'iOS'
    device_brand = getDeviceBrand()
    system_version = getOsVersion()
  } else {
    system_type = 'PC'
    device_brand = getBrowserName()
    system_version = getBrowserVersion()
  }

  return {
    system_type,
    device_brand,
    system_version
  }
}

export function getAppVersion() {
  const appver = navigator.userAgent.match(/appver=\d+\s/)
  if (appver) {
    // 收集到的app version是五位数，收敛版本位数为3位
    const result = appver[0].split('=')[1]
    return result.slice(0, 3)
  }
  return ''
}

export function getRnVersion() {
  const rnver = navigator.userAgent.match(/rnver=\d+/)
  if (rnver) {
    return rnver[0].split('=')[1]
  }
  return ''
}

export function getLocale() {
  const country = navigator.userAgent.match(/language=\[a-zA-z]+/)
  if (country) {
    return country[0].split('=')[1]
  }
  return 'en'
}



export async function getCommonHeader(options: Options): Promise<CommonHeader> {
  /**
   * 收集设备的信息
   */
  // @ts-ignore
  const conn = navigator.connection || {}
  // @ts-ignore
  const networkType = conn.effectiveType || conn.type || 'unknown'
  if (!networkInfo) {
    networkInfo = await getIsWifi()
  }
  const isWifi = networkInfo?.networkType === 1
  
  const header = {
    ...getSystemInfo(),
    app_version: getAppVersion(),
    rn_version: getRnVersion(),
    session_id: seq_id,
    region: options.region,
    biz_type: options.bizType,
    offline_pkg_version: window.offlineVersion || options.offlineVersion || '-1',
    online_version: options.onlineVersion || '-1',
    network_type: isWifi ? NetworkTypeEnum.wifi : NetworkTypeEnum[networkType],
    platformUserId: (options.getUser && options.getUser()) || ''
  }
  return header
}

// event上报公共参数
export function getEventCommonParams(fromUrl: string) {
  return {
    timestamp: new Date().getTime(),
    fromUrl,
    url: getPageRoute(location.href)
  }
}

export function bind(event: string, callback: any): void {
  window.bridgeRegisterHandler && window.bridgeRegisterHandler(event, callback)
}

export function unBind(event: string, fn: any): void {
  window.bridgeUnRegisterHandler && window.bridgeUnRegisterHandler(event, fn)
}

export function is<T extends object>(v: any, k: string): v is T {
  return k in v;
}
