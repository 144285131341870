// FMP 计算方式
import MutationObserver from 'mutation-observer'
import { addEvent } from './common'

const START_TIME = (() => {
  try {
    return performance.timing.fetchStart || Date.now()
  } catch (error) {
    return Date.now()
  }
})()

const CHECK_INTERVAL = 500
const MAX_WAITING_INTERVAL = 10000
const EXCLUDE_TAGS = ['HEAD', 'META', 'SCRIPT', 'STYLE']

interface FMPRecord {
  fmp: number
  score: number
}

class FMP {
  // 用来判断页面是否处于 ready 状态的标志
  private hasReady = false

  // MutationObserver 对象，用于监测 dom 变动
  private observer: MutationObserver | undefined

  // 记录每次 fmp 分值的列表
  private recordList: FMPRecord[] = []

  // 用来判断 observer 对象是否已经正常监测中
  private hasObserved = false

  // 获得 fmp 的方式：promise
  private fmpPromise: Promise<number>
  private fmpResolve?: (value: number) => void
  private fmpReject?: (value?: any) => void

  constructor() {
    this.fmpPromise = new Promise((resolve, reject) => {
      this.fmpResolve = resolve
      this.fmpReject = reject
    })
  }

  // 计算当前元素的分值，depth 为元素的深度
  private calcScore(el: Element, depth: number) {
    let score = 0
    const length = el.children ? el.children.length : 0

    if (EXCLUDE_TAGS.includes(el.tagName)) {
      return score
    }

    if (length > 0) {
      for (let i = length - 1; i >= 0; i--) {
        score += this.calcScore(el.children[i], depth + 1)
      }
    }

    if (score <= 0) {
      if (!(el.getBoundingClientRect && el.getBoundingClientRect().top < window.innerHeight)) {
        return 0
      }
    }

    score += 1 + 0.5 * depth

    return score
  }

  // 返回递增式的 record 列表
  private getIncrementalRecord() {
    const { recordList } = this
    if (recordList.length <= 1) {
      return recordList
    }
    return recordList.slice(1).reduce(
      (inc, r) => {
        r.score >= inc[inc.length - 1].score && inc.push(r)
        return inc
      },
      [recordList[0]]
    )
  }

  // 根据递增式的 record 列表，计算得出符合目标的 fmp 值
  private calcFMPByRecord() {
    const irl = this.getIncrementalRecord()
    if (irl.length <= 0) {
      return -1
    }
    if (irl.length <= 2) {
      return irl[irl.length - 1].fmp
    }
    const initialRecord = { ...irl[1], rate: irl[1].score - irl[0].score }
    return irl.slice(1).reduce((record: typeof initialRecord, cur) => {
      const rate = cur.score - record.score
      return record.rate <= rate ? { ...cur, rate } : record
    }, initialRecord).fmp
  }

  // 注册 ready 事件后的回调
  private onReady(cb: Function) {
    const rn = () => {
      this.hasReady = true
      cb()
    }
    if (this.hasReady || document.readyState === 'complete') {
      return rn()
    }
    addEvent(window, 'load', () => rn())
  }

  // 初始化 observer 对象
  public async initObserver() {
    addEvent(window, 'beforeunload', () => this.endObserver())
    this.observer = new MutationObserver(() => {
      const fmp = Date.now() - START_TIME
      const score = document.body ? this.calcScore(document.body, 1) : 0
      this.recordList.push({ fmp, score })
    })
    this.observer.observe(document.body, { childList: true, subtree: true })
    this.hasObserved = true
    this.onReady(() => this.endObserverByCheck())
    const result = await this.fmpPromise
    return result
  }

  // 直接结束 observer 监测，用于 beforeunload 情况（例如在未上报 fmp 前已经关闭页面）
  // 正常情况下，请使用 endObserverByCheck
  /**
   * param { isTimeout } 是否超时
   */
  private endObserver() {
    if (!this.observer || !this.hasObserved) {
      return
    }
    const fmp = this.calcFMPByRecord()
    this.observer.disconnect()
    this.hasObserved = false
    if (typeof fmp !== 'number') {
      this.fmpReject && this.fmpReject(-1)
    } else {
      this.fmpResolve && this.fmpResolve(fmp)
    }
  }

  // 满足以下条件之一，就结束 observer 监测，并上报 FMP 的值
  // 1. 监测耗时（timestamp） > MAX_WAITING_INTERVAL
  // 2. 监测耗时（timestamp） > 2 * CHECK_INTERVAL
  private endObserverByCheck() {
    const timestamp = Date.now() - START_TIME
    const length = this.recordList.length
    const lastFMP = (length && this.recordList[length - 1].fmp) || 0
    if (timestamp > MAX_WAITING_INTERVAL || timestamp - lastFMP > 2 * CHECK_INTERVAL) {
      this.endObserver()
    } else {
      setTimeout(() => this.endObserverByCheck(), CHECK_INTERVAL)
    }
  }
}

export const reportFMP = () => {
  if (!window || !document || !MutationObserver) {
    console && console.warn('first meaningful paint can not be retrieved')
    return Promise.reject(-1)
  }
  return new FMP().initObserver()
}
