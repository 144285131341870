import { render, unmount as reactUnmount } from './render';
export function renderToBody(element, body) {
    var container = document.createElement('div');
    if (body) {
        body.appendChild(container);
    }
    else {
        document.body.append(container);
    }
    function unmount() {
        var unmountResult = reactUnmount(container);
        if (unmountResult && container.parentNode) {
            container.parentNode.removeChild(container);
        }
    }
    render(element, container);
    return unmount;
}
