import { postRequest } from "./request";
import { createLogger } from "../monitor/logger";
var logger = createLogger('PromoWebSDK:API');
export function fetchUseVoucherListForUse(body, api, timeout) {
    if (api === void 0) { api = '/api/promotion/list_user_voucher_for_use'; }
    return postRequest(api, {
        "message": {
            "filter": {
                "bizTypeIn": { "inValues": [body.bizType] }
            },
            "params": body.params,
            "bizCommon": {
                "bizType": body.bizType,
                "bizCountry": body.region
            },
            source: body.source
        }
    }, timeout);
}
// 单词拼错了，然而已经上线了...这里纠正一下
export var fetchUserVoucherListForUse = fetchUseVoucherListForUse;
export function activityEventTrigger(body, api) {
    if (api === void 0) { api = '/api/promotion/core/event_trigger'; }
    var url = api;
    if (window.__PROMO_SDK_API_DOMAIN__ && url.indexOf('http') !== 0) {
        url = "".concat(window.__PROMO_SDK_API_DOMAIN__).concat(url);
    }
    return postRequest(url, {
        message: {
            eventCode: body.eventCode,
            bizCommon: {
                bizType: body.bizType,
                bizCountry: body.region
            }
        }
    });
}
export function activityEventReport(body, api) {
    if (api === void 0) { api = '/api/promotion/core/event_report'; }
    var url = api;
    if (window.__PROMO_SDK_API_DOMAIN__ && url.indexOf('http') !== 0) {
        url = "".concat(window.__PROMO_SDK_API_DOMAIN__).concat(url);
    }
    return postRequest(url, {
        message: {
            eventCode: body.eventCode,
            resourceIdList: body.resourceIdList,
            operation: body.operation,
            bizCommon: {
                bizType: body.bizType,
                bizCountry: body.region
            }
        }
    }).catch(function (e) {
        logger.error('event_report', e);
    });
}
