import { toCamelCase } from "@credit/utils";
var parseVoucherRule = function (rule) {
    var _a, _b;
    var ruleObj;
    try {
        ruleObj = JSON.parse(rule);
    }
    catch (e) {
        console.error(e);
    }
    var keyObj = ruleObj === null || ruleObj === void 0 ? void 0 : ruleObj.Opf[0];
    var valObj = ruleObj === null || ruleObj === void 0 ? void 0 : ruleObj.Opf[1];
    return {
        key: (_b = (_a = keyObj === null || keyObj === void 0 ? void 0 : keyObj.Opd) === null || _a === void 0 ? void 0 : _a.match(/\$\{(.*)\}/)) === null || _b === void 0 ? void 0 : _b[1],
        value: valObj.Opd
    };
};
export function parseVoucherRules(ruleList) {
    if (!Array.isArray(ruleList)) {
        return {};
    }
    var result = {};
    try {
        ruleList === null || ruleList === void 0 ? void 0 : ruleList.forEach(function (item) {
            var rule = parseVoucherRule(item.condition_express);
            result[toCamelCase(rule.key)] = rule.value;
        });
    }
    catch (e) {
    }
    return result;
}
